import { Row, Spin } from 'antd'
import React from 'react'

const LoadingIndicator: React.FC = () => (
  <Row justify="center" align="middle" style={{ height: 300 }}>
    <Spin />
  </Row>
)

export default LoadingIndicator
