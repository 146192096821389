// import { checkAuth } from '@src/libs/localStorage'

import { request } from './config'

// export const hashtagApi = new HashtagApi()
// --------FN----------

export const updateSlug = (
  id: string,
  data: string,
  token: string,
  name: string,
) =>
  request({ url: `${name}/update-slug/${id}`, method: 'PUT', data }, { token })
