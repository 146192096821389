import HeadHtml from '@components/layout/HeadHtml'
import LoadingIndicator from '@src/components/elements/Loading'
import { OrderByEnum, OrderEnum } from '@src/configs/common'
import { UserQueryParams } from '@src/models/user'
import { useQueryListDeletedUser } from '@src/queries/hooks'
import { Col, Row, Table, TablePaginationConfig } from 'antd'
import { debounce } from 'lodash'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ListHeader from './components/List.header'
import { columns } from './configs/table.config'

function ListDeletedUsers() {
  const [query, setQuery] = useState<UserQueryParams>({
    page: 1,
    limit: 20,
    order: OrderEnum.DESC,
    orderBy: OrderByEnum.CREATE_AT,
  })
  const navigate = useNavigate()
  const { data, isLoading, isFetching } = useQueryListDeletedUser(query)
  const onChangeTable = (
    pagination: TablePaginationConfig,
    filter: any,
    sorter: any,
  ) => {
    let orderBy = OrderByEnum.CREATE_AT
    switch (sorter?.field) {
      case 'fullName':
        orderBy = OrderByEnum.FUll_NAME
        break
      case 'email':
        orderBy = OrderByEnum.EMAIL
        break
      default:
        orderBy = OrderByEnum.CREATE_AT
        break
    }

    // const filterArr = ListRole.data?.data.filter(
    //   (item: any) =>
    //     item.code === (filter.roles?.length >= 3 ? null : filter?.roles?.[0]),
    // )

    const filterArr = filter.roles?.length >= 3 ? null : filter?.roles?.[0]

    setQuery({
      ...query,
      page: pagination.current || 1,
      limit: pagination.pageSize || 20,
      status: filter.status?.length >= 2 ? null : filter?.status?.[0],
      // roles: filterArr.length > 0 ? filterArr[0]._id : null,
      roles: filterArr,
      order:
        !sorter?.order || sorter?.order === 'ascend'
          ? OrderEnum.DESC
          : OrderEnum.ASC,
      orderBy,
    })
  }
  if (isLoading) return <LoadingIndicator />

  return (
    <>
      <HeadHtml title="Danh sách người dùng đã xóa" />
      <Row
        justify="space-between"
        align="bottom"
        style={{ marginBottom: 16 }}
        gutter={[16, 8]}
      >
        <Col sm={24} md={24} xs={24} className="row-header">
          <Row>
            <ListHeader
              title="Danh sách người dùng đã xóa"
              placeholder="Search"
              onChange={debounce(
                (e: React.ChangeEvent<HTMLInputElement>) =>
                  setQuery({ ...query, s: e.target.value }),
                500,
              )}
            />
          </Row>
        </Col>
      </Row>
      <Table
        rowKey="_id"
        showSorterTooltip
        columns={columns}
        dataSource={data?.data?.data || []}
        pagination={{
          pageSize: data?.data?.limit || 10,
          total: data?.data?.total || 0,
          current: data?.data?.page || 1,
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true,
        }}
        onChange={onChangeTable}
        loading={isLoading || isFetching}
        onRow={(item) => ({
          onClick: () => navigate(`/user-deleted/${item._id}`),
        })}
        scroll={{ x: 992 }}
      />
    </>
  )
}

export default ListDeletedUsers
