import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'
import { EconomicZone, EconomicZoneQueryParams } from '@src/models'
import { notification } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import {
  createEconomicZone,
  delEconomicZoneById,
  getEconomicZone,
  getEconomicZoneById,
  updateEconomicZone,
} from '../api/economicZone'

const ECONOMIC_ZONE_KEY = {
  LIST: 'LIST_ECONOMIC_ZONE',
  DETAIL: 'DETAIL_ECONOMIC_ZONE',
}

// -- Fn
export const useQueryEconomicZone = (params: EconomicZoneQueryParams) =>
  useQuery([ECONOMIC_ZONE_KEY.LIST, params], () => getEconomicZone(params), {
    keepPreviousData: true,
    retry: 1,
  })

export const useEconomicZoneById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [ECONOMIC_ZONE_KEY.DETAIL, id, getStoredLang()],
    () => getEconomicZoneById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useCreateEconomicZone = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: EconomicZone) => createEconomicZone(body, accessToken),
    {
      onSuccess(data: ResApi) {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Create Success!' })
          queryClient.refetchQueries([ECONOMIC_ZONE_KEY.LIST])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Create failure!' })
      },
    },
  )
}

export const useUpdateEconomicZone = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: EconomicZone) => updateEconomicZone(id, body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Update Success!' })
          queryClient.refetchQueries([ECONOMIC_ZONE_KEY.LIST])
          queryClient.refetchQueries([
            ECONOMIC_ZONE_KEY.DETAIL,
            id,
            getStoredLang(),
          ])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Update failure!' })
      },
    },
  )
}

export const useDeleteEconomicZone = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delEconomicZoneById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      notification.success({ message: data.message || 'Delete Success!' })
      queryClient.refetchQueries([ECONOMIC_ZONE_KEY.LIST])
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}
