import { checkAuth, getStoredLang } from '@src/libs/localStorage'
import { TransactionQueryParams } from '@src/models'
import { useQuery } from 'react-query'

import { getListTransaction, getTransactionById } from '../api'
import { TRANSACTION_KEY } from '../keys'

export const useQueryTransaction = (params: TransactionQueryParams) =>
  useQuery(
    [TRANSACTION_KEY.LIST, params, getStoredLang()],
    () => getListTransaction(checkAuth(), params),
    {
      keepPreviousData: true,
      retry: 2,
    },
  )

export const useTransactionById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [TRANSACTION_KEY.DETAIL, id, getStoredLang()],
    () => getTransactionById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}
