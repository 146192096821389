import { NError, NSuccess } from '@src/configs/common'
import { TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { ResApi, ResApiErr, TResApi } from '@src/configs/resApi.interface'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'
import {
  EMediaSystem,
  MediaData,
  MediaQueryParams,
  TFile,
  TFolder,
  TFolderMakeTree,
  TMoveFile,
  TMoveFolder,
  TPatchFile,
  TPatchFolder,
  TPathFolder,
  TPostFolder,
  TQueryFile,
  TQueryFolder,
} from '@src/models'
import { notification } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { queryClient } from '..'
import {
  createMedia,
  delMediaById,
  getFileById,
  getFolderById,
  getFolderMakeTreeById,
  getListFile,
  getListFolder,
  getListMedia,
  getMediaById,
  moveFile,
  moveFolderById,
  patchFileById,
  patchFolderById,
  postFolder,
  removeFileById,
  removeFolderById,
  removeManyFile,
  updateMedia,
  uploadFileBySystem,
} from '../api'
import {
  DETAIL_FILE,
  DETAIL_FOLDER,
  FOLDER_MAKE_TREE,
  LIST_FILE,
  LIST_FOLDER,
  MEDIA_KEY,
} from '../keys'
import { DETAIL_THEME } from '../keys/option'

export const useQueryMedia = (params: MediaQueryParams) =>
  useQuery(
    [MEDIA_KEY.LIST, params, getStoredLang()],
    () => getListMedia(params),
    {
      retry: 2,
      keepPreviousData: true,
    },
  )
/**
 * @method useQueryListFolder
 * @param {TQueryFolder}params
 * @param {string}token
 * @returns
 */
export const useQueryListFolder = (params: TQueryFolder, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TFolder>>(
    [LIST_FOLDER, JSON.stringify(params)],
    () => getListFolder(params, accessToken),
    {
      enabled: !!accessToken,
    },
  )
}
export const useCreateMedia = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((body: MediaData) => createMedia(body, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Create Success!' })
        queryClient.refetchQueries([MEDIA_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Create failure!' })
    },
  })
}

export const useMediaById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [MEDIA_KEY.DETAIL, id, getStoredLang()],
    () => getMediaById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useUpdateMedia = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((body: MediaData) => updateMedia(id, body, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Update Success!' })
        queryClient.refetchQueries([MEDIA_KEY.LIST])
        queryClient.refetchQueries([MEDIA_KEY.DETAIL, id, getStoredLang()])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Update failure!' })
    },
  })
}

export const useDeleteMedia = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delMediaById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        queryClient.refetchQueries([MEDIA_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}

/**
 * @method useQueryFolderById
 * @param {string}id
 * @param {string}token
 * @returns
 */
export const useQueryFolderById = (id: string, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TFolder, TPathFolder>>(
    [DETAIL_FOLDER, id],
    () => getFolderById(id, accessToken),
    {
      enabled: !!accessToken && !!id,
    },
  )
}

export const useQueryFolderMakeTree = (token?: string, id?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResApi<TFolderMakeTree>>(
    [FOLDER_MAKE_TREE, id],
    () => getFolderMakeTreeById(id, accessToken),
    {
      enabled: !!accessToken,
    },
  )
}

/**
 * @method useMutationPatchFolderById
 * @returns
 */
export const useMutationPatchFolderById = () => {
  const accessToken = checkAuth()
  // const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }: { id: string; data: TPatchFolder }) =>
      patchFolderById(id, data, accessToken),

    {
      onSuccess: (res: ResApi) => {
        // [TODO] ...
        notification.success({ message: NSuccess, description: res?.message })
        // queryClient.refetchQueries([LIST_FOLDER, JSON.stringify(params)])
      },
      onError: (error: ResApiErr) => {
        // [TODO] ...
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
}

/**
 * @method useMutationRemoveFolderById
 * @returns
 */
export const useMutationRemoveFolderById = (token?: string) => {
  const accessToken = token || checkAuth()
  return useMutation((id: string) => removeFolderById(id, accessToken), {
    onSuccess: (res: ResApi) => {
      // [TODO] ...
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: ResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useMutationMoveFolderById = (token?: string) => {
  const accessToken = token || checkAuth()
  return useMutation(
    ({ id, data }: { id: string; data: TMoveFolder }) =>
      moveFolderById(id, data, accessToken),
    {
      onSuccess: (res: ResApi) => {
        // [TODO] ...
        notification.success({ message: NSuccess, description: res?.message })
      },
      onError: (error: ResApiErr) => {
        // [TODO] ...
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
}

// FILE

/**
 * @method useMutationUploadFileBySystem
 * @returns
 */
export const useMutationUploadFileBySystem = () =>
  // const accessToken = token || checkAuth()
  useMutation(
    ({ system, data }: { system: EMediaSystem; data: FormData }) =>
      uploadFileBySystem(system, data),
    {
      onSuccess: (res: TResApi<TFile | TFile[]>) => {
        // [TODO] ...
        notification.success({ message: NSuccess, description: res?.message })
      },
      onError: (error: ResApiErr) => {
        // [TODO] ...
        notification.error({ message: NError, description: error?.message })
      },
    },
  )

/**
 * @method useQueryListFile
 * @param {TQueryFile}params
 * @param {string}token
 * @returns
 */
export const useQueryListFile = (params: TQueryFile, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TFile>>(
    [LIST_FILE, JSON.stringify(params)],
    () => getListFile(params, accessToken),
    {
      enabled: !!accessToken,
    },
  )
}

/**
 * @method useQueryFileById
 * @param {string}id
 * @param {string}token
 * @returns
 */
export const useQueryFileById = (id: string, token?: string) => {
  const accessToken = token || checkAuth()
  // const queryClient = useQueryClient()
  return useQuery<TResApi<TFile>>(
    [DETAIL_FILE, id],
    () => getFileById(id, accessToken),
    {
      enabled: !!accessToken,
      // onSuccess: (data) => {
      //   // Update the query client cache after successful data fetch
      //   queryClient.setQueryData([LIST_FILE, id], data)
      // },
    },
  )
}

/**
 * @method useMutationPatchFileById
 */
export const useMutationPatchFileById = (token?: string) => {
  const accessToken = token || checkAuth()
  return useMutation(
    ({ id, data }: { id: string; data: TPatchFile }) =>
      patchFileById(id, data, accessToken),
    {
      onSuccess: (res: ResApi) => {
        // [TODO] ...
        queryClient.refetchQueries([DETAIL_THEME])
        notification.success({ message: NSuccess, description: res?.message })
      },
      onError: (error: ResApiErr) => {
        // [TODO] ...
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
}

/**
 * @method useMutationRemoveFileById
 */
export const useMutationRemoveFileById = (token?: string) => {
  const accessToken = token || checkAuth()

  return useMutation((id: string) => removeFileById(id, accessToken), {
    onSuccess: (res: ResApi) => {
      // [TODO] ...
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: ResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })
}

/**
 * @method useMutationRemoveManyFile
 */
export const useMutationRemoveManyFile = () =>
  useMutation(removeManyFile, {
    onSuccess: (res: ResApi) => {
      // [TODO] ...
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: ResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })

/**
 * @method useMutationMoveManyFile
 */
export const useMutationMoveFileById = (token?: string) => {
  const accessToken = token || checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }: { id: string; data: TMoveFile }) =>
      moveFile(id, data, accessToken),
    {
      onSuccess: (res: ResApi) => {
        // [TODO] ...
        notification.success({ message: NSuccess, description: res?.message })
        queryClient.refetchQueries([LIST_FILE])
      },
      onError: (error: ResApiErr) => {
        // [TODO] ...
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
}

/**
 * @method useMutationFolder
 */
export const useMutationPostFolder = (token?: string) => {
  const accessToken = token || checkAuth()

  return useMutation((body: TPostFolder) => postFolder(body, accessToken), {
    onSuccess: (res: TResApi<TFolder>) => {
      // [TODO] ...
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })
}
