import { OrderByEnum, StatusDocumentEnum } from '@src/configs/common'

import { InforBasicProps } from './basic'
import { DefaultParams } from './queryParams'

export enum ETypeInvestProject {
  INFRASTRUCTURE_BUILDING = 'INFRASTRUCTURE_BUILDING',
  URBAN_HOUSING = 'URBAN_HOUSING',
  LOGISTICS_ENERGY = 'LOGISTICS_ENERGY',
  INDUSTRY = 'INDUSTRY',
  AGRICULTURE_FORESTRY = 'AGRICULTURE_FORESTRY',
  ENVIRONMENT = 'ENVIRONMENT',
  COMMERCE_SERVICE = 'COMMERCE_SERVICE',
  CULTURE_SPORT_TOURISM = 'CULTURE_SPORT_TOURISM',
}
export interface IInvestProject extends InforBasicProps {
  publishedLanguage: string[]
  name: string
  thumbnail: string
  cityProvince: string
  area: string
  type: ETypeInvestProject
  investment: string
  typeInvestment: string
  target: string
  currentStatus: string
  provinceInfo: string
  viewer: number
}

export type TQueryParamsInvestProject = DefaultParams & {
  orderBy?: OrderByEnum
  type?: ETypeInvestProject
  cityProvince?: string
  status?: StatusDocumentEnum
}

export type TPostInvestProject = {
  name: string
  thumbnail: string
  cityProvince: string
  contacts: string
  address: string
  area: string
  type: ETypeInvestProject
  investment: string
  typeInvestment: string
  target: string
  currentStatus: string
  provinceInfo: string
  meta: any
  status: StatusDocumentEnum
}

export type TPutInvestProject = Partial<TPostInvestProject>
