import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import { THEME_URL } from '@src/configs/urlRouter'

import { RouteConfigItemType } from './interface'

const List = lazyLoading(() => import('@pages/theme/List'))

const Detail = lazyLoading(() => import('@pages/theme/Detail'))

const themeRouter: RouteConfigItemType[] = [
  {
    path: THEME_URL.LIST,
    Element: List,
    key: PERMISSIONS.LIST_THEME,
  },
  {
    path: THEME_URL.DETAIL,
    Element: Detail,
    key: PERMISSIONS.VIEW_THEME,
  },
]

export { themeRouter }
