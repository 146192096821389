import { PERMISSIONS } from '@src/configs/permissions'
import { useQueryCurrentRole } from '@src/queries/hooks'

export const usePermission = () => {
  const { data, isLoading } = useQueryCurrentRole()
  const roles = data?.data
  const newPermissions: string[] = []
  if (Array.isArray(roles) && roles?.length > 0) {
    roles.forEach((role: { code: string; permissions: string[] }) => {
      if (Array.isArray(role?.permissions) && role?.permissions?.length > 0) {
        role?.permissions.forEach((permission: string) => {
          if (permission) newPermissions.push(permission)
        })
      }
    })
  }
  return {
    permissions:
      newPermissions?.length > 0
        ? [...newPermissions, PERMISSIONS.GENERAL]
        : [PERMISSIONS.GENERAL],
    isLoading,
  }
}
