import { PageHeader, PageHeaderProps } from 'antd'
import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'

function PageHeaders(props: PageHeaderProps) {
  const { title, extra, avatar, ...rest } = props
  const navigate = useNavigate()
  const onBack = () => navigate(-1)

  return (
    <PageHeader
      onBack={onBack}
      title={title}
      extra={extra}
      avatar={avatar}
      className="page-header"
      style={{ padding: 0 }}
      {...rest}
    />
  )
}

export default memo(PageHeaders)
