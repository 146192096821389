import { UserOutlined } from '@ant-design/icons'
import { BASIC_TABLE_CONTENT, BASIC_TABLE_HEADER } from '@src/assets/contants'
import { ROLES_COLORS } from '@src/configs/common'
import { Avatar, Badge, Row, Tag } from 'antd'
import moment from 'moment'

export const columns = [
  {
    title: BASIC_TABLE_HEADER.AVATAR,
    dataIndex: 'avatar',
    render: (avatar: string) => (
      <Avatar size="small" src={avatar} icon={<UserOutlined />} />
    ),
  },
  {
    title: BASIC_TABLE_HEADER.EMAIL,
    dataIndex: 'email',
    sorter: true,
  },
  {
    title: BASIC_TABLE_HEADER.USERNAME,
    dataIndex: 'username',
    sorter: true,
  },
  {
    title: BASIC_TABLE_HEADER.DELETED_IP,
    dataIndex: 'deleteIp',
  },

  {
    title: BASIC_TABLE_HEADER.DELETED_EMAIL,
    dataIndex: 'deleteEmail',
  },

  // {
  //   title: BASIC_TABLE_HEADER.PHONE,
  //   dataIndex: 'phone',
  // },
  {
    title: BASIC_TABLE_HEADER.ROLE,
    dataIndex: 'roles',
    render: (roles: any) => (
      <Row gutter={[5, 5]}>
        {roles.map((tag: any) => {
          const color: string = ROLES_COLORS[tag.code]
          return (
            <Tag color={color} key={tag._id}>
              {tag.code.toUpperCase()}
            </Tag>
          )
        })}
      </Row>
    ),
    filters: [
      {
        text: 'USER',
        value: '62958eb283f42d990d65a37f',
      },
      {
        text: 'ADMINISTRATOR',
        value: '627618215b07a0745c59c163',
      },
      {
        text: 'SUPERADMINISTRATION',
        value: '62a1beacf18261f44594e3de',
      },
    ],
    filterMultiple: false,
  },

  {
    title: BASIC_TABLE_HEADER.STATUS,
    dataIndex: 'status',
    render: (status: any) => {
      const color = status ? 'green' : 'red'

      return (
        <Badge
          color={color}
          text={
            status ? BASIC_TABLE_CONTENT.ACTIVE : BASIC_TABLE_CONTENT.INACTIVE
          }
        />
      )
    },

    filters: [
      {
        text: BASIC_TABLE_CONTENT.ACTIVE,
        value: true,
      },
      {
        text: BASIC_TABLE_CONTENT.INACTIVE,
        value: false,
      },
    ],
    filterMultiple: false,
  },
  {
    title: 'Xác thực Email',
    dataIndex: 'emailVerify',
    render: (emailVerify: any) => {
      const color = emailVerify ? 'green' : 'red'
      return (
        <Badge
          color={color}
          text={
            emailVerify
              ? BASIC_TABLE_CONTENT.VERIFIED
              : BASIC_TABLE_CONTENT.UNVERIFIED
          }
        />
      )
    },
  },
  {
    title: BASIC_TABLE_HEADER.CREATED_AT,
    dataIndex: 'createdAt',
    render: (createdAt: string) =>
      moment(createdAt).format('DD/MM/yyyy HH:mm:ss'),
    sorter: (a: any, b: any) =>
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
  },
]
