import { useQuery } from 'react-query'
import { DetailDataResponse } from '@src/models/queryParams'
import { BaseDataProps } from '@src/models'

import {
  baseDataApi,
  getListCurrentSituation,
  getListEconomicRegion,
  getListEconomicSector,
  // getListTaxonomy,
  getListEconomicRegionUrban,
  getListGenreIndustrialZone,
  getListEconomicZoneType,
  getListUrbanAreaType,
  getListUrbanArea2,
} from '../api'
import { BASE_DATA_KEY } from '../keys/baseData'

/**
 * @method queryBaseData()
 * @returns
 */

class QueryBaseData {
  querySector() {
    return useQuery<DetailDataResponse<BaseDataProps[]>, Error>(
      [BASE_DATA_KEY.SECTOR],
      () => baseDataApi.getSector(),
      {
        // enabled: !!accessToken,
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
      },
    )
  }

  queryRegion() {
    return useQuery<DetailDataResponse<BaseDataProps[]>, Error>(
      [BASE_DATA_KEY.REGION],
      () => baseDataApi.getRegion(),
      {
        // enabled: !!accessToken,
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
      },
    )
  }

  queryIndustrialZone() {
    return useQuery<DetailDataResponse<BaseDataProps[]>, Error>(
      [BASE_DATA_KEY.INDUSTRIAL_ZONE],
      () => baseDataApi.getIndustrial(),
      {
        // enabled: !!accessToken,
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
      },
    )
  }

  queryEconomicSector() {
    return useQuery<DetailDataResponse<BaseDataProps[]>, Error>(
      [BASE_DATA_KEY.INDUSTRIAL_ZONE],
      () => baseDataApi.getIndustrial(),
      {
        // enabled: !!accessToken,
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
      },
    )
  }
}

export const queryBaseData = new QueryBaseData()

// --------Fn -------------------

export const useQueryEconomicSector = () =>
  useQuery([BASE_DATA_KEY.SECTOR], () => getListEconomicSector(), {
    retry: 2,
  })

export const useQueryEconomicRegion = () =>
  useQuery([BASE_DATA_KEY.REGION], () => getListEconomicRegion(), {
    retry: 2,
  })

export const useQueryEconomicZoneType = () =>
  useQuery([BASE_DATA_KEY.ECONOMIC_ZONE], () => getListEconomicZoneType(), {
    retry: 2,
  })

export const useQueryUrbanAreaType = () =>
  useQuery([BASE_DATA_KEY.URBAN_AREA], () => getListUrbanAreaType(), {
    retry: 2,
  })
export const useQueryUrbanArea2 = () =>
  useQuery([BASE_DATA_KEY.URBAN_AREA_TYPE], () => getListUrbanArea2(), {
    retry: 2,
  })
export const useQueryEconomicRegionUrban = () =>
  useQuery([BASE_DATA_KEY.REGION_URBAN], () => getListEconomicRegionUrban(), {
    retry: 2,
  })

export const useQueryGenreIndustrialZone = () =>
  useQuery(
    [BASE_DATA_KEY.INDUSTRIAL_ZONE],
    () => getListGenreIndustrialZone(),
    {
      retry: 2,
    },
  )

export const useQueryCurrentSituation = () =>
  useQuery([BASE_DATA_KEY.CURRENT_SITUATION], () => getListCurrentSituation(), {
    retry: 2,
  })
// export const QueryTaxonomy = () =>
//   useQuery([BASE_DATA_KEY.TAXONOMY], () => getListTaxonomy(), {
//     retry: 2,
//   })
