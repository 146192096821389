import { LangEnum, OrderByEnum, OrderEnum } from '@src/configs/common'
import { HASHTAG_URL } from '@src/configs/urlRouter'
import { checkAuth } from '@src/libs/localStorage'
import { HashtagData, HashtagQueryParams } from '@src/models'

import { request } from './config'

// export const hashtagApi = new HashtagApi()
// --------FN----------
export const searchHashtag = (s?: string) => {
  const accessToken = checkAuth()
  const params = {
    page: 1,
    limit: 10,
    order: OrderEnum.DESC,
    orderBy: OrderByEnum.NAME,
    s: s || '',
  }
  return request(
    { url: HASHTAG_URL.LIST, method: 'GET', params },
    { token: accessToken, lang: LangEnum.VI },
  )
}
export const getListHashtag = (params: HashtagQueryParams) =>
  request(
    { url: 'hashtag/find-all', method: 'GET', params },
    { token: checkAuth() },
  )

export const createHashtag = (data: HashtagData) =>
  request(
    { url: 'hashtag/create', method: 'POST', data },
    { token: checkAuth() },
  )

export const getHashtagById = (id: string) =>
  request({ url: `hashtag/${id}` }, { token: checkAuth() })

export const updateHashtag = (id: string, data: HashtagData) =>
  request({ url: `hashtag/${id}`, method: 'PUT', data }, { token: checkAuth() })

export const delHashtagById = (id: string) =>
  request({ url: `hashtag/${id}`, method: 'DELETE' }, { token: checkAuth() })
