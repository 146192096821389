import { BASE_URL } from '@src/configs/api'
import { MIMETYPES } from '@src/configs/MIMETypes.config'
import { message } from 'antd'
import { RcFile } from 'antd/lib/upload'

const MIMEType = [
  MIMETYPES.doc,
  MIMETYPES.docx,
  MIMETYPES.jpeg,
  MIMETYPES.jpg,
  MIMETYPES.csv,
  MIMETYPES.pdf,
  MIMETYPES.ppt,
  MIMETYPES.xlsx,
  MIMETYPES.xls,
]

export const beforeUploadImage = (file: RcFile) => {
  const isJpgOrPng =
    file.type === MIMETYPES.jpeg || MIMETYPES.jpg || file.type === MIMETYPES.png
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }

  return isJpgOrPng && isLt2M
}

export const beforeUploadFile = (file: RcFile) => {
  const isFileTypes = [
    ...MIMEType,
    MIMETYPES.jpeg,
    MIMETYPES.jpg,
    MIMETYPES.png,
  ].includes(file.type)

  if (!isFileTypes) {
    message.error(
      'You can only upload jpg/png/pdf/doc/docx/ppt/xlsx/xml/csv/xls file!',
    )
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isFileTypes && isLt2M
}

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

export const actionUpload = () => `${BASE_URL}/media/upload`
