import { labelStyle, OrderByEnum, OrderEnum } from '@src/configs/common'
import { useQueryHashtag } from '@src/queries/hooks'
import { Form, Select, SelectProps } from 'antd'

interface Props extends SelectProps {
  hashtagId?: string
}

const HashtagSelect = ({ hashtagId, mode, ...rest }: Props) => {
  const paramsHashtag = {
    page: 1,
    limit: 0,
    order: OrderEnum.DESC,
    orderBy: OrderByEnum.CREATE_AT,
    s: '',
    isCms: true,
  }
  const { data: currentHashtag } = useQueryHashtag(paramsHashtag)

  return (
    <Form.Item
      label="Hashtag"
      name="hashtag"
      //   rules={[
      //     {
      //       // required: true,
      //       message: 'Please select taxonomies',
      //     },
      //   ]}
      {...labelStyle}
      initialValue={hashtagId || undefined}
    >
      <Select
        showSearch
        // onSearch={onSearch}
        // onChange={onChange}
        style={{ width: '100%' }}
        mode={mode || undefined}
        {...rest}
        placeholder="Nhập hashtag"
        filterOption={(input, option) =>
          (option!.children as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      >
        {currentHashtag?.data?.data.map((v: any, k: any) => (
          <Select.Option key={k} value={v._id}>
            {v.title}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default HashtagSelect
