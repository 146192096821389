import { useMutation, useQuery, useQueryClient } from 'react-query'
import { NewsData, NewsQueryParams } from '@src/models'
import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { notification } from 'antd'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'

import {
  createNews,
  delNewsById,
  getNewsById,
  getListNews,
  updateNews,
} from '../api'
import { NEWS_KEY } from '../keys'

export const useQueryNews = (params: NewsQueryParams) =>
  useQuery(
    [NEWS_KEY.LIST, params, getStoredLang()],
    () => getListNews(params),
    {
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  )

export const useCreateNews = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((body: NewsData) => createNews(body, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Create Success!' })
        queryClient.refetchQueries([NEWS_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Create failure!' })
    },
  })
}

export const useNewsById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [NEWS_KEY.DETAIL, id, getStoredLang()],
    () => getNewsById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  )
}

export const useUpdateNews = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((body: NewsData) => updateNews(id, body, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Update Success!' })
        queryClient.refetchQueries([NEWS_KEY.LIST])
        queryClient.refetchQueries([NEWS_KEY.DETAIL, id, getStoredLang()])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Update failure!' })
    },
  })
}

export const useDeleteNews = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delNewsById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        queryClient.refetchQueries([NEWS_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}
