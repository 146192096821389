import { StatusDocumentEnum } from '@src/configs/common'

import { OrderByEnum } from './basic'

export type JuridicalQueryParams = {
  orderBy: OrderByEnum
  limit: any
  order: any
  page: number
  s?: string
  type?: ETypeJuridical
  cityProvince?: string
  status?: StatusDocumentEnum
}

export type TJuridical = {
  createdAt: Date
  _id: string
  code: string
  schedule: Date
  signer: string
  excerpt: string
  agencyIssued?: string
  type?: ETypeJuridical
  document?: string
  status?: StatusDocumentEnum
  slug?: string
  meta?: any
  total?: any
  page?: any
  limit?: any
  data?: any
}
export type TStatusDoc = {
  [x: string]: any
  label: string
  value: ETypeJuridical
}
export enum ETypeJuridical {
  LAW = 'LAW',
  DECREE = 'DECREE',
  DECISION = 'DECISION',
  RESOLUTION = 'RESOLUTION',
  ORDINANCE = 'ORDINANCE',
  CIRCULARS = 'CIRCULARS',
  JOINT_CIRCULAR = 'JOINT_CIRCULAR',
}
