import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'
import {
  TPostInvestProject,
  TPutInvestProject,
  TQueryParamsInvestProject,
} from '@src/models'
import { notification } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { INVEST_PROJECT_KEY } from '../keys'
import {
  createInvestProject,
  delInvestProjectById,
  getInvestProjectById,
  getListInvestProject,
  updateInvestProject,
} from '../api'

export const useQueryListInvestProject = (params: TQueryParamsInvestProject) =>
  useQuery([INVEST_PROJECT_KEY.LIST, params], () =>
    getListInvestProject(params),
  )

export const useMutationPostInvestProject = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: TPostInvestProject) => createInvestProject(body, accessToken),
    {
      onSuccess(data: ResApi) {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Create Success!' })
          queryClient.refetchQueries([INVEST_PROJECT_KEY.LIST])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Create failure!' })
      },
    },
  )
}

export const useQueryInvestProjectById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [INVEST_PROJECT_KEY.DETAIL, id, getStoredLang()],
    () => getInvestProjectById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useMutationPutInvestProjectById = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: TPutInvestProject) => updateInvestProject(id, body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Update Success!' })
          queryClient.refetchQueries([INVEST_PROJECT_KEY.LIST])
          queryClient.refetchQueries([
            INVEST_PROJECT_KEY.DETAIL,
            id,
            getStoredLang(),
          ])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Update failure!' })
      },
    },
  )
}

export const useMutationRemoveInvestProjectById = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delInvestProjectById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      notification.success({ message: data.message || 'Delete Success!' })
      queryClient.refetchQueries([INVEST_PROJECT_KEY.LIST])
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}
