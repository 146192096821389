/* eslint-disable react-hooks/exhaustive-deps */
import {
  CloseSquareFilled,
  EditFilled,
  FileExclamationOutlined,
  FileTextOutlined,
  PlaySquareOutlined,
} from '@ant-design/icons'
import placeholderImage from '@assets/placeholder-image.jpg'
import MediaManagerModal, {
  THandleMediaManagerModal,
} from '@components/widgets/MediaManagerModal'
import { TFile } from '@src/models'
import {
  regexAudio,
  regexDocument,
  regexImage,
  regexVideo,
} from '@src/utils/regex'
import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  FormItemProps,
  Image,
  Input,
  Row,
  Space,
  Typography,
} from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'

const { Meta } = Card
const { Text } = Typography

type TSelectSingleFileFormItem<T = any> = {
  form: FormInstance<T>
  initialValue?: TFile | any
  onFileSelected?: (data: any) => void
}

function SelectSingleFileFormItem({
  form,
  onFileSelected,
  ...props
}: TSelectSingleFileFormItem & FormItemProps) {
  const mediaManagerModalRef = useRef<null | THandleMediaManagerModal>(null)
  const [fileSelected, setFileSelected] = useState<string | undefined>(
    undefined,
  )

  const handleAddFile = (files: string) => {
    setFileSelected(files)
  }

  useEffect(() => {
    setFileSelected(props?.initialValue)
  }, [props?.initialValue])

  useEffect(() => {
    if (props?.name) {
      form.setFieldsValue({
        [`${props.name}`]: fileSelected,
      })
      if (onFileSelected) onFileSelected(fileSelected)
    }
  }, [fileSelected])

  const fileType: 'docx' | 'image' | 'audio' | 'unknown' = useMemo(() => {
    if (fileSelected?.match(regexImage)) {
      return 'image'
    }
    if (fileSelected?.match(regexDocument)) {
      return 'docx'
    }
    if (fileSelected?.match(regexVideo) || fileSelected?.match(regexAudio)) {
      return 'audio'
    }
    return 'unknown'
  }, [fileSelected])

  return (
    <Row className="select-single-file-container">
      <Col span={24}>
        {fileSelected ? (
          <Card
            className="card-file-single"
            size="small"
            cover={
              fileType === 'image' && (
                <Row
                  align="middle"
                  justify="center"
                  className="card-file-single-cover"
                >
                  <Col>
                    <Image src={fileSelected || placeholderImage} />
                  </Col>
                </Row>
              )
            }
            extra={
              fileSelected && (
                <Space align="start" size={15}>
                  <EditFilled
                    onClick={() => mediaManagerModalRef.current?.onOpen(true)}
                  />
                  <CloseSquareFilled
                    onClick={() => setFileSelected(undefined)}
                  />
                </Space>
              )
            }
          >
            {fileType === 'image' ? (
              <Meta
                description={
                  <Text ellipsis style={{ fontSize: 12 }}>
                    {fileSelected}
                  </Text>
                }
              />
            ) : (
              <Row gutter={[10, 10]} align="middle" wrap={false}>
                <Col flex="23px">
                  {fileType === 'docx' && (
                    <FileTextOutlined style={{ fontSize: 16 }} />
                  )}
                  {fileType === 'audio' && (
                    <PlaySquareOutlined style={{ fontSize: 16 }} />
                  )}
                  {fileType === 'unknown' && (
                    <FileExclamationOutlined style={{ fontSize: 16 }} />
                  )}
                </Col>
                <Col flex="1">
                  <Text ellipsis>{fileSelected}</Text>
                </Col>
              </Row>
            )}
          </Card>
        ) : (
          <Space size={10} align="center">
            <Text>No file selected</Text>
            <Button onClick={() => mediaManagerModalRef.current?.onOpen(true)}>
              Add file
            </Button>
          </Space>
        )}
      </Col>
      <Form.Item {...props}>
        <Input hidden />
      </Form.Item>
      <MediaManagerModal
        ref={mediaManagerModalRef}
        handleAddFile={(files: TFile[]) => handleAddFile(files?.[0].location)}
        filesSelectedInit={fileSelected ? [fileSelected] : []}
      />
    </Row>
  )
}

export default SelectSingleFileFormItem
