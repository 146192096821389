import { MANAGEMENT_URL } from '@src/configs/urlRouter'
import { checkAuth } from '@src/libs/localStorage'
import { LangEnum, OrderByEnum, OrderEnum } from '@src/models/basic'
import {
  ManagementData,
  ManagementQuery,
  // ManagementQueryParams,
} from '@src/models/management'

import { request } from './config'

class ManagementApi {
  url: { LIST: string; CREATE: string; DETAIL: string }

  constructor() {
    this.url = MANAGEMENT_URL
  }

  getSearch(s?: string) {
    const accessToken = checkAuth()
    const params = {
      page: 1,
      limit: 10,
      order: OrderEnum.ASC,
      orderBy: OrderByEnum.NAME,
      s: s || '',
    }
    return request(
      { url: MANAGEMENT_URL.LIST, method: 'GET', params },
      { token: accessToken, lang: LangEnum.VI },
    )
  }
}

export const managementApi = new ManagementApi()
// --------FN----------

export const createManagement = (data: ManagementData, token: string) =>
  request({ url: 'management', method: 'POST', data }, { token })

export const getManagementById = (id: string, token: string) =>
  request({ url: `management/${id}`, params: { isCms: true } }, { token })

export const updateManagement = (
  id: string,
  data: ManagementData,
  token: string,
) => request({ url: `management/${id}`, method: 'PUT', data }, { token })

export const delManagementById = (id: string, token: string) =>
  request({ url: `management/${id}`, method: 'DELETE' }, { token })

// ---------------------Fn---------------
export const getListManagement = (params: ManagementQuery) =>
  request({ url: 'management', method: 'GET', params })

export const getDetailManagement = (id: string, token: string) =>
  request({ url: `management/${id}`, method: 'GET' }, { token })
