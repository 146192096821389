import HashtagSelect from '@src/components/elements/HashtagSelect'
import RichText from '@src/components/elements/RichText'
import TypeSearchSelectData from '@src/components/widgets/SelectData/TypeSearch.SelectData'
import { labelStyle } from '@src/configs/common'
import { ProductData } from '@src/models'
import { CurrencyUnitEnum } from '@src/models/basic'
import { enterpriseApi } from '@src/queries/api'
import { Collapse, Form, Input, InputNumber, Select, Space } from 'antd'
// import CardUploadGallery from '../industrial/sectionCard/CardUploadGallery'

const { Panel } = Collapse
// const { Option } = Select
const { TextArea } = Input
interface FormInputProduct {
  data?: ProductData
  form: any
}

function FormInput({ data, form }: FormInputProduct) {
  // logger('data product', data)
  // const { data: economicRegions } = queryBaseData.queryRegion()
  // const lang = getStoredLang() || LANGUAGE_DEFAULT

  return (
    <Space direction="vertical" className="space-event" size={10}>
      <Collapse
        defaultActiveKey={['1']}
        className="action"
        expandIconPosition="end"
      >
        <Panel header="Tổng quan" key="1">
          <Form.Item
            name="name"
            label="Tên"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập trường này...',
              },
            ]}
            {...labelStyle}
            initialValue={data?.name}
          >
            <Input placeholder="Enter name here" />
          </Form.Item>

          <Form.Item
            label="Xí nghiệp"
            name="enterprise"
            {...labelStyle}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập trường này...',
              },
            ]}
            initialValue={data?.enterprise?._id}
          >
            <TypeSearchSelectData
              placeholder="select Enterprise Name"
              fetchOptions={enterpriseApi.getSearch}
            />
          </Form.Item>

          <Form.Item label="Mức giá" {...labelStyle}>
            <Input.Group compact>
              <Form.Item name="minPrice" initialValue={data?.minPrice}>
                <InputNumber
                  min={0}
                  type="number"
                  style={{ width: 100, textAlign: 'center' }}
                  placeholder="min Price"
                />
              </Form.Item>
              <Input
                className="site-input-split"
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                }}
                placeholder="~"
                disabled
              />
              <Form.Item name="maxPrice" initialValue={data?.maxPrice}>
                <InputNumber
                  type="number"
                  min={0}
                  style={{ width: 100, textAlign: 'center' }}
                  placeholder="max Price"
                />
              </Form.Item>
              <Form.Item name="unitPrice" initialValue={CurrencyUnitEnum.VND}>
                <Select style={{ width: '100%' }}>
                  <Select.Option value={CurrencyUnitEnum.VND}>
                    vnd
                  </Select.Option>
                  <Select.Option value={CurrencyUnitEnum.USD}>
                    usd
                  </Select.Option>
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item
            // label="Min Order:"
            label="Đơn nhỏ nhất:"
            // required
            {...labelStyle}
          >
            <Input.Group compact>
              <Form.Item
                initialValue={data?.minOrder}
                name="minOrder"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập trường này...',
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  min={0}
                  style={{ width: 100, textAlign: 'center' }}
                  placeholder="min Order"
                />
              </Form.Item>
              <Form.Item>
                <Select style={{ width: '100%' }} value={CurrencyUnitEnum.VND}>
                  <Select.Option value={CurrencyUnitEnum.VND}>
                    Unit
                  </Select.Option>
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <HashtagSelect hashtagId={data?.hashtag ?? ''} />
          {/* <PublishedLanguage data={data} /> */}
        </Panel>
      </Collapse>
      <Collapse defaultActiveKey={['3']} expandIconPosition="end">
        <Panel header="Thẻ Meta" key="3">
          <Form.Item
            name="metaKeyword"
            label="Meta Keyword"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập trường này...',
              },
            ]}
            initialValue={data?.meta?.metaKeyword}
            {...labelStyle}
          >
            <TextArea placeholder="Nhập thẻ meta ..." />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập trường này...',
              },
            ]}
            name="metaDescription"
            label="Meta Description"
            initialValue={data?.meta?.metaDescription}
            {...labelStyle}
          >
            <TextArea rows={6} placeholder="Nhập thẻ meta description ..." />
          </Form.Item>
        </Panel>
      </Collapse>
      <Collapse
        defaultActiveKey={['1']}
        className="action"
        expandIconPosition="end"
      >
        <Panel header="Thông tin" key="1">
          <Form.Item
            name="content"
            label="Nội dung"
            {...labelStyle}
            initialValue={data?.content}
          >
            <RichText
              h={300}
              initialValue={data?.content}
              onEditorChange={(text: any) => {
                form.setFieldsValue({
                  content: text,
                })
              }}
            />
          </Form.Item>
          <Form.Item
            name="excerpt"
            label="Đoạn trích"
            {...labelStyle}
            initialValue={data?.excerpt}
          >
            <RichText
              h={300}
              initialValue={data?.excerpt}
              onEditorChange={(text: any) => {
                form.setFieldsValue({
                  excerpt: text,
                })
              }}
            />
          </Form.Item>
        </Panel>
      </Collapse>
      {/* <Collapse
        defaultActiveKey={['1']}
        className="action"
        expandIconPosition="end"
      >
        <Panel header="Thư viện" key="1">
          <Row justify="center">
            <InputUploadListImage
              form={form}
              name="gallery"
              files={data?.gallery}
            />
          </Row>
        </Panel>
      </Collapse> */}
    </Space>
  )
}

export default FormInput
