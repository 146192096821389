import { EditOutlined } from '@ant-design/icons'
import { labelStyle } from '@src/configs/common'
import { useUpdateSlug } from '@src/queries/hooks/updateslug'
import { Button, Col, Collapse, Form, Input, Row } from 'antd'
import { useParams } from 'react-router-dom'

import LoadingIndicator from '../Loading'

const { Panel } = Collapse
const EditSlug = ({ ...props }) => {
  const slug = props?.data
  const name = props?.name

  const { id } = useParams()
  const { mutate: mutateSlug, isLoading: loadSlug } = useUpdateSlug(
    id || '',
    name,
  )
  const [formEditSlug] = Form.useForm()
  const onFinish = (values: any) => {
    const slug = {
      slug: values.slug,
    }
    mutateSlug(slug)
  }
  const handleSubmit = () => {
    formEditSlug.submit()
  }
  if (loadSlug) return <LoadingIndicator />
  return (
    <Form form={formEditSlug} onFinish={onFinish} scrollToFirstError>
      <Collapse
        defaultActiveKey={['1']}
        className="action"
        expandIconPosition="end"
      >
        <Panel header="Edit Slug" key="1">
          <Row justify="space-between">
            <Col flex={4}>
              <Form.Item
                name="slug"
                label="Slug"
                initialValue={slug}
                rules={[
                  {
                    required: true,
                    message: 'Slug không được để trống...',
                  },
                  {
                    // type: 'regexp',
                    // eslint-disable-next-line prefer-regex-literals
                    pattern: new RegExp('^[a-z0-9]+(?:-[a-z0-9]+)*$'),
                    message: `Slug không hợp lệ`,
                  },
                ]}
                {...labelStyle}
              >
                <Input placeholder="Nhập slug ..." />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Row justify="end">
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </Row>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Form>
  )
}

export default EditSlug
