import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CouponData, CouponQueryParams } from '@src/models'
import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { notification } from 'antd'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'

import {
  createCoupon,
  delCouponById,
  getCouponById,
  getListCoupon,
  updateCoupon,
} from '../api'
import { COUPON_KEY } from '../keys'

export const useQueryCoupon = (params: CouponQueryParams) =>
  useQuery(
    [COUPON_KEY.LIST, params, getStoredLang()],
    () => getListCoupon(params),
    {
      keepPreviousData: true,
      retry: 2,
    },
  )

export const useCreateCoupon = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((body: CouponData) => createCoupon(body, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Create Success!' })
        queryClient.refetchQueries([COUPON_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Create failure!' })
    },
  })
}

export const useCouponById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [COUPON_KEY.DETAIL, id, getStoredLang()],
    () => getCouponById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useUpdateCoupon = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: CouponData) => updateCoupon(id, body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Update Success!' })
          queryClient.refetchQueries([COUPON_KEY.LIST])
          queryClient.refetchQueries([COUPON_KEY.DETAIL, id, getStoredLang()])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Update failure!' })
      },
    },
  )
}

export const useDeleteCoupon = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delCouponById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        queryClient.refetchQueries([COUPON_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}
