/* eslint-disable no-return-assign */
import logger from '@src/libs/logger'
import { uploadImgApi } from '@src/queries/api/uploadImg'
import { Editor } from '@tinymce/tinymce-react'
import { FormInstance } from 'antd/es/form/Form'
import { useRef } from 'react'
import { TINYMCE_API_KEY } from '@src/configs/api'

export interface RichTextProps {
  h?: number
  submitForm?: FormInstance
  initialValue?: string
  onEditorChange?: (x: any) => void
}

export default function RichText({
  h,
  initialValue,
  onEditorChange,
}: RichTextProps) {
  const editorRef: any = useRef(null)

  const initTinymce: any = {
    height: h || 500,

    plugins:
      'image code charmap emoticons pagebreak fullscreen preview link codesample media lists anchor autosave save directionality insertdatetime nonbreaking pagebreak quickbars searchreplace table template visualblocks visualchars wordcount',
    mobile: {
      plugins:
        'image code charmap emoticons pagebreak fullscreen preview link codesample media lists tab anchor charmap autosave save charmap directionality hr insertdatetime nonbreaking pagebreak quickbars searchreplace table template visualblocks visualchars wordcount',
    },

    toolbar:
      'undo redo | bold italic underline strikethrough blockquote | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify alignnone | outdent indent lineheight |  numlist bullist | searchreplace spellchecker | forecolor backcolor removeformat casechange formatpainter selectall | pagebreak | charmap emoticons | fullscreen  preview save | code table | insertfile image media template link anchor codesample | visualblocks visualchars wordcount | ltr rtl',
    menu: false,
    // menubar: false,
    media_advtab: false,
    importcss_append: false,
    selector: 'textarea',
    a11y_advanced_options: true,
    toolbar_mode: 'sliding',
    tinycomments_mode: 'embedded',
    image_advtab: false,
    image_caption: true,
    images_upload_url: ``,
    images_upload_credentials: false,
    image_description: true,

    // eslint-disable-next-line consistent-return
    async images_upload_handler(
      blobInfo: any,
      //  progress: any
    ) {
      const imageFile = new FormData()
      imageFile.append('file', blobInfo.blob())
      imageFile.append('systemType', 'NienGiam')

      try {
        const { data } = await uploadImgApi.uploadImg(imageFile)
        return data.location
      } catch (error) {
        logger.debug('images_upload_handler', error)
      }
    },
  }

  return (
    <Editor
      apiKey={TINYMCE_API_KEY}
      onInit={(evt, editor) => (editorRef.current = editor)}
      initialValue={initialValue}
      onEditorChange={onEditorChange}
      init={initTinymce}
    />
  )
}
