import { LangEnum, OrderByEnum, OrderEnum } from '@src/configs/common'
import { ENTERPRISE_URL } from '@src/configs/urlRouter'
import { checkAuth } from '@src/libs/localStorage'
import { EnterpriseData, EnterpriseQueryParams } from '@src/models'

import { request } from './config'

class EnterpriseApi {
  url: { LIST: string; CREATE: string; DETAIL: string }

  constructor() {
    this.url = ENTERPRISE_URL
  }

  getSearch(s?: string) {
    const accessToken = checkAuth()
    const params = {
      page: 1,
      limit: 10,
      order: OrderEnum.DESC,
      orderBy: OrderByEnum.NAME,
      s: s || '',
    }
    return request(
      { url: ENTERPRISE_URL.LIST, method: 'GET', params },
      { token: accessToken, lang: LangEnum.VI },
    )
  }
}

export const enterpriseApi = new EnterpriseApi()
// --------FN----------
export const getListEnterprise = (params: EnterpriseQueryParams) =>
  request({ url: 'enterprise', method: 'GET', params })

export const createEnterprise = (data: EnterpriseData, token: string) =>
  request({ url: 'enterprise', method: 'POST', data }, { token })

export const getEnterpriseById = (id: string, token: string) =>
  request({ url: `enterprise/${id}`, params: { isCms: true } }, { token })

export const updateEnterprise = (
  id: string,
  data: EnterpriseData,
  token: string,
) => request({ url: `enterprise/${id}`, method: 'PUT', data }, { token })

export const delEnterpriseById = (id: string, token: string) =>
  request({ url: `enterprise/${id}`, method: 'DELETE' }, { token })
