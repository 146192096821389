import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ProductData, ProductQueryParams } from '@src/models'
import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { notification } from 'antd'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'

import {
  createProduct,
  delProductById,
  getProductById,
  getListProduct,
  updateProduct,
} from '../api'
import { PRODUCT_KEY } from '../keys'

export const useQueryProduct = (params: ProductQueryParams) =>
  useQuery(
    [PRODUCT_KEY.LIST, params, getStoredLang()],
    () => getListProduct(params),
    {
      keepPreviousData: true,
      retry: 2,
    },
  )

export const useCreateProduct = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((body: ProductData) => createProduct(body, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Create Success!' })
        queryClient.refetchQueries([PRODUCT_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Create failure!' })
    },
  })
}

export const useProductById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [PRODUCT_KEY.DETAIL, id, getStoredLang()],
    () => getProductById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useUpdateProduct = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: ProductData) => updateProduct(id, body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Update Success!' })
          queryClient.refetchQueries([PRODUCT_KEY.LIST])
          queryClient.refetchQueries([PRODUCT_KEY.DETAIL, id, getStoredLang()])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Update failure!' })
      },
    },
  )
}

export const useDeleteProduct = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delProductById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        queryClient.refetchQueries([PRODUCT_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}
