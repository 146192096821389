import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import { ENTERPRISE_URL } from '@src/configs/urlRouter'
import Detail from '@src/pages/enterprise/Detail'

import { RouteConfigItemType } from './interface'

const ListEnterprise = lazyLoading(() => import('@src/pages/enterprise/List'))
const CreateEnterprise = lazyLoading(
  () => import('@src/pages/enterprise/Create'),
)

const enterpriseRouter: RouteConfigItemType[] = [
  {
    path: ENTERPRISE_URL.LIST,
    Element: ListEnterprise,
    key: PERMISSIONS.LIST_ENTERPRISE,
  },
  {
    path: ENTERPRISE_URL.CREATE,
    Element: CreateEnterprise,
    key: PERMISSIONS.CREATE_ENTERPRISE,
  },
  {
    path: ENTERPRISE_URL.DETAIL,
    Element: Detail,
    key: PERMISSIONS.VIEW_ENTERPRISE,
  },
]

export { enterpriseRouter }
