import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'
// import { EditSlugData } from '@src/models'
import { notification } from 'antd'
import { useMutation, useQueryClient } from 'react-query'

import { updateSlug } from '../api'
import { ENTERPRISE_KEY } from '../keys'

export const useUpdateSlug = (id: string, name: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((slug: any) => updateSlug(id, slug, accessToken, name), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Update Success!' })
        queryClient.refetchQueries([ENTERPRISE_KEY.LIST])
        queryClient.refetchQueries([ENTERPRISE_KEY.DETAIL, id, getStoredLang()])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Update failure!' })
    },
  })
}
