import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import { ECONOMICZONE_URL } from '@src/configs/urlRouter'

import { RouteConfigItemType } from './interface'

const List = lazyLoading(() => import('@pages/economic-zone/List'))

const Create = lazyLoading(() => import('@pages/economic-zone/Create'))

const Detail = lazyLoading(() => import('@pages/economic-zone/Detail'))

const economicZoneRouter: RouteConfigItemType[] = [
  {
    path: ECONOMICZONE_URL.LIST,
    Element: List,
    key: PERMISSIONS.LIST_ECONOMIC_ZONE,
  },
  {
    path: ECONOMICZONE_URL.CREATE,
    Element: Create,
    key: PERMISSIONS.CREATE_ECONOMIC_ZONE,
  },
  {
    path: ECONOMICZONE_URL.DETAIL,
    Element: Detail,
    key: PERMISSIONS.VIEW_ECONOMIC_ZONE,
  },
]

export { economicZoneRouter }
