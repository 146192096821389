/* eslint-disable no-param-reassign */
import _ from 'lodash'

export const removeAccents = (str: string) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  return str
}

export const capitalize = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()

export const convertToSlug = (text: string) => {
  const a = removeAccents(text)
  return a
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-')
}

export function onDetail(navigate: any, id: any, url: any) {
  if (id) {
    navigate(`/${url}/${id}`)
  }
}

export function onLinkNavigate(navigate: string) {
  return `/${navigate}`
}
export const differenceBetweenTwoObj = (newObj: any, oldObj: any) => {
  function changes(newObj: any, oldObj: any) {
    return _.transform(newObj, (result: any, value, key) => {
      if (!_.isEqual(value, oldObj[key])) {
        // eslint-disable-next-line no-param-reassign
        result[key] =
          _.isObject(value) && _.isObject(oldObj[key])
            ? changes(value, oldObj[key])
            : value
      }
    })
  }
  return changes(newObj, oldObj)
}

export function formatNumberWithDot(n?: number) {
  return n ? n.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, '.') : 'N/A'
}
