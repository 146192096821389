import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import ListIndustrial from '@src/pages/industrial/List'
import { INDUSTRIAL_URL } from '@src/configs/urlRouter'

import { RouteConfigItemType } from './interface'

const CreateIndustrial = lazyLoading(() => import('@pages/industrial/Create'))

const DetailIndustrial = lazyLoading(() => import('@pages/industrial/Detail'))

const industrialRouter: RouteConfigItemType[] = [
  {
    path: INDUSTRIAL_URL.LIST,
    Element: ListIndustrial,
    key: PERMISSIONS.LIST_INDUSTRIAL_AREA,
  },
  {
    path: INDUSTRIAL_URL.CREATE,
    Element: CreateIndustrial,
    key: PERMISSIONS.CREATE_INDUSTRIAL_AREA,
  },
  {
    path: INDUSTRIAL_URL.DETAIL,
    Element: DetailIndustrial,
    key: PERMISSIONS.VIEW_INDUSTRIAL_AREA,
  },
]

export { industrialRouter }
