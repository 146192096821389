/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Input, Select } from 'antd'
import { CurrencyProps } from '@src/models/basic'

const { Option } = Select

const units = ['vnd', 'usd']
interface PriceInputProps {
  data?: CurrencyProps
  detailData?: CurrencyProps
  onChange?: (v: any) => void
}

const PriceInput: React.FC<PriceInputProps> = ({
  data = {},
  onChange,
  detailData,
}) => {
  const [value, setValue] = useState(detailData?.value || 0)
  const [unit, setUnit] = useState(detailData?.unit || 'vnd')

  const triggerChange = (changedValue: CurrencyProps) => {
    onChange?.({ value, unit, ...data, ...changedValue })
  }

  const onNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newNumber = parseInt(e.target.value || '0', 10)
    if (Number.isNaN(value)) {
      return
    }
    if (!('number' in data)) {
      setValue(newNumber)
    }
    triggerChange({ value: newNumber })
  }

  const onCurrencyChange = (newCurrency: any) => {
    if (!('currency' in data)) {
      setUnit(newCurrency)
    }
    triggerChange({ unit: newCurrency })
  }

  return (
    <Input.Group compact>
      <Input
        placeholder="type value"
        type="number"
        value={value}
        style={{ width: 150 }}
        onChange={onNumberChange}
      />
      <Select value={unit} style={{ width: 80 }} onChange={onCurrencyChange}>
        {units.map((unit) => (
          <Option key={unit} value={unit}>
            {unit}
          </Option>
        ))}
      </Select>
    </Input.Group>
  )
}

export default PriceInput
