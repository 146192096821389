import FallbackImage from '@src/components/elements/FallbackImage'
import {
  StatusDocumentEnum,
  StatusDocumentEnumBoolean,
  LANGUAGE_DEFAULT,
} from '@src/configs/common'
import { AreaInterface } from '@src/configs/interface.config'
// import { queryClient } from '@src/queries'
// import { BASE_DATA_KEY } from '@src/queries/keys/baseData'
import { formatNumberWithDot } from '@src/utils'
import { Badge, Image } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import { getStoredLang } from '@src/libs/localStorage'

interface DataType {
  _id: string
  name: string
  thumbnail: string
  address: string
  currentStatus: string
  totalArea: AreaInterface
  fillRate: number
  status: StatusDocumentEnum
  representative: boolean
  createdAt: string
}
const lang = getStoredLang() || LANGUAGE_DEFAULT
export const columns: ColumnsType<DataType> = [
  {
    title: 'Thumbnail',
    dataIndex: 'thumbnail',
    render: (avatar: string) =>
      avatar ? (
        <Image width={40} src={avatar} preview={false} />
      ) : (
        <FallbackImage width={40} />
      ),
  },
  {
    title: 'Tên',
    dataIndex: 'name',
    render: (name: string) => <span>{name || 'Đang cập nhật'}</span>,
    sorter: true,
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'address',
    render: (address: string) => <span>{address || 'Đang cập nhật'}</span>,
  },

  {
    title: 'Chủ đầu tư',
    dataIndex: 'investor',
    render: (investor: any) => (
      <span>{investor?.translations?.[lang].name || 'Đang cập nhật'}</span>
    ),
  },

  // {
  //   title: 'Investor',
  //   dataIndex: 'investor',
  //   render: (investor: any) => {
  //     let color = 'green'
  //     switch (investor) {
  //       case true:
  //         color = 'green'
  //         break
  //       case false:
  //         color = 'red'
  //         break

  //       default:
  //         color = 'green'
  //         break
  //     }

  //     return <Badge color={color} text={investor} />
  //   },
  //   filters: [
  //     {
  //       text: StatusDocumentEnumBoolean.YES,
  //       value: true,
  //     },
  //     {
  //       text: StatusDocumentEnumBoolean.NO,
  //       value: false,
  //     },
  //   ],
  //   filterMultiple: false,
  // },

  {
    title: 'Tổng diện tịch',
    dataIndex: 'totalArea',
    render: (totalArea: AreaInterface) => (
      <span>
        {totalArea.value
          ? `${formatNumberWithDot(totalArea.value)} ${totalArea.unit}`
          : 'Đang cập nhật'}
      </span>
    ),
  },
  {
    title: 'Tỷ lệ lấp đầy',
    dataIndex: 'fillRate',
    render: (fillRate: number) => (
      <span>{fillRate ? `${fillRate}%` : 'Đang cập nhật'} </span>
    ),
  },

  {
    title: 'Tiêu biểu',
    dataIndex: 'representative',
    render: (representative: boolean) => {
      let color = 'green'
      switch (representative) {
        case true:
          color = 'green'
          break
        case false:
          color = 'red'
          break

        default:
          color = 'green'
          break
      }

      return <Badge color={color} text={representative ? 'Có' : 'Không'} />
    },
    filters: [
      {
        text: StatusDocumentEnumBoolean.YES,
        value: true,
      },
      {
        text: StatusDocumentEnumBoolean.NO,
        value: false,
      },
    ],
    filterMultiple: false,
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (status: StatusDocumentEnum) => {
      let color = 'green'
      switch (status) {
        case StatusDocumentEnum.ACTIVE:
          color = 'green'
          break
        case StatusDocumentEnum.INACTIVE:
          color = 'red'
          break
        case StatusDocumentEnum.DRAFT:
          color = '#808080'
          break
        default:
          color = 'red'
          break
      }

      return <Badge color={color} text={status} />
    },
    filters: [
      {
        text: StatusDocumentEnum.ACTIVE,
        value: StatusDocumentEnum.ACTIVE,
      },
      {
        text: StatusDocumentEnum.INACTIVE,
        value: StatusDocumentEnum.INACTIVE,
      },
      {
        text: StatusDocumentEnum.DRAFT,
        value: StatusDocumentEnum.DRAFT,
      },
    ],
    filterMultiple: false,
  },
  {
    title: 'Thời gian tạo',
    dataIndex: 'createdAt',
    render: (createdAt: string) =>
      moment(createdAt).format('DD/MM/yyyy H:mm:ss a'),
    sorter: (a: any, b: any) =>
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
  },
]
