import { OrderByEnum } from '@src/configs/common'

import { InforBasicProps, StatusInvestorEnum } from './basic'
import { DefaultParams } from './queryParams'

export type NewsQueryParams = DefaultParams & {
  orderBy: OrderByEnum
  status?: StatusInvestorEnum
  featured?: boolean
  taxonomies?: string[] | []
}

export interface NewsData extends InforBasicProps {
  title?: string
  thumbnail?: string
  excerpt?: string
  content?: string
  views?: number
  taxonomies?: string[]
  authorId?: string
  hashtag?: string
  [key: string]: any
}
export enum SystemTypeOptions {
  NG = 'NienGiam',
  '2KCN' = '2KCN',
}
