import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import { INVESTOR_URL } from '@src/configs/urlRouter'

import { RouteConfigItemType } from './interface'

const List = lazyLoading(() => import('@pages/investor/ListInvestor'))
const Create = lazyLoading(() => import('@pages/investor/CreateInvestor'))
const Detail = lazyLoading(() => import('@pages/investor/DetailInvestor'))

const investorRouter: RouteConfigItemType[] = [
  {
    path: INVESTOR_URL.LIST,
    Element: List,
    key: PERMISSIONS.LIST_INVESTOR,
  },
  {
    path: INVESTOR_URL.CREATE,
    Element: Create,
    key: PERMISSIONS.CREATE_INVESTOR,
  },
  {
    path: INVESTOR_URL.DETAIL,
    Element: Detail,
    key: PERMISSIONS.VIEW_INVESTOR,
  },
]

export { investorRouter }
