import {
  DeleteOutlined,
  EditOutlined,
  FolderFilled,
  FolderOpenOutlined,
} from '@ant-design/icons'
import { TFolder } from '@src/models'
import { Card, Col, Dropdown, Menu, MenuProps, Row, Typography } from 'antd'
import { useMemo } from 'react'

const { Text } = Typography

export type TFolderItem = {
  folder: TFolder
  handleClick?: (id: string) => void
  handleEdit?: (folder: TFolder | undefined) => void
  handleDelete?: (id: string) => void
  handleMove?: (folder: TFolder | undefined) => void
}

function FolderItem({
  folder,
  handleClick,
  handleEdit,
  handleDelete,
  handleMove,
}: TFolderItem) {
  const itemsArray: MenuProps['items'] = useMemo(
    () => [
      {
        label: 'Sửa',
        key: '0',
        icon: <EditOutlined />,
        onClick: () => {
          if (handleEdit) handleEdit(folder)
        },
      },
      {
        label: 'Xóa',
        key: '1',
        icon: <DeleteOutlined />,
        onClick: () => {
          if (handleDelete) handleDelete(folder._id)
        },
      },
      {
        label: 'Chuyển vào Folder',
        key: '4',
        icon: <FolderOpenOutlined />,
        onClick: () => {
          if (handleMove) handleMove(folder)
        },
      },
    ],
    [folder],
  )

  const menu = (
    <Menu>
      {itemsArray.length > 0 &&
        itemsArray.map((val: any, key) => (
          <Menu.Item key={key} icon={val?.icon} onClickCapture={val.onClick}>
            {val.label}
          </Menu.Item>
        ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['contextMenu']}>
      <Card
        size="small"
        bordered={false}
        className="card-folder-item"
        onClick={() => {
          if (handleClick) handleClick(folder._id)
        }}
      >
        <Row
          wrap={false}
          className="folder-item-container"
          justify="space-between"
          align="middle"
        >
          <Col className="folder-item-icon">
            <FolderFilled />
          </Col>
          <Col className="folder-item-name">
            <Text ellipsis>{folder.name}</Text>
          </Col>
        </Row>
      </Card>
    </Dropdown>
  )
}

export default FolderItem
