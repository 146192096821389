import {
  CloseSquareFilled,
  FileExclamationOutlined,
  FileTextOutlined,
  PlaySquareOutlined,
} from '@ant-design/icons'
import placeholderImage from '@assets/placeholder-image.jpg'
import MediaManagerModal, {
  THandleMediaManagerModal,
} from '@components/widgets/MediaManagerModal'
import { TFile } from '@src/models'
import {
  regexAudio,
  regexDocument,
  regexImage,
  regexVideo,
} from '@src/utils/regex'
import {
  Button,
  Col,
  Form,
  FormInstance,
  FormItemProps,
  Image,
  Input,
  Row,
} from 'antd'
import { useEffect, useRef, useState } from 'react'

type TSelectMultipleFileFormItem<T = any> = {
  form: FormInstance<T>
  h?: number
  initialValue?: TFile[]
  onFileSelected?: (data: any) => void
  buttonText?: string
}

function SelectMultipleFileFormItem({
  form,
  h = 400,
  onFileSelected,
  buttonText = 'Add to gallery',
  ...props
}: TSelectMultipleFileFormItem & FormItemProps) {
  const mediaManagerModalRef = useRef<null | THandleMediaManagerModal>(null)
  const [filesSelected, setFilesSelected] = useState<string[] | undefined>(
    undefined,
  )

  const handleAddFile = (files: string[]) => {
    setFilesSelected(files)
  }

  useEffect(() => {
    setFilesSelected(props?.initialValue)
  }, [props?.initialValue])

  useEffect(() => {
    if (props?.name)
      form.setFieldsValue({
        [`${props?.name}`]: filesSelected?.map((item) => item),
      })
    if (onFileSelected) onFileSelected(filesSelected)
  }, [filesSelected])

  const handleCloseFile = (fileID: string) => {
    setFilesSelected((old) => old?.filter((f) => f !== fileID))
  }

  return (
    <Row className="select-multiple-file-container">
      <Col span={24} className="file-selected">
        <Row style={{ width: '100%' }}>
          <Col span={24} className="file-selected-view" style={{ height: h }}>
            <Row gutter={[10, 10]} style={{ width: '100%' }}>
              {filesSelected &&
                filesSelected?.length > 0 &&
                filesSelected?.map((file: any, index) => {
                  let fileType = 'unknown'
                  if (file?.match(regexImage)) {
                    fileType = 'image'
                  }
                  if (file?.match(regexDocument)) {
                    fileType = 'docx'
                  }
                  if (file?.match(regexVideo) || file?.match(regexAudio)) {
                    fileType = 'audio'
                  }

                  return (
                    <Col
                      xxl={6}
                      xl={4}
                      lg={8}
                      md={8}
                      sm={12}
                      xs={12}
                      className="image-view-item"
                      key={index}
                    >
                      {fileType === 'image' && (
                        <Image
                          wrapperClassName="image-ant"
                          src={file || placeholderImage}
                          height={100}
                        />
                      )}
                      {fileType === 'docx' && (
                        <Row className="image-ant">
                          <FileTextOutlined style={{ fontSize: 80 }} />
                        </Row>
                      )}
                      {fileType === 'audio' && (
                        <Row className="image-ant">
                          <PlaySquareOutlined style={{ fontSize: 80 }} />
                        </Row>
                      )}
                      {fileType === 'unknown' && (
                        <Row className="image-ant">
                          <FileExclamationOutlined style={{ fontSize: 80 }} />
                        </Row>
                      )}
                      <CloseSquareFilled
                        className="close-image-view"
                        onClick={() => handleCloseFile(file)}
                      />
                    </Col>
                  )
                })}
            </Row>
          </Col>
          <Col span={24} className="file-selected-control">
            <Row justify="end">
              <Button
                onClick={() => mediaManagerModalRef.current?.onOpen(true)}
              >
                {buttonText}
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
      <Form.Item {...props}>
        <Input hidden />
      </Form.Item>
      <MediaManagerModal
        isMultiselect
        ref={mediaManagerModalRef}
        handleAddFile={(files: TFile[]) => {
          let fileLocationSelect: any = []
          if (files && files.length > 0) {
            const fileLocation: any = files.map(
              (value) => value?.location || value,
            )

            handleAddFile(fileLocation)
            fileLocationSelect = [...fileLocation]
          }
          if (onFileSelected) onFileSelected(fileLocationSelect)
        }}
        filesSelectedInit={filesSelected}
      />
    </Row>
  )
}

export default SelectMultipleFileFormItem
