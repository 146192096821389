import { Input, InputProps } from 'antd'
import React from 'react'

export interface SearchNameProps extends InputProps {
  isLoading?: boolean
}

const SearchName: React.FC<SearchNameProps> = ({ isLoading, ...props }) => (
  <Input.Search
    {...props}
    className="search-bar"
    name="search"
    allowClear
    loading={isLoading}
    disabled={isLoading}
    size="middle"
  />
)

export default SearchName
