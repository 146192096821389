import { RoleItemType, RolesQueryParams } from '@src/models/role'

import { request } from './config'

export const getRoles = (params: RolesQueryParams, token: string) =>
  request(
    {
      url: '/role',
      method: 'GET',
      params,
    },
    { token },
  )
export const getRoleDetails = (roleId: any, token: string) =>
  request(
    {
      url: `/role/${roleId}`,
      method: 'GET',
    },
    { token },
  )

export const getPermissions = (token: string) =>
  request(
    {
      url: `/role/permissions`,
      method: 'GET',
    },
    { token },
  )
// export const createRole = (params: RoleItemType, token: string) =>
//   request(
//     {
//       url: '/roles',
//       method: 'POST',
//       data: params,
//     },
//     { token },
//   )

export const updateRole = (
  { id, body }: { id: string; body: any },
  token: string,
) =>
  request(
    {
      url: `/role/${id}`,
      method: 'PUT',
      data: body,
    },
    { token },
  )

export const deleteRole = (id: string, token: string) =>
  request(
    {
      url: `/role/${id}`,
      method: 'DELETE',
    },
    { token },
  )

export const createRole = (newRole: RoleItemType, token: string) =>
  request(
    {
      url: '/role',
      method: 'POST',
      data: newRole,
    },
    { token },
  )
