import { LoginOutlined, UserOutlined } from '@ant-design/icons'
import { UserItemType } from '@src/models/user'
import { useQueryLogOut } from '@src/queries/hooks'
import { Avatar, Button, Dropdown, Menu, Space, MenuProps } from 'antd'
import { Link, useNavigate } from 'react-router-dom'

type MenuItem = Required<MenuProps>['items'][number]
function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem
}

function NavigateAuth({ profile }: { profile: UserItemType | any }) {
  const navigator = useNavigate()
  const { mutate } = useQueryLogOut()
  const handleProfile = () => {
    navigator(`/users/${profile._id}`)
  }
  const handleLogout = () => {
    mutate(undefined, {
      onSettled: () => {
        navigator('/login')
      },
    })
  }
  const items: MenuItem[] = [
    getItem(
      <p aria-hidden="true" style={{ margin: 0 }} onClick={handleProfile}>
        {profile?.email}
      </p>,
      '1',
      <UserOutlined />,
    ),
    getItem(
      <p aria-hidden="true" onClick={handleLogout} style={{ margin: 0 }}>
        Logout
      </p>,
      '2',
      <LoginOutlined />,
    ),
  ]

  const menu = () => <Menu items={items} />

  if (!profile)
    return (
      <Button type="primary">
        <Link to="/login">LOGIN</Link>
      </Button>
    )
  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <Space size={10} style={{ cursor: 'pointer' }}>
        <Avatar icon={<UserOutlined />} src={profile?.avatar} size={25} />
        Xin chào, {profile.email}
      </Space>
    </Dropdown>
  )
}

export default NavigateAuth
