import { labelStyleSpan10 } from '@src/configs/common'
import { EnterpriseData } from '@src/models'
import { Form, Switch } from 'antd'
import React from 'react'

const FormStatus = ({ data }: { data: EnterpriseData | undefined }) => (
  <>
    <Form.Item name="representative" {...labelStyleSpan10} label="Tiêu biểu">
      <Switch defaultChecked={data ? data?.representative : false} />
    </Form.Item>

    <Form.Item name="greenCard" {...labelStyleSpan10} label="Xác thực:">
      <Switch defaultChecked={data ? data?.greenCard : false} />
    </Form.Item>
  </>
)

export default FormStatus
