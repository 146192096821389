import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'
import { Ads, AdsQueryParams } from '@src/models'
import { notification } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import {
  createAds,
  delAdsById,
  getAds,
  getAdsById,
  stopAllAds,
  updateAds,
} from '../api/ads'

const ADS_KEY = {
  LIST: 'LIST_ADS',
  DETAIL: 'DETAIL_ADS',
}

// -- Fn
export const useQueryAds = (params: AdsQueryParams) => {
  const accessToken = checkAuth()
  return useQuery([ADS_KEY.LIST, params], () => getAds(params, accessToken), {
    keepPreviousData: true,
    retry: 1,
  })
}

export const useAdsById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [ADS_KEY.DETAIL, id, getStoredLang()],
    () => getAdsById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useCreateAds = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((body: Ads) => createAds(body, accessToken), {
    onSuccess(data: ResApi) {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Create Success!' })
        queryClient.refetchQueries([ADS_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      if (error.message === 'The ads code must be unique') {
        notification.error({ message: 'Vị trí quảng cáo là duy nhất' })
      } else {
        notification.error({ message: error.message || 'Create failure!' })
      }
    },
  })
}

export const useUpdateAds = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((body: Ads) => updateAds(id, body, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Update Success!' })
        queryClient.refetchQueries([ADS_KEY.LIST])
        queryClient.refetchQueries([ADS_KEY.DETAIL, id, getStoredLang()])
      }
    },
    onError: (error: ResApiErr) => {
      if (error.message === 'The ads code must be unique') {
        notification.error({ message: 'Vị trí quảng cáo là duy nhất' })
      } else {
        notification.error({ message: error.message || 'Update failure!' })
      }
    },
  })
}

export const useDeleteAds = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delAdsById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      notification.success({ message: data.message || 'Delete Success!' })
      queryClient.refetchQueries([ADS_KEY.LIST])
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}

export const useStopAllAds = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(() => stopAllAds(accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({
          message: data.message || 'Stop all Ads Success!',
        })
        queryClient.refetchQueries([ADS_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Stop all Ads failure!' })
    },
  })
}
