import { PERMISSIONS } from '@src/configs/permissions'
import { TRANSACTION_URL } from '@src/configs/urlRouter'
import lazyLoading from '@src/libs/lazyLoading'
import { RouteConfigItemType } from '@src/route/routeConfig'

const ListTransaction = lazyLoading(
  () => import('@pages/transaction/ListTransaction'),
)

const transactionRouter: RouteConfigItemType[] = [
  {
    path: TRANSACTION_URL.LIST,
    Element: ListTransaction,
    key: PERMISSIONS.LIST_ENTERPRISE, // fix
  },
]

export { transactionRouter }
