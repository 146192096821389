import { BASE_URL as URL } from '@src/configs/urlRouter'

import { request } from './config'

class BaseDataApi {
  getSector() {
    return request({ url: URL.SECTOR, method: 'GET' })
  }

  getRegion() {
    return request({ url: URL.REGION, method: 'GET' })
  }

  getIndustrial() {
    return request({ url: URL.INDUSTRIAL, method: 'GET' })
  }
}

export const baseDataApi = new BaseDataApi()

// --------Fn-----------
export const getListEconomicSector = () =>
  request({ url: 'base-data/sector', method: 'GET' })

export const getListEconomicRegion = () =>
  request({ url: 'base-data/region', method: 'GET' })
export const getListEconomicRegionUrban = () =>
  request({ url: 'base-data/region-urban', method: 'GET' })

export const getListGenreIndustrialZone = () =>
  request({ url: 'base-data/industrial-zone', method: 'GET' })

export const getListCurrentSituation = () =>
  request({ url: 'base-data/current-situation', method: 'GET' })

export const getListEconomicZoneType = () =>
  request({ url: 'base-data/economic-zone', method: 'GET' })
export const getListUrbanAreaType = () =>
  request({ url: 'base-data/urban-area-type', method: 'GET' })
export const getListUrbanArea2 = () =>
  request({ url: 'base-data/urban-area', method: 'GET' })
