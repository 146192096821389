import { ResApi, ResApiErr } from '@configs/resApi.interface'
import { RolesQueryParams } from '@models/role'
import { queryClient } from '@queries/index'
import { checkAuth } from '@src/libs/localStorage'
import { RoleItemType } from '@src/models/role'
import {
  GET_PERMISSIONS,
  GET_ROLES,
  GET_ROLES_DETAIL,
} from '@src/queries/keys/'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import {
  deleteRole,
  getPermissions,
  getRoleDetails,
  getRoles,
  updateRole,
} from '../api'
import { createRole } from '../api/role'

export const useQueryGetRole = (params: RolesQueryParams) => {
  const accessToken = checkAuth()
  return useQuery<any, Error>(
    [GET_ROLES, params],
    () => getRoles(params, accessToken),
    {
      keepPreviousData: true,
      retry: 2,
    },
  )
}
export const useQueryGetRoleDetail = (id: any) => {
  const accessToken = checkAuth()
  return useQuery<any, Error>(
    [GET_ROLES_DETAIL, id],
    () => getRoleDetails(id, accessToken),
    {
      enabled: !!id,
      retry: 1,
    },
  )
}
export const useQueryGetPermissions = () => {
  const accessToken = checkAuth()
  return useQuery([GET_PERMISSIONS], () => getPermissions(accessToken), {
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })
}

export const useMutationUpdateRole = () => {
  const accessToken = checkAuth()

  return useMutation(
    ({ id, body }: { id: string; body: any }) =>
      updateRole({ id, body }, accessToken),
    {
      onSuccess: async (data: ResApi) => {
        if (data.statusCode === 200) {
          notification.success({ message: data.message || 'Update Success!' })
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Update failure!' })
      },
      onSettled: () => queryClient.invalidateQueries(),
    },
  )
}

export const useMutationDeleteRole = () => {
  const accessToken = checkAuth()
  const navigate = useNavigate()
  return useMutation((id: string) => deleteRole(id, accessToken), {
    onSuccess: async (data: ResApi) => {
      if (data.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        navigate('/roles')
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
    onSettled: () => queryClient.invalidateQueries(),
  })
}

export const useQueryCreateRole = () => {
  const navigate = useNavigate()
  const accessToken = checkAuth()
  return useMutation((body: RoleItemType) => createRole(body, accessToken), {
    onSuccess: async (data: ResApi) => {
      if (data.statusCode === 201) {
        notification.success({
          message: data.message || 'Tạo Role thành công',
        })
        navigate('/roles')
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Create failure!' })
    },
    onSettled: () => queryClient.invalidateQueries(),
  })
}
