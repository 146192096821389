/* eslint-disable no-unused-vars */
export enum PERMISSIONS {
  GENERAL = 'GENERAL',
  // User Module
  CREATE_USER = 'CREATE_USER',
  LIST_USERS = 'LIST_USERS',
  VIEW_USER = 'VIEW_USER',
  UPDATE_USER = 'UPDATE_USER',
  DELETE_USER = 'DELETE_USER',
  LIST_DELETED_USER = 'LIST_DELETED_USER',
  RESTORE_USER = 'RESTORE_USER',
  VIEW_DELETED_USER = 'VIEW_DELETED_USER',

  // Role Module
  LIST_ROLES = 'LIST_ROLES',
  CREATE_ROLE = 'CREATE_ROLE',
  VIEW_ROLE = 'VIEW_ROLE',
  UPDATE_ROLE = 'UPDATE_ROLE',
  DELETE_ROLE = 'DELETE_ROLE',

  // Mail module
  SEND_MAIL = 'SEND_MAIL',

  // Taxonomy module
  CREATE_TAXONOMY = 'CREATE_TAXONOMY',
  LIST_TAXONOMY = 'LIST_TAXONOMY',
  VIEW_TAXONOMY = 'VIEW_TAXONOMY',
  UPDATE_TAXONOMY = 'UPDATE_TAXONOMY',
  DELETE_TAXONOMY = 'DELETE_TAXONOMY',

  // News
  CREATE_NEWS = 'CREATE_NEWS',
  LIST_NEWS = 'LIST_NEWS',
  VIEW_NEWS = 'VIEW_NEWS',
  UPDATE_NEWS = 'UPDATE_NEWS',
  DELETE_NEWS = 'DELETE_NEWS',

  // Enterprise
  CREATE_ENTERPRISE = 'CREATE_ENTERPRISE',
  LIST_ENTERPRISE = 'LIST_ENTERPRISE',
  VIEW_ENTERPRISE = 'VIEW_ENTERPRISE',
  UPDATE_ENTERPRISE = 'UPDATE_ENTERPRISE',
  DELETE_ENTERPRISE = 'DELETE_ENTERPRISE',

  // Product
  CREATE_PRODUCT = 'CREATE_PRODUCT',
  LIST_PRODUCT = 'LIST_PRODUCT',
  VIEW_PRODUCT = 'VIEW_PRODUCT',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  DELETE_PRODUCT = 'DELETE_PRODUCT',

  // Product-order
  LIST_PRODUCT_ORDER = 'LIST_PRODUCT_ORDER',
  VIEW_PRODUCT_ORDER = 'VIEW_PRODUCT_ORDER',
  UPDATE_PRODUCT_ORDER = 'UPDATE_PRODUCT_ORDER',
  CREATE_PRODUCT_ORDER = 'CREATE_PRODUCT_ORDER',
  DELETE_PRODUCT_ORDER = 'DELETE_PRODUCT_ORDER',

  // Management
  CREATE_MANAGEMENT = 'CREATE_MANAGEMENT',
  LIST_MANAGEMENT = 'LIST_MANAGEMENT',
  VIEW_MANAGEMENT = 'VIEW_MANAGEMENT',
  UPDATE_MANAGEMENT = 'UPDATE_MANAGEMENT',
  DELETE_MANAGEMENT = 'DELETE_MANAGEMENT',

  // Investor
  CREATE_INVESTOR = 'CREATE_INVESTOR',
  LIST_INVESTOR = 'LIST_INVESTOR',
  VIEW_INVESTOR = 'VIEW_INVESTOR',
  UPDATE_INVESTOR = 'UPDATE_INVESTOR',
  DELETE_INVESTOR = 'DELETE_INVESTOR',

  // Industrial Area
  CREATE_INDUSTRIAL_AREA = 'CREATE_INDUSTRIAL_AREA',
  LIST_INDUSTRIAL_AREA = 'LIST_INDUSTRIAL_AREA',
  VIEW_INDUSTRIAL_AREA = 'VIEW_INDUSTRIAL_AREA',
  UPDATE_INDUSTRIAL_AREA = 'UPDATE_INDUSTRIAL_AREA',
  DELETE_INDUSTRIAL_AREA = 'DELETE_INDUSTRIAL_AREA',

  // Call Investment
  CREATE_PROJECT = 'CREATE_PROJECT',
  LIST_PROJECT = 'LIST_PROJECT',
  VIEW_PROJECT = 'VIEW_PROJECT',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  DELETE_PROJECT = 'DELETE_PROJECT',

  // Juridical
  CREATE_JURIDICAL = 'CREATE_JURIDICAL',
  UPDATE_JURIDICAL = 'UPDATE_JURIDICAL',
  DELETE_JURIDICAL = 'DELETE_JURIDICAL',
  LIST_JURIDICAL = 'LIST_JURIDICAL',
  VIEW_JURIDICAL = 'VIEW_JURIDICAL',

  // Economic Zone
  CREATE_ECONOMIC_ZONE = 'CREATE_ECONOMIC_ZONE',
  LIST_ECONOMIC_ZONE = 'LIST_ECONOMIC_ZONE',
  VIEW_ECONOMIC_ZONE = 'VIEW_ECONOMIC_ZONE',
  UPDATE_ECONOMIC_ZONE = 'UPDATE_ECONOMIC_ZONE',
  DELETE_ECONOMIC_ZONE = 'DELETE_ECONOMIC_ZONE',

  // City - Province
  CREATE_CITY_PROVINCE = 'CREATE_CITY_PROVINCE',
  LIST_CITY_PROVINCE = 'LIST_CITY_PROVINCE',
  VIEW_CITY_PROVINCE = 'VIEW_CITY_PROVINCE',
  UPDATE_CITY_PROVINCE = 'UPDATE_CITY_PROVINCE',
  DELETE_CITY_PROVINCE = 'DELETE_CITY_PROVINCE',
  // urban - area
  CREATE_URBAN_AREA = 'CREATE_URBAN_AREA',
  LIST_URBAN_AREA = 'LIST_URBAN_AREA',
  VIEW_URBAN_AREA = 'VIEW_URBAN_AREA',
  UPDATE_URBAN_AREA = 'UPDATE_URBAN_AREA',
  DELETE_URBAN_AREA = 'DELETE_URBAN_AREA',
  // Hashtag
  CREATE_HASHTAG = 'CREATE_HASHTAG',
  LIST_HASHTAG = 'LIST_HASHTAG',
  VIEW_HASHTAG = 'VIEW_HASHTAG',
  UPDATE_HASHTAG = 'UPDATE_HASHTAG',
  DELETE_HASHTAG = 'DELETE_HASHTAG',

  // Coupon
  CREATE_COUPON = 'CREATE_COUPON',
  LIST_COUPON = 'LIST_COUPON',
  VIEW_COUPON = 'VIEW_COUPON',
  UPDATE_COUPON = 'UPDATE_COUPON',
  DELETE_COUPON = 'DELETE_COUPON',

  // TRANSACTION Module
  LIST_TRANSACTIONS = 'LIST_TRANSACTION',

  // Coupon
  CREATE_ADS = 'CREATE_ADS',
  LIST_ADS = 'LIST_ADS',
  VIEW_ADS = 'VIEW_ADS',
  UPDATE_ADS = 'UPDATE_ADS',
  DELETE_ADS = 'DELETE_ADS',

  // Banner
  CREATE_BANNER = 'CREATE_BANNER',
  LIST_BANNER = 'LIST_BANNER',
  VIEW_BANNER = 'VIEW_BANNER',
  UPDATE_BANNER = 'UPDATE_BANNER',
  DELETE_BANNER = 'DELETE_BANNER',

  // theme
  CREATE_THEME = 'CREATE_THEME',
  LIST_THEME = 'LIST_THEME',
  VIEW_THEME = 'VIEW_THEME',
  UPDATE_THEME = 'UPDATE_THEME',
  DELETE_THEME = 'DELETE_THEME',
  /// /media

  CREATE_MEDIA = 'CREATE_MEDIA',
  LIST_MEDIA = 'LIST_MEDIA',
  VIEW_MEDIA = 'VIEW_MEDIA',
  UPDATE_MEDIA = 'UPDATE_MEDIA',
  DELETE_MEDIA = 'DELETE_MEDIA',
  UPLOAD_MEDIA = 'UPLOAD_MEDIA',
  CREATE_FOLDER = 'CREATE_FOLDER',
  DELETE_FOLDER = 'DELETE_FOLDER',
  LIST_FOLDER = 'LIST_FOLDER',
  VIEW_FOLDER = 'VIEW_FOLDER',
  VIEW_MEDIA_FOLDER = 'VIEW_MEDIA_FOLDER',
}
