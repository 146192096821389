import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  AuditOutlined,
  BankOutlined,
  ClusterOutlined,
  ControlOutlined,
  DashboardOutlined,
  FileImageOutlined,
  GlobalOutlined,
  HomeOutlined,
  SafetyCertificateOutlined,
  SettingOutlined,
  SlackOutlined,
  TeamOutlined,
  WechatOutlined,
} from '@ant-design/icons'
import logoApp from '@assets/images/logo.png'
import { PERMISSIONS } from '@src/configs/permissions'
import {
  CITY_PROVINCE_URL,
  ECONOMICZONE_URL,
  ENTERPRISE_URL,
  HASHTAG_URL,
  INDUSTRIAL_URL,
  INVESTOR_URL,
  INVEST_PROJECT_URL,
  MANAGEMENT_URL,
  MEDIA_URL,
  NEWS_URL,
  PRODUCT_ORDER_URL,
  PRODUCT_URL,
  TAXONOMY_URL,
  URBAN_AREA_URL,
  PROJECT_URL,
  JURIDICAL_URL,
} from '@src/configs/urlRouter'
import { usePermission } from '@src/hooks/usePermissions'
import { useQueryProfile } from '@src/queries/hooks'
import { queryBaseData } from '@src/queries/hooks/baseData'
import { onLinkNavigate } from '@src/utils'
import { Layout, Menu, MenuProps, Row, Space } from 'antd'
import React, { ReactNode } from 'react'
import { Link, Outlet } from 'react-router-dom'

import Breadcrumb from '../elements/Breadcrumb'
import NavigateAuth from '../widgets/NavigateAuth'
import SelectLang from '../widgets/SelectData/SelectLang'

const { Header, Content, Footer, Sider } = Layout

interface PropsType {
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode
}

type MenuItem = Required<MenuProps>['items'][number]

const rootSubmenuKeys = [
  '1',
  'user-1',
  'roles-1',
  `${INDUSTRIAL_URL.LIST}-1`,
  `${PRODUCT_URL.LIST}-1`,
  `${ENTERPRISE_URL.LIST}-1`,
  `${INVESTOR_URL.LIST}-1`,
  `${INVESTOR_URL.LIST}-1`,
  `${MANAGEMENT_URL.LIST}-1`,
  `${NEWS_URL.LIST}-1`,
  `${TAXONOMY_URL.LIST}-1`,
  `${CITY_PROVINCE_URL.LIST}-1`,
  `${ECONOMICZONE_URL.LIST}-1`,
  URBAN_AREA_URL.LIST,
  `${PRODUCT_ORDER_URL.LIST}-1`,
  `${HASHTAG_URL.LIST}-1`,
  `${MEDIA_URL.LIST}-1`,
  `${INVEST_PROJECT_URL.LIST}-1`,
  `${PROJECT_URL.LIST}-1`,
  'ads-1',
  // `${COUPON_URL.LIST}-1`,
  'theme-1',
]

const LayoutApp: React.FC<PropsType> = ({ children }) => {
  queryBaseData.querySector()
  queryBaseData.queryIndustrialZone()
  queryBaseData.queryRegion()
  const { data: profile } = useQueryProfile()
  const { permissions } = usePermission()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    keyName: PERMISSIONS,
    icon?: React.ReactNode,
    children?: MenuItem[],
  ): MenuItem {
    if (!permissions.includes(keyName)) return null
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem
  }

  const menuItems: MenuItem[] = [
    getItem(
      <Link to="/">Bảng điều khiển</Link>,
      '1',
      PERMISSIONS.GENERAL,
      <DashboardOutlined />,
    ),

    // Industrial-area
    getItem(
      'Khu công nghiệp',
      `${INDUSTRIAL_URL.LIST}-1`,
      PERMISSIONS.LIST_INDUSTRIAL_AREA,
      <AppstoreOutlined />,
      [
        getItem(
          <Link to={`/${INDUSTRIAL_URL.LIST}`}>Danh sách khu công nghiệp</Link>,
          INDUSTRIAL_URL.LIST,
          PERMISSIONS.LIST_INDUSTRIAL_AREA,
        ),
        getItem(
          <Link to={`/${INDUSTRIAL_URL.CREATE}`}>Thêm khu công nghiệp</Link>,
          INDUSTRIAL_URL.CREATE,
          PERMISSIONS.CREATE_INDUSTRIAL_AREA,
        ),
      ],
    ),

    // Economic-zone
    getItem(
      'Khu kinh tế',
      `${ECONOMICZONE_URL.LIST}-1`,
      PERMISSIONS.LIST_INDUSTRIAL_AREA,
      <AppstoreAddOutlined />,
      [
        getItem(
          <Link to={`/${ECONOMICZONE_URL.LIST}`}>Danh sách khu kinh tế</Link>,
          ECONOMICZONE_URL.LIST,
          PERMISSIONS.LIST_INDUSTRIAL_AREA,
        ),
        getItem(
          <Link to={`/${ECONOMICZONE_URL.CREATE}`}>Thêm khu kinh tế</Link>,
          ECONOMICZONE_URL.CREATE,
          PERMISSIONS.CREATE_INDUSTRIAL_AREA,
        ),
      ],
    ),

    // invest-project
    getItem(
      'Dự án đầu tư',
      `${PROJECT_URL.LIST}-1`,
      PERMISSIONS.LIST_PROJECT,
      <AppstoreAddOutlined />,
      [
        getItem(
          <Link to={`/${PROJECT_URL.LIST}`}>Danh sách dự án đầu tư</Link>,
          PROJECT_URL.LIST,
          PERMISSIONS.LIST_PROJECT,
        ),
        getItem(
          <Link to={`/${PROJECT_URL.CREATE}`}>Thêm dự án đầu tư</Link>,
          PROJECT_URL.CREATE,
          PERMISSIONS.CREATE_PROJECT,
        ),
      ],
    ),

    // Product
    getItem(
      'Sản phẩm',
      `${PRODUCT_URL.LIST}-1`,
      PERMISSIONS.LIST_PRODUCT,
      <ControlOutlined />,
      [
        getItem(
          <Link to={`/${PRODUCT_URL.LIST}`}>Danh sách sản phẩm</Link>,
          PRODUCT_URL.LIST,
          PERMISSIONS.LIST_PRODUCT,
        ),
        getItem(
          <Link to={`/${PRODUCT_URL.CREATE}`}>Thêm sản phẩm</Link>,
          PRODUCT_URL.CREATE,
          PERMISSIONS.CREATE_PRODUCT,
        ),
        getItem(
          <Link to={`/${PRODUCT_ORDER_URL.LIST}`}>Danh sách đơn hàng</Link>,
          PRODUCT_ORDER_URL.LIST,
          PERMISSIONS.LIST_PRODUCT_ORDER,
        ),
      ],
    ),
    // Văn bản pháp lý
    getItem(
      'Văn bản pháp lý',
      `${JURIDICAL_URL.LIST}-1`,
      PERMISSIONS.LIST_JURIDICAL,
      <ControlOutlined />,
      [
        getItem(
          <Link to={`/${JURIDICAL_URL.LIST}`}>Danh sách pháp lý</Link>,
          JURIDICAL_URL.LIST,
          PERMISSIONS.LIST_JURIDICAL,
        ),
        getItem(
          <Link to={`/${JURIDICAL_URL.CREATE}`}>Thêm pháp lý</Link>,
          JURIDICAL_URL.CREATE,
          PERMISSIONS.CREATE_JURIDICAL,
        ),
      ],
    ),
    // Enterprise
    getItem(
      'Doanh nghiệp',
      `${ENTERPRISE_URL.LIST}-1`,
      PERMISSIONS.LIST_ENTERPRISE,
      <BankOutlined />,
      [
        getItem(
          <Link to={`/${ENTERPRISE_URL.LIST}`}>Danh sách doanh nghiệp</Link>,
          ENTERPRISE_URL.LIST,
          PERMISSIONS.LIST_ENTERPRISE,
        ),
        getItem(
          <Link to={`/${ENTERPRISE_URL.CREATE}`}>Thêm doanh nghiệp</Link>,
          ENTERPRISE_URL.CREATE,
          PERMISSIONS.CREATE_ENTERPRISE,
        ),
      ],
    ),

    // Urban Area
    getItem(
      'Khu đô thị',
      `${URBAN_AREA_URL.LIST}`,
      PERMISSIONS.LIST_INDUSTRIAL_AREA,
      <AppstoreOutlined />,
      [
        getItem(
          <Link to={`/${URBAN_AREA_URL.LIST}`}>Danh sách khu đô thị</Link>,
          URBAN_AREA_URL.LIST,
          PERMISSIONS.LIST_INDUSTRIAL_AREA,
        ),
        getItem(
          <Link to={`/${URBAN_AREA_URL.CREATE}`}>Thêm khu đô thị</Link>,
          URBAN_AREA_URL.CREATE,
          PERMISSIONS.CREATE_INDUSTRIAL_AREA,
        ),
      ],
    ),

    // Investor
    getItem(
      'Chủ đầu tư',
      `${INVESTOR_URL.LIST}-1`,
      PERMISSIONS.LIST_INVESTOR,
      <ClusterOutlined />,
      [
        getItem(
          <Link to={`/${INVESTOR_URL.LIST}`}>Danh sách chủ đầu tư</Link>,
          INVESTOR_URL.LIST,
          PERMISSIONS.LIST_INVESTOR,
        ),
        getItem(
          <Link to={`/${INVESTOR_URL.CREATE}`}>Thêm chủ đầu tư</Link>,
          INVESTOR_URL.CREATE,
          PERMISSIONS.CREATE_INVESTOR,
        ),
      ],
    ),

    // Management
    getItem(
      'Ban quản lý',
      `${MANAGEMENT_URL.LIST}-1`,
      PERMISSIONS.LIST_MANAGEMENT,
      <AuditOutlined />,
      [
        getItem(
          <Link to={`/${MANAGEMENT_URL.LIST}`}>Danh sách ban quản lý</Link>,
          MANAGEMENT_URL.LIST,
          PERMISSIONS.LIST_MANAGEMENT,
        ),
        getItem(
          <Link to={`/${MANAGEMENT_URL.CREATE}`}>Tạo ban quản lý</Link>,
          MANAGEMENT_URL.CREATE,
          PERMISSIONS.CREATE_MANAGEMENT,
        ),
      ],
    ),

    // News
    getItem(
      'Tin tức',
      `${NEWS_URL.LIST}-1`,
      PERMISSIONS.LIST_NEWS,
      <WechatOutlined />,
      [
        getItem(
          <Link to={`/${NEWS_URL.LIST}`}>Danh sách tin</Link>,
          NEWS_URL.LIST,
          PERMISSIONS.LIST_NEWS,
        ),
        getItem(
          <Link to={onLinkNavigate(NEWS_URL.CREATE)}>Thêm tin</Link>,
          NEWS_URL.CREATE,
          PERMISSIONS.CREATE_NEWS,
        ),
      ],
    ),

    // Taxonomy
    getItem(
      'Taxonomy',
      `${TAXONOMY_URL.LIST}-1`,
      PERMISSIONS.LIST_TAXONOMY,
      <SlackOutlined />,
      [
        getItem(
          <Link to={`/${TAXONOMY_URL.LIST}`}>List Taxonomy</Link>,
          TAXONOMY_URL.LIST,
          PERMISSIONS.LIST_TAXONOMY,
        ),
        getItem(
          <Link to={`/${TAXONOMY_URL.CREATE}`}>Add Taxonomy</Link>,
          TAXONOMY_URL.CREATE,
          PERMISSIONS.CREATE_TAXONOMY,
        ),
      ],
    ),
    // City - province
    getItem(
      'Tỉnh / Thành Phố',
      `${CITY_PROVINCE_URL.LIST}-1`,
      PERMISSIONS.LIST_CITY_PROVINCE,
      <HomeOutlined />,
      [
        getItem(
          <Link to={`/${CITY_PROVINCE_URL.LIST}`}>
            Danh sách tỉnh/thành phố
          </Link>,
          CITY_PROVINCE_URL.LIST,
          PERMISSIONS.LIST_CITY_PROVINCE,
        ),
        getItem(
          <Link to={`/${CITY_PROVINCE_URL.CREATE}`}>Thêm tỉnh/thành phố</Link>,
          CITY_PROVINCE_URL.CREATE,
          PERMISSIONS.CREATE_CITY_PROVINCE,
        ),
      ],
    ),
    // hastag
    getItem(
      'Hashtag',
      `${HASHTAG_URL.LIST}-1`,
      PERMISSIONS.LIST_HASHTAG,
      <HomeOutlined />,
      [
        getItem(
          <Link to={`/${HASHTAG_URL.LIST}`}>Danh sách Hashtag</Link>,
          HASHTAG_URL.LIST,
          PERMISSIONS.LIST_HASHTAG,
        ),
        getItem(
          <Link to={`/${HASHTAG_URL.CREATE}`}>Thêm Hashtag</Link>,
          HASHTAG_URL.CREATE,
          PERMISSIONS.CREATE_HASHTAG,
        ),
      ],
    ),
    // Coupon
    // getItem(
    //   'Phiếu mua hàng',
    //   `${COUPON_URL.LIST}-1`,
    //   PERMISSIONS.LIST_COUPON,
    //   <HomeOutlined />,
    //   [
    //     getItem(
    //       <Link to={`/${COUPON_URL.LIST}`}>Danh sách</Link>,
    //       COUPON_URL.LIST,
    //       PERMISSIONS.LIST_COUPON,
    //     ),
    //     getItem(
    //       <Link to={`/${COUPON_URL.CREATE}`}>Thêm phiếu</Link>,
    //       COUPON_URL.CREATE,
    //       PERMISSIONS.CREATE_COUPON,
    //     ),
    //   ],
    // ),

    getItem('Người dùng', 'user-1', PERMISSIONS.LIST_USERS, <TeamOutlined />, [
      getItem(
        <Link to="/users">Danh sách người dùng</Link>,
        'user',
        PERMISSIONS.LIST_USERS,
      ),
      getItem(
        <Link to="/user-create">Thêm người dùng</Link>,
        'user-create',
        PERMISSIONS.CREATE_USER,
      ),
      // getItem(
      //   <Link to="/user/transaction">Danh sách giao dịch</Link>,
      //   'user-transaction',
      //   PERMISSIONS.LIST_TRANSACTIONS,
      // ),
      getItem(
        <Link to="/user-deleted">Người dùng bị xóa</Link>,
        'user-deleted',
        PERMISSIONS.LIST_DELETED_USER,
      ),
    ]),
    getItem(
      'Vai trò',
      'roles-1',
      PERMISSIONS.LIST_ROLES,
      <SafetyCertificateOutlined />,
      [
        getItem(
          <Link to="/roles">Danh sách vai trò</Link>,
          'roles',
          PERMISSIONS.LIST_ROLES,
        ),
        getItem(
          <Link to="/role-create">Thêm vai trò</Link>,
          'role-create',
          PERMISSIONS.LIST_ROLES,
        ),
      ],
    ),

    getItem('Ads', 'ads-1', PERMISSIONS.LIST_ADS, <GlobalOutlined />, [
      getItem(
        <Link to="/ads">Danh sách ads</Link>,
        'ads',
        PERMISSIONS.LIST_ADS,
      ),
      getItem(
        <Link to="/ads-create">Thêm Ads</Link>,
        'ads-create',
        PERMISSIONS.CREATE_ADS,
      ),
    ]),

    getItem('Media', 'media', PERMISSIONS.VIEW_MEDIA, <FileImageOutlined />, [
      getItem(
        <Link to="/media">Danh sách</Link>,
        'media-list',
        PERMISSIONS.LIST_ADS,
      ),
    ]),
    getItem('Cài đặt', 'theme-1', PERMISSIONS.LIST_THEME, <SettingOutlined />, [
      getItem(<Link to="/theme">ACF</Link>, 'theme-1', PERMISSIONS.LIST_THEME),
    ]),
  ]

  const [openKeys, setOpenKeys] = React.useState<string[]>([])
  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }
  return (
    <Layout style={{ minHeight: '100vh' }} hasSider>
      <Sider breakpoint="lg" collapsedWidth="0" style={{ zIndex: 1 }}>
        <div className="logo-app" style={{ textAlign: 'center' }}>
          <Link to="/">
            <img src={logoApp} alt="logo" />
          </Link>
        </div>
        <Menu
          theme="dark"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          mode="inline"
          items={menuItems}
        />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Row
            justify="end"
            align="middle"
            style={{
              marginLeft: 16,
              marginRight: 16,
              height: '100%',
            }}
          >
            <Space size={12}>
              <SelectLang />
              <NavigateAuth profile={profile?.data || null} />
            </Space>
          </Row>
        </Header>
        <Content style={{ margin: '0 16px' }}>
          <div style={{ margin: '16px 0' }}>
            <Breadcrumb />
          </div>
          <div
            style={{
              padding: '0',
              // minHeight: 'calc(100vh - 190px)',
            }}
          >
            {children ?? <Outlet />}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          {'Designed and Developed by '}
          <a href="https://twinger.vn/" target="_blank" rel="noreferrer">
            Twinger
          </a>
        </Footer>
      </Layout>
    </Layout>
  )
}

export default LayoutApp
