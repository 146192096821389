import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import { CITY_PROVINCE_URL } from '@src/configs/urlRouter'

import { RouteConfigItemType } from './interface'

const List = lazyLoading(() => import('@src/pages/city-province/List'))
const Create = lazyLoading(() => import('@pages/city-province/Create'))

const Detail = lazyLoading(() => import('@pages/city-province/Detail'))

const cityProvinceRouter: RouteConfigItemType[] = [
  {
    path: CITY_PROVINCE_URL.LIST,
    Element: List,
    key: PERMISSIONS.LIST_CITY_PROVINCE,
  },
  {
    path: CITY_PROVINCE_URL.CREATE,
    Element: Create,
    key: PERMISSIONS.CREATE_CITY_PROVINCE,
  },
  {
    path: CITY_PROVINCE_URL.DETAIL,
    Element: Detail,
    key: PERMISSIONS.VIEW_CITY_PROVINCE,
  },
]

export { cityProvinceRouter }
