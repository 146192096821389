import {
  CalendarOutlined,
  DeleteOutlined,
  MailOutlined,
  PlusOutlined,
  PushpinOutlined,
  SketchOutlined,
} from '@ant-design/icons'
import {
  emailVerify,
  statusDocument,
  statusDocumentBoolean,
  StatusDocumentEnum,
  TYPICAL,
} from '@src/configs/common'
import { ActionPublishData } from '@src/configs/interface.config'
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Popconfirm,
  Row,
  Select,
} from 'antd'
import moment from 'moment'

const { Option } = Select

interface ActionPublish {
  data?: ActionPublishData
  handlePublish?: () => void
  handleDelete?: () => void
  handleRestore?: () => void
  loadingCreate?: boolean
  loadingUpdate?: boolean
  loadingDelete?: boolean
  isStatusBoolean?: boolean
}

function ActionPublish({
  data,
  handleDelete,
  handlePublish,
  // handleRestore,
  loadingCreate,
  loadingDelete,
  // loadingUpdate,
  isStatusBoolean = false,
}: ActionPublish) {
  return (
    <Row>
      {data && data?.emailVerify !== undefined && (
        <Col span={24}>
          <Row>
            <Col flex="23px">
              <MailOutlined style={{ marginTop: 9 }} />
            </Col>
            <Col flex="1">
              <Form.Item
                name="emailVerify"
                initialValue={!!data?.emailVerify || false}
              >
                <Select placeholder="Select Status here" disabled>
                  {emailVerify.map((status) => (
                    <Option value={status.value} key={status.key}>
                      <Badge
                        status={status.status}
                        text={status.label.toUpperCase()}
                      />
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      )}
      {data && data?.representative !== undefined && (
        <Col span={24}>
          <Row>
            <Col flex="23px">
              <SketchOutlined style={{ marginTop: 9 }} />
            </Col>
            <Col flex="1">
              <Form.Item
                name="representative"
                initialValue={!!data?.representative || false}
              >
                <Select placeholder="Select Status here" disabled>
                  {TYPICAL.map((typical) => (
                    <Option value={typical.value} key={typical.key}>
                      <Badge
                        status={typical.status}
                        text={typical.label.toUpperCase()}
                      />
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      )}
      {!isStatusBoolean ? (
        <Col span={24}>
          <Row>
            <Col flex="23px">
              <PushpinOutlined style={{ marginTop: 9 }} />
            </Col>
            <Col flex="1">
              <Form.Item
                name="status"
                initialValue={data?.status || StatusDocumentEnum.INACTIVE}
              >
                <Select placeholder="Select Status here" disabled>
                  {statusDocument.map((status) => (
                    <Option value={status.value} key={status.key}>
                      <Badge
                        status={status.status}
                        text={status.value.toUpperCase()}
                      />
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      ) : (
        <Col span={24}>
          <Row>
            <Col flex="23px">
              <PushpinOutlined style={{ marginTop: 9 }} />
            </Col>
            <Col flex="1">
              <Form.Item name="status" initialValue={!!data?.status || false}>
                <Select placeholder="Select Status here" disabled>
                  {statusDocumentBoolean.map((status) => (
                    <Option value={status.value} key={status.key}>
                      <Badge
                        status={status.status}
                        text={status.label.toUpperCase()}
                      />
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      )}

      <Col span={24}>
        <Row>
          <Col flex="23px">
            <CalendarOutlined style={{ marginTop: 9 }} />
          </Col>
          <Col flex="1">
            <DatePicker
              showTime
              allowClear={false}
              className="date-picker-input"
              format="DD-MM-YYYY HH:mm:ss"
              defaultValue={moment(data?.createdAt || new Date())}
              disabled
            />
          </Col>
        </Row>
      </Col>
      <Divider style={{ margin: 0, marginBottom: 18, marginTop: 22 }} />
      {!data && (
        <Col span={24}>
          <Row justify="end">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handlePublish}
              loading={loadingCreate}
            >
              Publish
            </Button>
          </Row>
        </Col>
      )}
      {data && (
        <Col span={24}>
          <Row justify="space-between">
            <Popconfirm
              title="Are you sure to restore this user?"
              onConfirm={handleDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined />} loading={loadingDelete}>
                Khôi phục
              </Button>
            </Popconfirm>
          </Row>
        </Col>
      )}
    </Row>
  )
}

export default ActionPublish
