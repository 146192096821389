import { BASIC_CONTENT } from '@src/assets/contants'
import {
  GENDER,
  labelStyle,
  OrderByEnum,
  OrderEnum,
  SUPERADMINISTRATION,
} from '@src/configs/common'
import { RoleItemType } from '@src/models/role'
import { UserItemType } from '@src/models/user'
import { useQueryCurrentRole, useQueryGetRole } from '@src/queries/hooks'
import { regexEmail, regexPwdStrong } from '@src/utils'
import { DatePicker, Form, Input, InputNumber, Select } from 'antd'
import moment from 'moment'
import { useMemo } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const { Option } = Select

interface FormInput {
  data?: UserItemType
}

function FormInput({ data }: FormInput) {
  const { data: roles } = useQueryCurrentRole()
  const { data: dataRoles } = useQueryGetRole({
    page: 1,
    limit: 0,
    order: OrderEnum.DESC,
    orderBy: OrderByEnum.CREATE_AT,
  })

  const isSuperAdmin: boolean = useMemo(
    () =>
      roles?.data && roles?.data?.length > 0
        ? roles?.data
            ?.map((role: RoleItemType) => role.code)
            .includes(SUPERADMINISTRATION)
        : false,
    [roles],
  )

  const roleIds: string[] = useMemo(
    () =>
      data?.roles && data?.roles?.length > 0
        ? data?.roles?.map((role) => role._id)
        : [],
    [data],
  )

  return (
    <>
      <Form.Item
        name="email"
        label={`${BASIC_CONTENT.EMAIL}:`}
        initialValue={data?.email}
        rules={[
          {
            required: true,
            message: `${BASIC_CONTENT.EMAIL} không được để trống`,
          },
          {
            pattern: regexEmail,
            message: `${BASIC_CONTENT.EMAIL} không hợp lệ!`,
          },
        ]}
        {...labelStyle}
      >
        <Input
          placeholder={`Nhập ${BASIC_CONTENT.EMAIL.toLowerCase()} ...`}
          disabled={!!data}
        />
      </Form.Item>
      <Form.Item
        name="username"
        label={`${BASIC_CONTENT.USERNAME}:`}
        rules={[
          {
            required: true,
            message: `${BASIC_CONTENT.USERNAME} không được để trống`,
          },
        ]}
        {...labelStyle}
        initialValue={data?.username}
      >
        <Input
          placeholder={`Nhập ${BASIC_CONTENT.USERNAME.toLowerCase()} ...`}
          disabled={!!data}
        />
      </Form.Item>
      <Form.Item
        name="fullName"
        label={`${BASIC_CONTENT.FULL_NAME}:`}
        rules={[
          {
            required: true,
            message: `${BASIC_CONTENT.FULL_NAME} không được để trống`,
          },
        ]}
        {...labelStyle}
        initialValue={data?.fullName}
      >
        <Input
          placeholder={`Nhập ${BASIC_CONTENT.FULL_NAME.toLowerCase()} ...`}
          disabled={!!data}
        />
      </Form.Item>

      <Form.Item
        name="phone"
        label={`${BASIC_CONTENT.PHONE}:`}
        rules={[
          {
            required: true,
            message: `${BASIC_CONTENT.PHONE} không được để trống`,
          },
        ]}
        {...labelStyle}
        initialValue={data?.phone}
      >
        <PhoneInput
          country="vn"
          enableSearch
          placeholder={`Nhập ${BASIC_CONTENT.PHONE.toLowerCase()} ...`}
          containerStyle={{ width: '100%' }}
          inputStyle={{ width: '100%' }}
          disabled={!!data}
        />
      </Form.Item>

      {!data && (
        <>
          <Form.Item
            name="password"
            label={`${BASIC_CONTENT.PASSWORD}:`}
            rules={[
              {
                required: true,
                message: `${BASIC_CONTENT.PASSWORD} không được để trống`,
              },
              {
                pattern: regexPwdStrong,
                message:
                  'Mật khẩu phải chứa từ 6 đến 15 kí tự và chứa ít nhất 1 kí tự số và 1 kí tự đặc biệt: !@#$%^&*',
              },
            ]}
            {...labelStyle}
            hasFeedback
          >
            <Input.Password
              placeholder={`Nhập ${BASIC_CONTENT.PASSWORD.toLowerCase()} ...`}
              disabled={!!data}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label={`${BASIC_CONTENT.CONFIRM_PASSWORD}:`}
            dependencies={['password']}
            hasFeedback
            {...labelStyle}
            rules={[
              {
                required: true,
                message: 'Hãy xác nhận mật khẩu của bạn!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Mật khẩu không trùng khớp!'))
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Nhập lại mật khẩu..."
              disabled={!!data}
            />
          </Form.Item>
        </>
      )}

      <Form.Item
        name="roles"
        label={`${BASIC_CONTENT.ROLE}:`}
        {...labelStyle}
        initialValue={roleIds}
        rules={[
          {
            required: true,
            message: `${BASIC_CONTENT.ROLE} không được để trống`,
          },
        ]}
      >
        <Select
          placeholder={`Nhập ${BASIC_CONTENT.ROLE.toLowerCase()} ...`}
          mode="multiple"
          disabled={!!data}
        >
          {dataRoles?.data?.data?.length > 0 &&
            dataRoles?.data?.data?.map((role: any) => (
              <Option
                value={role?._id}
                key={role?._id}
                disabled={!isSuperAdmin && role?.code === SUPERADMINISTRATION}
              >
                {role?.code}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="birthday"
        label={`${BASIC_CONTENT.BIRTHDAY}:`}
        {...labelStyle}
        initialValue={moment(data?.birthday || new Date())}
      >
        <DatePicker
          allowClear={false}
          className="date-picker-input"
          format="DD-MM-YYYY"
          disabled={!!data}
        />
      </Form.Item>

      <Form.Item
        name="gender"
        label={`${BASIC_CONTENT.GENDER}:`}
        {...labelStyle}
        initialValue={data?.gender}
        rules={[
          {
            required: true,
            message: `${BASIC_CONTENT.GENDER} không được để trống`,
          },
        ]}
      >
        <Select placeholder="Chọn giới tính" disabled={!!data}>
          {GENDER.map((g) => (
            <Option value={g} key={g}>
              {g.toLocaleUpperCase()}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="country"
        label={`${BASIC_CONTENT.COUNTRY}:`}
        {...labelStyle}
        initialValue={data?.country}
      >
        <Input
          placeholder={`Nhập ${BASIC_CONTENT.COUNTRY.toLowerCase()} ...`}
          disabled={!!data}
        />
      </Form.Item>

      <Form.Item
        name="province"
        label="Tỉnh / Thành phố"
        {...labelStyle}
        initialValue={data?.province}
      >
        <Input placeholder="Nhập Tỉnh / Thành phố..." disabled={!!data} />
      </Form.Item>

      <Form.Item
        name="district"
        label="Quận / Huyện"
        {...labelStyle}
        initialValue={data?.district}
      >
        <Input placeholder="Nhập Quận / Huyện..." disabled={!!data} />
      </Form.Item>

      <Form.Item
        name="subDistrict"
        label="Phường / Xã"
        {...labelStyle}
        initialValue={data?.subDistrict}
      >
        <Input placeholder="Nhập Phường / Xã ..." disabled={!!data} />
      </Form.Item>

      <Form.Item
        name="taxCodeOrCmnd"
        label="Mã số thuế / Số CMND"
        {...labelStyle}
        initialValue={data?.taxCodeOrCmnd}
      >
        <InputNumber
          placeholder="Nhập mã số thuế / số CMND..."
          disabled={!!data}
        />
      </Form.Item>
    </>
  )
}

export default FormInput
