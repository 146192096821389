import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CityProvinceData, CityProvinceQueryParams } from '@src/models'
import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { notification } from 'antd'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'

import {
  createCityProvince,
  delCityProvinceById,
  getCityProvinceById,
  getListCityProvince,
  updateCityProvince,
} from '../api'
import { CITY_PROVINCE_KEY } from '../keys'

export const useQueryCityProvince = (params: CityProvinceQueryParams) =>
  useQuery(
    [CITY_PROVINCE_KEY.LIST, params, getStoredLang()],
    () => getListCityProvince(params),
    {
      keepPreviousData: true,
      retry: 2,
    },
  )

export const useCreateCityProvince = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: CityProvinceData) => createCityProvince(body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Create Success!' })
          queryClient.refetchQueries([CITY_PROVINCE_KEY.LIST])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Create failure!' })
      },
    },
  )
}

export const useCityProvinceById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [CITY_PROVINCE_KEY.DETAIL, id, getStoredLang()],
    () => getCityProvinceById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useUpdateCityProvince = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: CityProvinceData) => updateCityProvince(id, body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Update Success!' })
          queryClient.refetchQueries([CITY_PROVINCE_KEY.LIST])
          queryClient.refetchQueries([
            CITY_PROVINCE_KEY.DETAIL,
            id,
            getStoredLang(),
          ])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Update failure!' })
      },
    },
  )
}

export const useDeleteCityProvince = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delCityProvinceById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        queryClient.refetchQueries([CITY_PROVINCE_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}
