import { OrderByEnum } from '@src/configs/common'

import { DefaultParams } from './queryParams'

export type AdsQueryParams = DefaultParams & {
  orderBy: OrderByEnum
  status?: boolean
  author?: string
}

export enum AdsKeyEnum {
  // HOME = 'HOME',
  // HOME_NEWS = 'HOME_NEWS',
  // NEWS_LIST_TOP = 'NEWS_LIST_TOP',
  // NEWS_LIST_BOTTOM = 'NEWS_LIST_BOTTOM',

  // NEWS_DETAIL = 'NEWS_DETAIL',
  // NEWS_HASHTAG = 'NEWS_HASHTAG',

  // NEWS_DETAIL_CONTENT = 'NEWS_DETAIL_CONTENT',
  // NEWS_LIST = 'NEWS_LIST',
  // NEWS_TAXONOMY = 'NEWS_TAXONOMY',
  // NEWS_DETAIL = 'NEWS_DETAIL',
  // NEWS_HASHTAG = 'NEWS_HASHTAG',
  HOME = 'HOME',
  HOME_NEWS = 'HOME_NEWS',

  NEWS_LIST_TOP = 'NEWS_LIST_TOP',
  NEWS_LIST_BOTTOM = 'NEWS_LIST_BOTTOM',

  NEWS_DETAIL = 'NEWS_DETAIL',
  NEWS_HASHTAG = 'NEWS_HASHTAG',

  NEWS_DETAIL_CONTENT = 'NEWS_DETAIL_CONTENT',
  JURIDICAL = 'JURIDICAL',
}

export const ListAdsKey = [
  {
    key: AdsKeyEnum.HOME,
    value: 'Trang chủ',
  },
  {
    key: AdsKeyEnum.HOME_NEWS,
    value: 'Danh sách tin tức',
  },
  {
    key: AdsKeyEnum.NEWS_LIST_TOP,
    value: 'Chuyên mục tin tức ',
  },
  {
    key: AdsKeyEnum.NEWS_LIST_BOTTOM,
    value: 'Chi tiết tin tức',
  },
  {
    key: AdsKeyEnum.NEWS_DETAIL,
    value: 'Tin tức theo hashtag',
  },
  {
    key: AdsKeyEnum.NEWS_HASHTAG,
    value: 'Tin tức theo hashtag',
  },
  {
    key: AdsKeyEnum.NEWS_DETAIL_CONTENT,
    value: 'Tin tức theo hashtag',
  },
  {
    key: AdsKeyEnum.JURIDICAL,
    value: 'Tin tức theo hashtag',
  },
]

export interface AdsManagerListProps {
  guide: string
  key: AdsKeyEnum
  show: boolean
  value: string
  mobile: string
}

export interface Ads {
  author: string
  exd: string
  schedule: string
  status: boolean
  title: string
  ads: AdsManagerListProps[]
}
