import { JuridicalQueryParams, TJuridical } from '@models/juridical'

import { request } from './config'

export const createJuridical = (data: TJuridical, token: string) =>
  request({ url: 'juridical', method: 'POST', data }, { token })
export const getListJuridical = (params: JuridicalQueryParams) =>
  request({ url: 'juridical', method: 'GET', params })
export const getDetailJuridical = (id: string) =>
  request({ url: `juridical/${id}`, method: 'GET' })
export const removeDetailJuridical = (id: string, token: string) =>
  request({ url: `juridical/${id}`, method: 'DELETE' }, { token })
export const updateDetailJuridical = (
  id: string,
  data: TJuridical,
  token: string,
) => request({ url: `juridical/${id}`, method: 'PUT', data }, { token })
