import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import { URBAN_AREA_URL } from '@src/configs/urlRouter'

import { RouteConfigItemType } from './interface'

const List = lazyLoading(() => import('@src/pages/urban-area/ListUrbanArea'))
const Create = lazyLoading(() => import('@pages/urban-area/CreateUrbanArea'))

const Detail = lazyLoading(() => import('@pages/urban-area/DetailUrbanArea'))

const urbanAreaRouter: RouteConfigItemType[] = [
  {
    path: URBAN_AREA_URL.LIST,
    Element: List,
    key: PERMISSIONS.LIST_INDUSTRIAL_AREA,
  },
  {
    path: URBAN_AREA_URL.CREATE,
    Element: Create,
    key: PERMISSIONS.LIST_INDUSTRIAL_AREA,
  },
  {
    path: URBAN_AREA_URL.DETAIL,
    Element: Detail,
    key: PERMISSIONS.CREATE_INDUSTRIAL_AREA,
  },
]

export { urbanAreaRouter }
