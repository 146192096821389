/* eslint-disable no-unused-vars */
import { OrderByEnum, OrderEnum } from '@src/configs/common'
import { ListDataResponse, ParamsListData } from '@src/configs/interface.config'
import logger from '@src/libs/logger'
import { Select, Spin } from 'antd'
import type { SelectProps } from 'antd/es/select'
import { debounce } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (param: ParamsListData) => Promise<ListDataResponse<any>>
  debounceTimeout?: number
  initOptions?: ValueType[]
}

function FormSelectSearch<
  ValueType extends {
    key?: string
    label: React.ReactNode
    value: string | number
  } = any,
>({
  fetchOptions,
  debounceTimeout = 800,
  initOptions = [],
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<ValueType[]>([])

  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      const dataFind: any[] = []
      fetchOptions({
        s: value,
        page: 1,
        limit: 20,
        order: OrderEnum.DESC,
        orderBy: OrderByEnum.CREATE_AT,
        isCms: true,
      }).then((newOptions) => {
        logger.debug('FormSelectSearch', newOptions)
        newOptions?.data?.data?.forEach((item, i: number) =>
          dataFind.push({
            key: i,
            label: item?.name || item?.username,
            value: item?._id,
          }),
        )
        if (fetchId !== fetchRef.current) {
          return
        }

        setOptions(dataFind)
        setFetching(false)
      })
    }
    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  useEffect(() => {
    debounceFetcher('')
  }, [debounceFetcher])

  return (
    <Select
      placeholder="Lựa chọn đối tượng"
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      showSearch
      notFoundContent={fetching ? <Spin size="small" /> : null}
      options={
        options?.length > 0
          ? [
              ...initOptions,
              ...options.filter(
                (option: ValueType) =>
                  initOptions.findIndex(
                    (initOption: ValueType) =>
                      initOption.value === option.value,
                  ) < 0,
              ),
            ]
          : initOptions
      }
      {...props}
    />
  )
}

export default FormSelectSearch
