import { TRemoveMany } from '@src/configs/interface.config'
import { TResApi } from '@src/configs/resApi.interface'
import { MEDIA_URL } from '@src/configs/urlRouter'
import { checkAuth } from '@src/libs/localStorage'
import {
  EMediaSystem,
  MediaData,
  MediaQueryParams,
  TFile,
  TMoveFile,
  TMoveFolder,
  TPatchFile,
  TPatchFolder,
  TPostFolder,
  TQueryFile,
  TQueryFolder,
} from '@src/models'
import { LangEnum, OrderByEnum, OrderEnum } from '@src/models/basic'

import { request } from './config'

class MediaApi {
  url: { LIST: string; CREATE: string; DETAIL: string }

  constructor() {
    this.url = MEDIA_URL
  }

  getSearch(s?: string) {
    const accessToken = checkAuth()
    const params = {
      page: 1,
      limit: 10,
      order: OrderEnum.ASC,
      orderBy: OrderByEnum.NAME,
      s: s || '',
    }
    return request(
      { url: MEDIA_URL.LIST, method: 'GET', params },
      { token: accessToken, lang: LangEnum.VI },
    )
  }
}

export const mediaApi = new MediaApi()
// --------FN----------
// export const getListmedia = (params: MediaQueryParamsParams) =>
//   request({ url: 'media', method: 'GET', params })

export const createMedia = (data: MediaData, token: string) =>
  request({ url: 'media', method: 'POST', data }, { token })

export const getMediaById = (id: string, token: string) =>
  request({ url: `media/${id}`, params: { isCms: true } }, { token })

export const updateMedia = (id: string, data: MediaData, token: string) =>
  request({ url: `media/${id}`, method: 'PUT', data }, { token })

export const delMediaById = (id: string, token: string) =>
  request({ url: `media/${id}`, method: 'DELETE' }, { token })

// --------------------Fn---------------------
export const getListMedia = (params: MediaQueryParams) =>
  request({ url: 'media', method: 'GET', params })

export const getDetailMedia = (id: string, token: string) =>
  request({ url: `media/${id}`, method: 'GET' }, { token })

/// ///////media module

// Folder API
export const postFolder = (data: TPostFolder, token?: string) =>
  request(
    {
      url: 'media/folder',
      method: 'POST',
      data: { ...data, systemType: 'NienGiam' },
    },
    { token },
  )
export const getListFolder = (params: TQueryFolder, token?: string) =>
  request({ url: 'media/folder', method: 'GET', params }, { token })
export const getFolderById = (id: string, token?: string) =>
  request(
    {
      url: `media/folder/${id}/`,
      method: 'GET',
      // data: { systemType: 'NienGiam' },
    },
    { token },
  )
export const patchFolderById = (
  id: string,
  data: TPatchFolder,
  token?: string,
) =>
  request(
    {
      url: `media/folder/${id}`,
      method: 'PUT',
      data: { ...data, systemType: 'NienGiam' },
    },
    { token },
  )
export const removeFolderById = (id: string, token?: string) =>
  request({ url: `media/folder/${id}`, method: 'DELETE' }, { token })
export const moveFolderById = (id: string, data: TMoveFolder, token?: string) =>
  request(
    {
      url: `media/folder/${id}`,
      method: 'PUT',
      data: { ...data },
    },
    { token },
  )
export const getFolderMakeTreeById = (id?: string, token?: string) =>
  request(
    { url: 'media/get/tree-folder', params: { id, systemType: 'NienGiam' } },
    { token },
  )

// File API
export const uploadFileBySystem = (
  system: EMediaSystem,
  data: FormData,
  // token?: string,
): Promise<TResApi<TFile | TFile[]>> => {
  const token = checkAuth()
  return request(
    {
      url: `media/upload`,
      method: 'POST',
      data,

      headers: {
        Accept: 'application/xml, text/plain, text/html, *.*',
        'Content-Type':
          'multipart/form-data, application/x-www-form-urlencoded;charset=utf-8',
      },
      transformRequest: [() => data],
      // transformRequest: [() => ( data)],
    },
    { lang: undefined, token },
  )
}

export const getListFile = (params: TQueryFile, token?: string) =>
  request({ url: 'media', method: 'GET', params }, { token })
export const getFileById = (id: string, token?: string) =>
  request({ url: `media/${id}`, method: 'GET' }, { token })
export const patchFileById = (id: string, data: TPatchFile, token?: string) =>
  request({ url: `media/${id}`, method: 'PUT', data }, { token })
export const removeFileById = (id: string, token?: string) =>
  request({ url: `media/${id}`, method: 'DELETE' }, { token })
export const removeManyFile = (data: TRemoveMany, token?: string) =>
  request({ url: 'delete-many', method: 'POST', data }, { token })
export const moveFile = (id: string, data: TMoveFile, token?: string) =>
  request(
    {
      url: `media/${id}`,
      method: 'PUT',
      data: { ...data, systemType: 'NienGiam' },
    },
    { token },
  )
