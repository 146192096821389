import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import DetailDeletedUser from '@src/pages/users/DetailDeletedUser'
import ListDeletedUsers from '@src/pages/users/ListDeletedUser'
import React from 'react'

import {
  ProductOrderRouter,
  adsRouter,
  cityProvinceRouter,
  couponRouter,
  economicZoneRouter,
  enterpriseRouter,
  hashTagRouter,
  industrialRouter,
  investProjectRouter,
  investorRouter,
  managementRouter,
  newsRouter,
  productRouter,
  taxonomyRouter,
  themeRouter,
  transactionRouter,
  urbanAreaRouter,
} from './router'
import { projectRouter } from './router/project'

export type RouteConfigItemType = {
  path: string
  Element: React.FC
  key: PERMISSIONS
}
/// media

const Media = lazyLoading(() => import('@pages/media'))

/// /
const Dashboard = lazyLoading(() => import('@pages/dashboard'))
const ListRole = lazyLoading(() => import('@pages/roles/ListRole'))
const CreateRole = lazyLoading(() => import('@pages/roles/CreateRole'))
const DetailRole = lazyLoading(() => import('@pages/roles/DetailRole'))

// users
const ListUsers = lazyLoading(() => import('@pages/users/ListUsers'))
const CreateUser = lazyLoading(() => import('@pages/users/CreateUser'))
const DetailUser = lazyLoading(() => import('@pages/users/DetailUser'))
// Juridical
const ListJuridical = lazyLoading(
  () => import('@src/pages/juridical/ListJuridical'),
)
const DetailJuridical = lazyLoading(
  () => import('@src/pages/juridical/DetailJuridical'),
)
const CreateJuridical = lazyLoading(
  () => import('@src/pages/juridical/CreateJuridical'),
)

const ListTransaction = lazyLoading(
  () => import('@pages/transaction/ListTransaction'),
)

const routeConfigUser: RouteConfigItemType[] = [
  {
    path: '',
    Element: Dashboard,
    key: PERMISSIONS.GENERAL,
  },
  {
    path: 'users',
    Element: ListUsers,
    key: PERMISSIONS.LIST_USERS,
  },
  {
    path: 'users/:userId',
    Element: DetailUser,
    key: PERMISSIONS.UPDATE_USER,
  },
  {
    path: 'user-create',
    Element: CreateUser,
    key: PERMISSIONS.CREATE_USER,
  },
  {
    path: 'user-transaction',
    Element: ListTransaction,
    key: PERMISSIONS.LIST_USERS,
  },
  {
    path: 'user-deleted',
    Element: ListDeletedUsers,
    key: PERMISSIONS.LIST_DELETED_USER,
  },
  {
    path: 'user-deleted/:userId',
    Element: DetailDeletedUser,
    key: PERMISSIONS.VIEW_DELETED_USER,
  },
  {
    path: 'roles',
    Element: ListRole,
    key: PERMISSIONS.LIST_ROLES,
  },
  {
    path: 'roles/:roleId',
    Element: DetailRole,
    key: PERMISSIONS.VIEW_ROLE,
  },
  {
    path: 'role-create',
    Element: CreateRole,
    key: PERMISSIONS.CREATE_ROLE,
  },
  // Media
  {
    path: '/media',
    Element: Media,
    key: PERMISSIONS.LIST_MEDIA,
  },
  // Juridical
  {
    path: 'juridical',
    Element: ListJuridical,
    key: PERMISSIONS.LIST_JURIDICAL,
  },
  {
    path: 'juridical/:id',
    Element: DetailJuridical,
    key: PERMISSIONS.VIEW_JURIDICAL,
  },
  {
    path: 'juridical-create',
    Element: CreateJuridical,
    key: PERMISSIONS.CREATE_JURIDICAL,
  },
]

const routeConfig = routeConfigUser
  .concat(industrialRouter)
  .concat(economicZoneRouter)
  .concat(productRouter)
  .concat(enterpriseRouter)
  .concat(managementRouter)
  .concat(newsRouter)
  .concat(investorRouter)
  .concat(taxonomyRouter)
  .concat(cityProvinceRouter)
  .concat(hashTagRouter)
  .concat(transactionRouter)
  .concat(couponRouter)
  .concat(urbanAreaRouter)
  .concat(adsRouter)
  .concat(themeRouter)
  .concat(ProductOrderRouter)
  .concat(investProjectRouter)
  .concat(projectRouter)

export default routeConfig
