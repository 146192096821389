import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import { PRODUCT_URL } from '@src/configs/urlRouter'
import DetailProduct from '@src/pages/product/DetailProduct'

import { RouteConfigItemType } from './interface'

const ListProduct = lazyLoading(() => import('@pages/product/ListProduct'))
const CreateProduct = lazyLoading(() => import('@pages/product/CreateProduct'))

const productRouter: RouteConfigItemType[] = [
  {
    path: PRODUCT_URL.LIST,
    Element: ListProduct,
    key: PERMISSIONS.LIST_PRODUCT,
  },
  {
    path: PRODUCT_URL.CREATE,
    Element: CreateProduct,
    key: PERMISSIONS.CREATE_PRODUCT,
  },
  {
    path: PRODUCT_URL.DETAIL,
    Element: DetailProduct,
    key: PERMISSIONS.VIEW_PRODUCT,
  },
]

export { productRouter }
