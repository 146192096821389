import HeadHtml from '@src/components/layout/HeadHtml'
import { Button, Card, Col, Collapse, Form, PageHeader, Row, Space } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import SearchName, { SearchNameProps } from '../input/SearchName'

const { Panel } = Collapse
export interface ListHeaderProps extends SearchNameProps {
  title?: string
  create?: string
  flag?: boolean
  setParams?: any
  params?: any
  dataFormToFilter?: {
    data: DataFilterType[]
    resetFilter: () => void
  }
}
interface DataFilterType {
  name: string
  label: string
  initialValues: string
  FormElement: React.ReactElement
}

// const fakeDataFilter: DataFilterType[] = [
//   {
//     name: 'economicRegion',
//     label: 'Vùng kinh tế',
//     placeholder: 'Chọn vùng kinh tế',

//     // type: 'select',
//     FormElement: <Select></Select>,
//   },
//   {
//     name: 'investor',
//     label: 'Chủ đầu tư',
//     placeholder: 'Chọn chủ đầu tư',

//     FormElement: <Select></Select>,
//   },
//   {
//     name: 'investor',
//     label: 'Chủ đầu tư',
//     placeholder: 'Chọn chủ đầu tư',
//     FormElement: <Select></Select>,
//   },
//   {
//     name: 'investor',
//     label: 'Chủ đầu tư',
//     placeholder: 'Chọn chủ đầu tư',
//     FormElement: <Select></Select>,
//   },
// ]
const ListHeader: React.FC<ListHeaderProps> = ({
  title,
  create,
  flag = true,
  dataFormToFilter,
  ...props
}) => {
  const navigate = useNavigate()
  const navigateCreate = () => {
    navigate(`/${create}`)
  }

  return (
    <>
      <Row
        justify="space-between"
        align="bottom"
        style={{ marginBottom: 16 }}
        gutter={[16, 8]}
      >
        <HeadHtml title={title || 'title'} />
        {flag && (
          <Col sm={24} md={12} xs={24} className="row-header">
            <Row>
              <PageHeader
                title={title || 'title'}
                backIcon={false}
                className="page-header"
                extra={[
                  <Button size="large" key="create" onClick={navigateCreate}>
                    Tạo mới
                  </Button>,
                ]}
              />
            </Row>
          </Col>
        )}
        <Col sm={24} md={10} xs={24}>
          <Row justify="end" align="middle" gutter={[16, 0]}>
            <Col>
              <SearchName {...props} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Collapse defaultActiveKey={['1']} expandIconPosition="end">
        <Panel header="Bộ lọc" key="2">
          <Row
            // justify="space-around"
            align="middle"
            style={{ marginBottom: 16 }}
            gutter={[8, 16]}
          >
            <Card
              style={{ width: '100%' }}
              actions={[
                <Button
                  type="primary"
                  style={{ float: 'right', margin: '0 16px' }}
                  onClick={dataFormToFilter?.resetFilter}
                >
                  Xóa bộ lọc
                </Button>,
              ]}
            >
              <Space wrap size={[16, 8]}>
                {/* <Row align="middle" justify="start" wrap={true} gutter={[16, 16]}> */}
                {dataFormToFilter?.data?.map(
                  (value: DataFilterType, index: number) => (
                    <Col sm={24} xs={24} md={8}>
                      <Form.Item
                        key={index}
                        style={{ width: '350px' }}
                        name={value.name}
                        label={value.label}
                        initialValue={value.initialValues}
                      >
                        {value.FormElement}
                      </Form.Item>
                    </Col>
                  ),
                )}
                {/* </Row> */}
              </Space>
            </Card>
          </Row>
        </Panel>
      </Collapse>
    </>
  )
}

export default ListHeader
