import { EnterpriseData, ListDataResponse } from '@src/models'
import { FormItemProps, Select, Spin } from 'antd'
import type { SelectProps } from 'antd/es/select'
import debounce from 'lodash/debounce'
import React, { useMemo, useRef, useState } from 'react'

export interface TypeSearchSelectDataProps extends FormItemProps {
  // taxonomyData?: TaxonomyData[]
}

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  //   fetchOptions: (search: string) => Promise<ValueType[]>
  fetchOptions: (search: string) => Promise<ListDataResponse<any>>
  debounceTimeout?: number
}

interface ValueType {
  //   key?: string | number
  label: React.ReactNode
  value?: string
  initialValue?: any
}

const TypeSearchSelectData = ({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps<ValueType>) => {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<ValueType[]>([])
  const fetchRef = useRef(0)

  //   const { data, isLoading, isFetched } = queryEnterprise.querySearch(query)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      const dataFind: ValueType[] = []

      fetchOptions(value).then((newOptions) => {
        newOptions.data.data.forEach((item: EnterpriseData) =>
          dataFind.push({ label: item.name, value: item._id }),
        )
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(dataFind)
        setFetching(false)
      })

      //   fetchOptions(value).then((newOptions) => {
      //     if (fetchId !== fetchRef.current) {
      //       // for fetch callback order
      //       return
      //     }

      //     setOptions(newOptions)
      //     setFetching(false)
      //   })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  React.useEffect(() => {
    debounceFetcher('')
  }, [])

  return (
    <Select
      //   labelInValue
      showSearch
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  )
}

export default TypeSearchSelectData

// {options.map((city) => (
//     <Select.Option key={city.key} value={city.value}>
//       {city.label}
//     </Select.Option>
//   ))}
