import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'
import { IndustrialArea, IndustrialQueryParams } from '@src/models'
import { notification } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import {
  createIndustrialArea,
  delIndustrialAreaById,
  getIndustrialAreaById,
  getListIndustrialArea,
  updateIndustrialArea,
} from '../api'
import { INDUSTRIAL_KEY } from '../keys'

export const useQueryIndustrialArea = (params: IndustrialQueryParams) =>
  //
  useQuery([INDUSTRIAL_KEY.LIST, params], () => getListIndustrialArea(params), {
    keepPreviousData: true,
    retry: 2,
  })

export const useCreateIndustrialArea = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: IndustrialArea) => createIndustrialArea(body, accessToken),
    {
      onSuccess(data: ResApi) {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Create Success!' })
          queryClient.refetchQueries([INDUSTRIAL_KEY.LIST])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Create failure!' })
      },
    },
  )
}

export const useIndustrialAreaById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [INDUSTRIAL_KEY.DETAIL, id, getStoredLang()],
    () => getIndustrialAreaById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useUpdateIndustrialArea = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: IndustrialArea) => updateIndustrialArea(id, body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Update Success!' })
          queryClient.refetchQueries([INDUSTRIAL_KEY.LIST])
          queryClient.refetchQueries([
            INDUSTRIAL_KEY.DETAIL,
            id,
            getStoredLang(),
          ])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Update failure!' })
      },
    },
  )
}

export const useDeleteIndustrialArea = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delIndustrialAreaById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      notification.success({ message: data.message || 'Delete Success!' })
      queryClient.refetchQueries([INDUSTRIAL_KEY.LIST])
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}
