import { LangEnum, OrderByEnum, OrderEnum } from '@src/configs/common'
import { URBAN_AREA_URL } from '@src/configs/urlRouter'
import { checkAuth } from '@src/libs/localStorage'
import { UrbanAreaData, UrbanAreaQueryParams } from '@src/models'

import { request } from './config'

class UrbanAreaApi {
  url: { LIST: string; CREATE: string; DETAIL: string }

  constructor() {
    this.url = URBAN_AREA_URL
  }

  getSearch(s?: string) {
    const accessToken = checkAuth()
    const params = {
      page: 1,
      limit: 10,
      order: OrderEnum.DESC,
      orderBy: OrderByEnum.NAME,
      s: s || '',
    }
    return request(
      { url: URBAN_AREA_URL.LIST, method: 'GET', params },
      { token: accessToken, lang: LangEnum.VI },
    )
  }
}

export const urbanAreaApi = new UrbanAreaApi()
// --------FN----------
export const getListUrbanArea = (params: UrbanAreaQueryParams) =>
  request({ url: 'urban-area', method: 'GET', params })

export const createUrbanArea = (data: UrbanAreaData, token: string) =>
  request({ url: 'urban-area', method: 'POST', data }, { token })

export const getUrbanAreaById = (id: string, token: string) =>
  request({ url: `urban-area/${id}`, params: { isCms: true } }, { token })

export const updateUrbanArea = (
  id: string,
  data: UrbanAreaData,
  token: string,
) => request({ url: `urban-area/${id}`, method: 'PUT', data }, { token })

export const delUrbanAreaById = (id: string, token: string) =>
  request({ url: `urban-area/${id}`, method: 'DELETE' }, { token })
