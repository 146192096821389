import { useMutation, useQuery, useQueryClient } from 'react-query'
import { UrbanAreaData, UrbanAreaQueryParams } from '@src/models'
import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { notification } from 'antd'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'

import {
  createUrbanArea,
  delUrbanAreaById,
  getUrbanAreaById,
  getListUrbanArea,
  updateUrbanArea,
} from '../api'
import { URBAN_AREA_KEY } from '../keys'

export const useQueryUrbanArea = (params: UrbanAreaQueryParams) =>
  useQuery(
    [URBAN_AREA_KEY.LIST, params, getStoredLang()],
    () => getListUrbanArea(params),
    {
      keepPreviousData: true,
      retry: 2,
    },
  )

export const useCreateUrbanArea = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: UrbanAreaData) => createUrbanArea(body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Create Success!' })
          queryClient.refetchQueries([URBAN_AREA_KEY.LIST])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Create failure!' })
      },
    },
  )
}

export const useUrbanAreaById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [URBAN_AREA_KEY.DETAIL, id, getStoredLang()],
    () => getUrbanAreaById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useUpdateUrbanArea = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: UrbanAreaData) => updateUrbanArea(id, body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Update Success!' })
          queryClient.refetchQueries([URBAN_AREA_KEY.LIST])
          queryClient.refetchQueries([
            URBAN_AREA_KEY.DETAIL,
            id,
            getStoredLang(),
          ])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Update failure!' })
      },
    },
  )
}

export const useDeleteUrbanArea = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delUrbanAreaById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        queryClient.refetchQueries([URBAN_AREA_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}
