import { useMutation, useQuery, useQueryClient } from 'react-query'
import { InvestorData, InvestorQuery } from '@src/models'
import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { notification } from 'antd'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'

import {
  createInvestor,
  delInvestorById,
  getInvestorById,
  getListInvestor,
  updateInvestor,
} from '../api'
import { INVESTOR_KEY } from '../keys'

export const useQueryInvestor = (params: InvestorQuery) =>
  useQuery(
    [INVESTOR_KEY.LIST, params, getStoredLang()],
    () => getListInvestor(params),
    {
      retry: 2,
      keepPreviousData: true,
    },
  )

export const useCreateInvestor = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: InvestorData) => createInvestor(body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Create Success!' })
          queryClient.refetchQueries([INVESTOR_KEY.LIST])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Create failure!' })
      },
    },
  )
}

export const useInvestorById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [INVESTOR_KEY.DETAIL, id, getStoredLang()],
    () => getInvestorById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useUpdateInvestor = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: InvestorData) => updateInvestor(id, body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Update Success!' })
          queryClient.refetchQueries([INVESTOR_KEY.LIST])
          queryClient.refetchQueries([INVESTOR_KEY.DETAIL, id, getStoredLang()])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Update failure!' })
      },
    },
  )
}

export const useDeleteInvestor = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delInvestorById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        queryClient.refetchQueries([INVESTOR_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}
