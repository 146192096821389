import { NEWS_URL } from '@src/configs/urlRouter'
import { checkAuth } from '@src/libs/localStorage'
import { NewsData, NewsQueryParams } from '@src/models'
import { OrderEnum, OrderByEnum, LangEnum } from '@src/models/basic'

import { request } from './config'

class NewsApi {
  url: { LIST: string; CREATE: string; DETAIL: string }

  constructor() {
    this.url = NEWS_URL
  }

  getSearch(s?: string) {
    const accessToken = checkAuth()
    const params = {
      page: 1,
      limit: 10,
      order: OrderEnum.ASC,
      orderBy: OrderByEnum.NAME,
      s: s || '',
    }
    return request(
      { url: NEWS_URL.LIST, method: 'GET', params },
      { token: accessToken, lang: LangEnum.VI },
    )
  }
}

export const newsApi = new NewsApi()
// --------FN----------
export const getListNews = (params: NewsQueryParams) =>
  request({ url: 'news', method: 'GET', params })

export const createNews = (data: NewsData, token: string) =>
  request({ url: 'news/create', method: 'POST', data }, { token })

export const getNewsById = (id: string, token: string) =>
  request({ url: `news/${id}`, params: { isCms: true } }, { token })

export const updateNews = (id: string, data: NewsData, token: string) =>
  request({ url: `news/${id}`, method: 'PUT', data }, { token })

export const delNewsById = (id: string, token: string) =>
  request({ url: `news/${id}`, method: 'DELETE' }, { token })
