import { LangEnum, OrderByEnum, OrderEnum } from '@src/configs/common'
import { TRANSACTION_URL } from '@src/configs/urlRouter'
import { checkAuth } from '@src/libs/localStorage'
import { TransactionQueryParams } from '@src/models'
import { request } from '@src/queries/api/config'

export class TransactionApi {
  url: { LIST: string; CREATE: string; DETAIL: string }

  constructor() {
    this.url = TRANSACTION_URL
  }

  getSearch(s?: string) {
    const accessToken = checkAuth()
    const params = {
      page: 1,
      limit: 10,
      order: OrderEnum.DESC,
      orderBy: OrderByEnum.NAME,
      s: s || '',
    }
    return request(
      { url: TRANSACTION_URL.LIST, method: 'GET', params },
      { token: accessToken, lang: LangEnum.VI },
    )
  }
}

export const transactionApi = new TransactionApi()
// --------FN----------
export const getListTransaction = (
  token: string,
  params: TransactionQueryParams,
) =>
  request(
    { url: '/user/transaction/findAll', method: 'GET', params },
    { token },
  )

// export const createTransaction = (data: TransactionData, token: string) =>
//   request({ url: 'enterprise', method: 'POST', data }, { token })

export const getTransactionById = (id: string, token: string) =>
  request({ url: `user/transaction/${id}` }, { token })

// export const updateTransaction = (
//   id: string,
//   data: TransactionData,
//   token: string,
// ) => request({ url: `enterprise/${id}`, method: 'PUT', data }, { token })

// export const delTransactionById = (id: string, token: string) =>
//   request({ url: `enterprise/${id}`, method: 'DELETE' }, { token })
