import NotFound from '@components/widgets/404'
import Login from '@pages/login'
import LayoutApp from '@src/components/layout'
import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import ProtectedRoute from './ProtectedRoute'
import routeConfig, { RouteConfigItemType } from './routeConfig'

function RouteApp() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [pathname])

  return (
    <Routes>
      <>
        {routeConfig.map(
          ({ path, Element, key }: RouteConfigItemType, index: number) => (
            <Route
              path={path}
              key={index}
              element={
                <ProtectedRoute keyName={key}>
                  <LayoutApp>
                    <Element />
                  </LayoutApp>
                </ProtectedRoute>
              }
            />
          ),
        )}
      </>
      <Route path="login" element={<Login />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default RouteApp
