import { SignInPropType } from '@src/models/auth'

import { request } from './config'

export const singIn = (params: SignInPropType) =>
  request({ url: 'auth/login-cms', method: 'POST', data: params })
export const signOut = (token: string) =>
  request({ url: 'auth/logout', method: 'POST' }, { token })
export const refreshToken = (token: string, params: { refreshToken: string }) =>
  request(
    { url: 'auth/refresh-token', method: 'POST', data: params },
    { token },
  )
export const getProfile = (token: string) =>
  request({ url: 'user/profile', method: 'GET' }, { token })

export const getCurrentRoles = (token: string) =>
  request({ url: 'role/current', method: 'GET' }, { token })
