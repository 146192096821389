export const BASE_DATA_KEY = {
  SECTOR: 'SECTOR',
  REGION: 'REGION',
  REGION_URBAN: 'REGION_URBAN',
  INDUSTRIAL_ZONE: 'INDUSTRIAL_ZONE',
  CURRENT_SITUATION: 'CURRENT_SITUATION',
  TAXONOMY: 'TAXONOMY_KEY',
  ECONOMIC_ZONE: 'ECONOMIC_ZONE',
  URBAN_AREA: 'URBAN_AREA',
  URBAN_AREA_TYPE: 'URBAN_AREA_TYPE',
}
