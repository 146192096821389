import { UPLOAD_URL } from '@src/configs/urlRouter'
import { checkAuth } from '@src/libs/localStorage'
import { LangEnum } from '@src/models/basic'

import { request } from './config'

class UploadImgApi {
  url: { UPLOAD: string }

  constructor() {
    this.url = UPLOAD_URL
  }

  uploadImg(data: FormData) {
    const accessToken = checkAuth()
    return request(
      { url: this.url.UPLOAD, method: 'POST', data },
      { token: accessToken, lang: LangEnum.VI },
    )
  }
}

export const uploadImgApi = new UploadImgApi()
