import { IndustrialArea, IndustrialQueryParams } from '@src/models'

import { request } from './config'

export const getListIndustrialArea = (params: IndustrialQueryParams) =>
  request({ url: 'industrial-area', method: 'GET', params })

export const createIndustrialArea = (data: IndustrialArea, token: string) =>
  request({ url: 'industrial-area', method: 'POST', data }, { token })

export const getIndustrialAreaById = (id: string, token: string) =>
  request({ url: `industrial-area/${id}`, params: { isCms: true } }, { token })

export const updateIndustrialArea = (
  id: string,
  data: IndustrialArea,
  token: string,
) => request({ url: `industrial-area/${id}`, method: 'PUT', data }, { token })

export const delIndustrialAreaById = (id: string, token: string) =>
  request({ url: `industrial-area/${id}`, method: 'DELETE' }, { token })
