import {
  TPostInvestProject,
  TPutInvestProject,
  TQueryParamsInvestProject,
} from '@src/models'

import { request } from './config'

export const getListInvestProject = (params: TQueryParamsInvestProject) =>
  request({ url: 'project', method: 'GET', params })

export const createInvestProject = (data: TPostInvestProject, token: string) =>
  request({ url: 'project', method: 'POST', data }, { token })

export const getInvestProjectById = (id: string, token: string) =>
  request({ url: `project/${id}`, params: { isCms: true } }, { token })

export const updateInvestProject = (
  id: string,
  data: TPutInvestProject,
  token: string,
) => request({ url: `project/${id}`, method: 'PUT', data }, { token })

export const delInvestProjectById = (id: string, token: string) =>
  request({ url: `project/${id}`, method: 'DELETE' }, { token })
