import FormSelectSearch from '@src/components/widgets/FormSelectSearch'
import ListHeader from '@src/components/widgets/Header/Custom.Header'
import TypeSearchSelectData from '@src/components/widgets/SelectData/TypeSearch.SelectData'
import { BaseData, OrderByEnum, OrderEnum } from '@src/configs/common'
import { INDUSTRIAL_URL } from '@src/configs/urlRouter'
import { usePushQueryToUrl } from '@src/hooks'
import { getStoredLang } from '@src/libs/localStorage'
import { getListCityProvince, investorApi } from '@src/queries/api'
import { getListManagement } from '@src/queries/api/management'
import {
  useQueryEconomicRegionUrban,
  useQueryIndustrialArea,
} from '@src/queries/hooks'
import { onDetail } from '@src/utils'
import { Form, InputNumber, Select, Table, TablePaginationConfig } from 'antd'
import { debounce } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { columns } from './configs/table.config'

// import ListHeader from './components/List.header'
import './index.css'

function ListIndustrial() {
  const [searchParams, setSearchParams] = useSearchParams()
  // const [flag, setFlag] = useState<boolean>(false)
  const lang = getStoredLang()
  const { Option } = Select
  const renderOptionBaseData = (data: BaseData[], showId?: boolean) =>
    data?.length &&
    data?.map((item: BaseData | any) => (
      <Option value={item.id} key={item.id}>
        {showId ? `${item.id} - ` : ''}
        {item.translations[lang].label}
      </Option>
    ))
  const { data: economicRegion } = useQueryEconomicRegionUrban()
  // industrial-area?page=1&limit=10&order=DESC&orderBy=createdAt&s=&isCms=true&investor=62a6f048356a740c26abb913&economicRegion=trung_du_mien_nui_phia_bac&management=62b292506de710a4c920899d&fillRateMin=1&fillRateMax=2&cityProvince=62e38586241617c07e6bbb42
  // const searchParamsDefault = URLSearchParams

  const paramSearchUrl = useMemo(
    () => Object.fromEntries(Array.from(searchParams)),
    [searchParams],
  )

  const navigate = useNavigate()
  const baseParamsDefauls = {
    page: 1,
    limit: 10,
    order: OrderEnum.DESC,
    orderBy: OrderByEnum.CREATE_AT,
    s: '',
    isCms: true,
  }

  const baseParams = React.useMemo(
    () => ({
      page: 1,
      limit: 10,
      order: OrderEnum.DESC,
      orderBy: OrderByEnum.CREATE_AT,
      s: '',
      isCms: true,
      ...paramSearchUrl,
    }),
    [paramSearchUrl],
  )
  const [params, setParams] = useState<any>(baseParams)
  const { data, isLoading, isFetching } = useQueryIndustrialArea(params)

  const onChangeTable = (
    pagination: TablePaginationConfig,
    filter: any,
    sorter: any,
  ) => {
    setParams({
      ...params,
      page: pagination.current || 1,
      limit: pagination.pageSize || 10,
      order:
        sorter.order && sorter.order === 'ascend'
          ? OrderEnum.ASC
          : OrderEnum.DESC,
      status: filter.status?.length >= 2 ? '' : filter?.status?.[0],
      representative:
        filter.representative?.length >= 2 ? '' : filter?.representative?.[0],
      investor: filter.investor?.length >= 2 ? '' : filter?.investor?.[0],
      economicRegion:
        filter.economicRegion?.length >= 2 ? '' : filter?.economicRegion?.[0],
      management: filter.management?.length >= 2 ? '' : filter?.management?.[0],
      cityProvince:
        filter.cityProvince?.length >= 2 ? '' : filter?.cityProvince?.[0],
      fillRateMin:
        filter.fillRateMin?.length >= 2 ? '' : filter?.fillRateMin?.[0],
      fillRateMax:
        filter.fillRateMax?.length >= 2 ? '' : filter?.fillRateMax?.[0],
      orderBy:
        sorter.field === 'name' ? OrderByEnum.NAME : OrderByEnum.CREATE_AT,
    })
    // navigate(`?${params}`)
  }
  const { pushQuery } = usePushQueryToUrl()

  const handleFilterRegion = (value: any) => {
    setParams({
      ...params,

      economicRegion: value,
    })
  }
  const handleFilterInvestor = (value: any) => {
    setParams({
      ...params,
      investor: value,
    })
  }

  const handleManagement = (value: any) => {
    setParams({
      ...params,

      management: value.value,
    })
  }
  const handleCityProvince = (value: any) => {
    setParams({
      ...params,

      cityProvince: value.value,
    })
  }

  const [form] = Form.useForm()
  // useEffect(() => {
  //   setParams(baseParamsDefauls)
  // }, [params])
  // const handleReset2 = () => {
  //   // FormElement.resetFields()
  //   setParams(baseParamsDefauls)
  //   // pushQuery()(params)

  //   form.resetFields()

  // }
  const handleResetFilter = () => {
    // FormElement.resetFields()
    setParams(baseParamsDefauls)
    // pushQuery()(params)
    setSearchParams({})
    form.resetFields()
    form.setFieldsValue({
      economicRegion: undefined,
      investor: undefined,
      management: undefined,
      cityProvince: undefined,
      fillRateMin: undefined,
      fillRateMax: undefined,
      taxonomy: undefined,
    })

    // handleReset2()
  }
  useEffect(() => {
    pushQuery()(params)

    // handleResetFilter()
  }, [pushQuery, params])

  const fakeDataFilter = {
    data: [
      {
        name: 'economicRegion',
        label: 'Vùng kinh tế',
        initialValues: paramSearchUrl?.economicRegion,
        // type: 'select',
        FormElement: (
          <Select placeholder="Chọn vùng kinh tế" onChange={handleFilterRegion}>
            {renderOptionBaseData(economicRegion?.data || [])}
          </Select>
        ),
      },
      {
        name: 'investor',
        label: 'Chủ đầu tư',
        initialValues: paramSearchUrl?.investor,
        FormElement: (
          <TypeSearchSelectData
            onChange={handleFilterInvestor}
            placeholder="Chọn chủ đầu tư"
            fetchOptions={investorApi.getSearch}
          />
        ),
      },
      {
        name: 'management',
        label: 'Ban quản lý',
        initialValues: paramSearchUrl?.management,
        FormElement: (
          <FormSelectSearch
            fetchOptions={(p: any) => getListManagement(p)}
            onChange={handleManagement}
            placeholder="Chọn Ban quản lý"
          />
        ),
      },
      {
        name: 'cityProvince',
        label: 'Thành phố',
        initialValues: paramSearchUrl?.cityProvince,
        FormElement: (
          <FormSelectSearch
            fetchOptions={(p) => getListCityProvince(p)}
            placeholder="Chọn thành phố"
            onChange={handleCityProvince}
          />
        ),
      },
      {
        name: 'fillRateMin',
        label: 'Tỷ lệ lấp đầy tối thiểu',
        initialValues: paramSearchUrl?.fillRateMin,
        FormElement: (
          <InputNumber
            placeholder="Nhập tỷ lệ lấp đầy tối thiểu"
            type="number"
            min={0}
            max={100}
            onChange={debounce(
              (value: any) =>
                setParams({
                  ...params,
                  fillRateMin: value,
                }),
              750,
            )}
          />
        ),
      },
      {
        name: 'fillRateMax',
        label: 'Nhập tỷ lệ lấp đầy tối đa',
        initialValues: paramSearchUrl?.fillRateMax,
        FormElement: (
          <InputNumber
            placeholder="Nhập tỷ lệ lấp đầy tối đa"
            type="number"
            max={100}
            min={0}
            onChange={debounce(
              (value: any) =>
                setParams({
                  ...params,
                  fillRateMax: value,
                }),
              750,
            )}
          />
        ),
      },
      {
        name: 'taxonomy',
        label: 'Phân loại khu công nghiệp / cụm công nghiệp',
        initialValues: paramSearchUrl?.fillRateMax,
        FormElement: (
          <Select
            defaultValue="63a3e5357d167cb9ea25a385"
            // onChange={(value: string) => {
            //   form.setFieldsValue({
            //     taxonomy: value,
            //   })
            // }}
            onChange={debounce(
              (value: any) => {
                // eslint-disable-next-line no-unused-expressions, no-sequences
                setParams({
                  ...params,
                  taxonomy: value,
                })
                // setParams({
                //   ...params,
                //   taxonomy: value,
                // })
              },

              750,
            )}
            options={[
              {
                value: '63a3e5357d167cb9ea25a385',
                label: 'Khu công nghiệp',
              },
              {
                value: '63a3e54211dfebaad9624fd7',
                label: 'Cụm công nghiệp',
              },
              {
                value: null,
                label: 'Tất cả',
              },
            ]}
          />
        ),
      },
    ],
    resetFilter: handleResetFilter,
  }

  return (
    <>
      <Form layout="vertical" form={form}>
        <ListHeader
          title="Khu công nghiệp"
          create={INDUSTRIAL_URL.CREATE}
          placeholder="Search"
          onChange={debounce(
            (e: React.ChangeEvent<HTMLInputElement>) =>
              setParams({ ...params, s: e.target.value }),
            500,
          )}
          defaultValue={paramSearchUrl?.s}
          dataFormToFilter={fakeDataFilter}
        />
      </Form>

      <Table
        rowKey="_id"
        showSorterTooltip
        columns={columns}
        dataSource={data?.data?.data || []}
        loading={isLoading || isFetching}
        onRow={(item) => ({
          onClick: () => onDetail(navigate, item._id, INDUSTRIAL_URL.LIST),
        })}
        onChange={onChangeTable}
        pagination={{
          pageSize: data?.data.limit || 10,
          total: data?.data.total,
          current: data?.data.page || 1,
          pageSizeOptions: ['5', '10', '20'],
          showSizeChanger: true,
        }}
        scroll={{ x: 992 }}
      />
    </>
  )
}

export default ListIndustrial
