export const INDUSTRIAL_URL = {
  LIST: 'industrial-area',
  CREATE: 'industrial-area-create',
  DETAIL: 'industrial-area/:id',
}

export const ECONOMICZONE_URL = {
  LIST: 'economic-zone',
  CREATE: 'economic-zone-create',
  DETAIL: 'economic-zone/:id',
}

export const INVEST_PROJECT_URL = {
  LIST: 'invest-project',
  CREATE: 'invest-project-create',
  DETAIL: 'invest-project/:id',
}
export const PROJECT_URL = {
  LIST: 'list-project',
  CREATE: 'project-create',
  DETAIL: 'project/:id',
}

export const MANAGEMENT_URL = {
  LIST: 'management',
  CREATE: 'management-create',
  DETAIL: 'management/:id',
}

export const ENTERPRISE_URL = {
  LIST: 'enterprise',
  CREATE: 'enterprise-create',
  DETAIL: 'enterprise/:id',
}

export const INVESTOR_URL = {
  LIST: 'investor',
  CREATE: 'investor-create',
  DETAIL: 'investor/:id',
}

export const PRODUCT_URL = {
  LIST: 'product',
  CREATE: 'product-create',
  DETAIL: 'product/:id',
}

export const JURIDICAL_URL = {
  LIST: 'juridical',
  CREATE: 'juridical-create',
  DETAIL: 'juridical/:id',
}

export const NEWS_URL = {
  LIST: 'news',
  CREATE: 'news-create',
  DETAIL: 'news/:id',
}

export const TAXONOMY_URL = {
  LIST: 'taxonomy',
  CREATE: 'taxonomy-create',
  DETAIL: 'taxonomy/:id',
}

export const CITY_PROVINCE_URL = {
  LIST: 'city-province',
  CREATE: 'city-province-create',
  DETAIL: 'city-province/:id',
}

export const URBAN_AREA_URL = {
  LIST: 'urban-area',
  CREATE: 'urban-area-create',
  DETAIL: 'urban-area/:id',
}

export const HASHTAG_URL = {
  LIST: 'hashtag',
  CREATE: 'hashtag-create',
  DETAIL: 'hashtag/:id',
}

export const COUPON_URL = {
  LIST: 'coupon',
  CREATE: 'coupon-create',
  DETAIL: 'coupon/:id',
}

export const BASE_URL = {
  SECTOR: 'base-data/sector',
  REGION: 'base-data/region',
  INDUSTRIAL: 'base-data/industrial-zone',
}

export const UPLOAD_URL = {
  UPLOAD: 'media/upload',
}

export const TRANSACTION_URL = {
  LIST: 'user/transaction',
  CREATE: 'transaction-create',
  DETAIL: 'transaction/:id',
}

export const ADS_URL = {
  LIST: 'ads',
  CREATE: 'ads-create',
  DETAIL: 'ads/:id',
}
export const BANNER_URL = {
  LIST: 'theme',
  CREATE: 'theme-create',
  DETAIL: 'theme/:id',
}
export const PRODUCT_ORDER_URL = {
  LIST: 'product-order',
  CREATE: 'product-order-create',
  DETAIL: 'product-order/:id',
}
export const THEME_URL = {
  LIST: 'theme',
  CREATE: 'theme-create',
  DETAIL: 'theme/:id',
}
export const MEDIA_URL = {
  LIST: 'media',
  CREATE: 'media-create',
  DETAIL: 'media/:id',
}
