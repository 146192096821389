import { INVESTOR_URL } from '@src/configs/urlRouter'
import { checkAuth } from '@src/libs/localStorage'
import { InvestorData, InvestorQuery } from '@src/models'
import { LangEnum, OrderByEnum, OrderEnum } from '@src/models/basic'

import { request } from './config'

class InvestorApi {
  url: { LIST: string; CREATE: string; DETAIL: string }

  constructor() {
    this.url = INVESTOR_URL
  }

  getSearch(s?: string) {
    const accessToken = checkAuth()
    const params = {
      page: 1,
      limit: 10,
      order: OrderEnum.ASC,
      orderBy: OrderByEnum.NAME,
      s: s || '',
    }
    return request(
      { url: INVESTOR_URL.LIST, method: 'GET', params },
      { token: accessToken, lang: LangEnum.VI },
    )
  }
}

export const investorApi = new InvestorApi()
// --------FN----------
// export const getListInvestor = (params: InvestorQueryParams) =>
//   request({ url: 'investor', method: 'GET', params })

export const createInvestor = (data: InvestorData, token: string) =>
  request({ url: 'investor', method: 'POST', data }, { token })

export const getInvestorById = (id: string, token: string) =>
  request({ url: `investor/${id}`, params: { isCms: true } }, { token })

export const updateInvestor = (id: string, data: InvestorData, token: string) =>
  request({ url: `investor/${id}`, method: 'PUT', data }, { token })

export const delInvestorById = (id: string, token: string) =>
  request({ url: `investor/${id}`, method: 'DELETE' }, { token })

// --------------------Fn---------------------
export const getListInvestor = (params: InvestorQuery) =>
  request({ url: 'investor', method: 'GET', params })

export const getDetailInvestor = (id: string, token: string) =>
  request({ url: `investor/${id}`, method: 'GET' }, { token })
