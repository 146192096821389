import {
  //   ContactItem,
  OrderByEnum,
  StatusDocumentEnum,
} from '@src/configs/common'

import { AreaUnitEnum, TMetaSEO } from './basic'
import { CityProvinceData } from './cityProvince'
import { InvestorData } from './investor'
import { DefaultParams } from './queryParams'

export enum CurrencyUnit {
  VND = 'vnd',
  USD = 'usd',
}
export type AreaType = {
  value: number
  unit: AreaUnit
}
export enum AreaUnit {
  M = 'm',
  HA = 'ha',
  KM = 'km',
}

export interface UrbanAreaData {
  name: string
  cityProvince?: CityProvinceData
  slug: string
  content?: string
  address: string
  leasePriceL?: any
  thumbnail: string
  investorDetail?: string
  gallery: string[]

  investor: InvestorData

  totalArea: AreaType

  yearBuilt: number

  yearOfHandover: number

  type: string

  management: string

  buildingDensity: number

  totalBlocks: number

  totalFloors: number

  totalApartments: number

  typeApartment: string

  areaType: {
    min: number
    max: number
    unit: AreaUnitEnum
  }

  salePrice: {
    min: number
    max: number
    unit: CurrencyUnit
  }

  leasePrice: {
    min: number
    max: number
    unit: CurrencyUnit
  }

  legalPaper: string

  currentSituation: string // Hỏi acc base-data cho field này

  hashtag: any // Hashtag entity

  contacts: RawContactItem[]

  greenCard: boolean
  meta: TMetaSEO
}

export interface RawContactItem {
  label: string
  people: string
  phone: string
  email: string
  address: string
  website?: string
}

export type UrbanAreaQueryParams = DefaultParams & {
  orderBy: OrderByEnum
  status?: StatusDocumentEnum
  s?: string
  author?: string
}
