import { LangEnum, OrderByEnum, OrderEnum } from '@src/configs/common'
import { CITY_PROVINCE_URL } from '@src/configs/urlRouter'
import { checkAuth } from '@src/libs/localStorage'
import { CityProvinceData, CityProvinceQueryParams } from '@src/models'

import { request } from './config'

class CityProvinceApi {
  url: { LIST: string; CREATE: string; DETAIL: string }

  constructor() {
    this.url = CITY_PROVINCE_URL
  }

  getSearch(s?: string) {
    const accessToken = checkAuth()
    const params = {
      page: 1,
      limit: 10,
      order: OrderEnum.DESC,
      orderBy: OrderByEnum.NAME,
      s: s || '',
    }
    return request(
      { url: CITY_PROVINCE_URL.LIST, method: 'GET', params },
      { token: accessToken, lang: LangEnum.VI },
    )
  }
}

export const cityProvinceApi = new CityProvinceApi()
// --------FN----------
export const getListCityProvince = (params: CityProvinceQueryParams) =>
  request({ url: 'city-province', method: 'GET', params })

export const createCityProvince = (data: CityProvinceData, token: string) =>
  request({ url: 'city-province', method: 'POST', data }, { token })

export const getCityProvinceById = (id: string, token: string) =>
  request({ url: `city-province/${id}`, params: { isCms: true } }, { token })

export const updateCityProvince = (
  id: string,
  data: CityProvinceData,
  token: string,
) => request({ url: `city-province/${id}`, method: 'PUT', data }, { token })

export const delCityProvinceById = (id: string, token: string) =>
  request({ url: `city-province/${id}`, method: 'DELETE' }, { token })
