import { cn, en, vi } from '@src/assets/images'
import { getStoredLang, setLocalStored } from '@src/libs/localStorage'
import { LangEnum } from '@src/models/basic'
import { Image, Select, Space } from 'antd'
import React from 'react'
import { useQueryClient } from 'react-query'

const { Option } = Select

const SelectLang: React.FC = () => {
  const queryClient = useQueryClient()
  const handleChange = (value: string) => {
    setLocalStored('lang', value)
    queryClient.refetchQueries()
    window.location.reload()
  }

  return (
    <Select
      defaultValue={LangEnum.VI}
      value={getStoredLang()}
      onChange={handleChange}
    >
      <Option value={LangEnum.VI}>
        <Space align="center">
          <Image src={vi} width={20} preview={false} />
          {LangEnum.VI.toUpperCase()}
        </Space>
      </Option>
      <Option value={LangEnum.EN}>
        <Space align="center">
          <Image src={en} width={20} preview={false} />
          {LangEnum.EN.toUpperCase()}
        </Space>
      </Option>
      <Option value={LangEnum.CN}>
        <Space align="center">
          <Image src={cn} width={20} preview={false} />
          {LangEnum.CN.toUpperCase()}
        </Space>
      </Option>
    </Select>
  )
}

export default SelectLang
