import { memo } from 'react'
import { Spin } from 'antd'
import { useQueryCurrentRole, useQueryProfile } from '@src/queries/hooks'

function LoadingApp({
  loaded,
  children,
}: {
  loaded: boolean
  children: JSX.Element
}) {
  const { isLoading: qrProfileLoading, isFetching: isFetchingProfile } =
    useQueryProfile()
  const { isLoading: qrRoleLoading, isFetching: isFetchingRole } =
    useQueryCurrentRole()

  if (
    qrProfileLoading &&
    qrRoleLoading &&
    !!loaded &&
    !isFetchingRole &&
    !isFetchingProfile
  )
    return (
      <div className="loading-data">
        <Spin tip="Loading data" />
      </div>
    )
  return children
}

export default memo(LoadingApp)
