import { LangEnum, OrderByEnum, OrderEnum } from '@src/configs/common'
import { PRODUCT_ORDER_URL } from '@src/configs/urlRouter'
import { checkAuth } from '@src/libs/localStorage'
import { OrderData, OrderQueryParams } from '@src/models'

import { request } from './config'

class OrderApi {
  url: { LIST: string; CREATE: string; DETAIL: string }

  constructor() {
    this.url = PRODUCT_ORDER_URL
  }

  getSearch(s?: string) {
    const accessToken = checkAuth()
    const params = {
      page: 1,
      limit: 10,
      order: OrderEnum.DESC,
      orderBy: OrderByEnum.NAME,
      s: s || '',
    }
    return request(
      { url: PRODUCT_ORDER_URL.LIST, method: 'GET', params },
      { token: accessToken, lang: LangEnum.VI },
    )
  }
}

export const orderApi = new OrderApi()
// --------FN----------
export const getListOrder = (params: OrderQueryParams, token: string) =>
  request({ url: 'product/order/list', method: 'GET', params }, { token })

export const createOrder = (data: OrderData, token: string) =>
  request({ url: 'product/order', method: 'POST', data }, { token })

export const getOrderById = (id: string, token: string) =>
  request({ url: `product/order/${id}` }, { token })

export const updateOrder = (id: string, data: OrderData, token: string) =>
  request({ url: `product/order/${id}`, method: 'PUT', data }, { token })

export const delOrderById = (id: string, token: string) =>
  request({ url: `product/order/${id}`, method: 'DELETE' }, { token })
