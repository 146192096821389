import { useMutation, useQuery, useQueryClient } from 'react-query'
import { OrderData, OrderQueryParams } from '@src/models'
import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { notification } from 'antd'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'

import {
  createOrder,
  delOrderById,
  getOrderById,
  getListOrder,
  updateOrder,
} from '../api'
import { ORDER_KEY } from '../keys'

export const useQueryOrder = (params: OrderQueryParams) => {
  const token = checkAuth()
  return useQuery(
    [ORDER_KEY.LIST, params, getStoredLang()],
    () => getListOrder(params, token),
    {
      keepPreviousData: true,
      retry: 2,
    },
  )
}
export const useCreateOrder = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((body: OrderData) => createOrder(body, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Create Success!' })
        queryClient.refetchQueries([ORDER_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Create failure!' })
    },
  })
}

export const useOrderById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [ORDER_KEY.DETAIL, id, getStoredLang()],
    () => getOrderById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useUpdateOrder = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((body: OrderData) => updateOrder(id, body, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Update Success!' })
        queryClient.refetchQueries([ORDER_KEY.LIST])
        queryClient.refetchQueries([ORDER_KEY.DETAIL, id, getStoredLang()])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Update failure!' })
    },
  })
}

export const useDeleteOrder = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delOrderById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        queryClient.refetchQueries([ORDER_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}
