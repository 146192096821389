import { UserQueryParams } from '@src/models/user'

import { TCreateUser } from '../../models/user'

import { request } from './config'

export const uploadMedia = (token: string, file: any, lang: string) =>
  request(
    {
      url: '/upload/media',
      data: file,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    },
    { token, lang },
  )

export const getListUser = (token: string, params: UserQueryParams) =>
  request({ url: '/user', method: 'GET', params }, { token })

export const getListDeletedUser = (token: string, params: UserQueryParams) =>
  request(
    { url: '/user/deleted-user/findAll', method: 'GET', params },
    { token },
  )

export const getDetailDeletedUserById = (token: string, id: string) =>
  request({ url: `/user/deleted-user/${id}`, method: 'GET' }, { token })

export const restoreDeletedUserById = (token: string, id: string) =>
  request({ url: `/user/deleted-user/${id}`, method: 'PUT' }, { token })

export const getDetailUserById = (token: string, id: string) =>
  request({ url: `/user/${id}`, method: 'GET' }, { token })

export const createUser = (token: string, body: TCreateUser) =>
  request({ url: `/user`, method: 'POST', data: body }, { token })

export const updateUserById = (
  token: string,
  id: string,
  body: Partial<TCreateUser>,
) => request({ url: `/user/${id}`, method: 'PUT', data: body }, { token })

export const deleteUserById = (token: string, id: string) =>
  request({ url: `/user/${id}`, method: 'DELETE' }, { token })
