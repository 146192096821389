import { EconomicZone, EconomicZoneQueryParams } from '@src/models'

import { request } from './config'

// ---- Fn
export const getEconomicZone = (params: EconomicZoneQueryParams) =>
  request({ url: 'economic-zone', method: 'GET', params })

export const createEconomicZone = (data: EconomicZone, token: string) =>
  request({ url: 'economic-zone', method: 'POST', data }, { token })

export const getEconomicZoneById = (id: string, token: string) =>
  request({ url: `economic-zone/${id}`, params: { isCms: true } }, { token })

export const updateEconomicZone = (
  id: string,
  data: EconomicZone,
  token: string,
) => request({ url: `economic-zone/${id}`, method: 'PUT', data }, { token })

export const delEconomicZoneById = (id: string, token: string) =>
  request({ url: `economic-zone/${id}`, method: 'DELETE' }, { token })
