import NotFound from '@components/widgets/404'
import EditSlug from '@src/components/elements/EditSlug'
import LoadingIndicator from '@src/components/elements/Loading'
import PagesHeaders from '@src/components/elements/PagesHeaders'
import HeadHtml from '@src/components/layout/HeadHtml'
import ActionPublish from '@src/components/widgets/ActionPublish'
import {
  useDeleteProduct,
  useProductById,
  useUpdateProduct,
} from '@src/queries/hooks'
import { Card, Col, Collapse, Form, Row, Space } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import SelectMultipleFileFormItem from '@src/components/widgets/SelectMultipleFileFormItem'
import SelectSingleFileFormItem from '@src/components/widgets/SelectSingleFileFormItem'

import FormInput from './components/FormInput'

const { Panel } = Collapse

function DetailProduct() {
  const { id } = useParams()
  const [form] = Form.useForm()

  const navigate = useNavigate()

  const { mutate, isLoading: loadingUpdate } = useUpdateProduct(id || '')
  const { data: detail, isLoading, isFetching } = useProductById(id ?? '')

  const { mutate: del, isLoading: loadingDel } = useDeleteProduct()

  if (isLoading || isFetching) return <LoadingIndicator />

  if (!detail) return <NotFound />

  const onFinish = (values: any) => {
    const body = {
      ...values,
      area: {
        value: values.areaValue,
        unit: values.areaUnit,
      },
      slug: detail.data.slug,
      meta: {
        metaKeyword: values?.metaKeyword,
        metaDescription: values?.metaDescription,
      },
    }

    delete body?.areaValue
    delete body?.areaUnit
    mutate(body)
  }
  const handleSubmit = () => {
    form.submit()
  }

  const handleDel = () => {
    if (id)
      del(id, {
        onSuccess: () => {
          navigate('/product')
        },
      })
  }

  return (
    <>
      <HeadHtml title={detail.data.name || 'Chi tiết sản phẩm'} />
      <Form form={form} onFinish={onFinish} scrollToFirstError>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={17}>
            <Card hoverable title={<PagesHeaders title="Cập nhật sản phẩm" />}>
              <FormInput data={detail.data} form={form} />
            </Card>
          </Col>
          <Col xs={24} xl={7}>
            <Space size={16} direction="vertical" className="space-event">
              <Collapse
                defaultActiveKey={['1']}
                className="action"
                expandIconPosition="end"
              >
                <Panel header="Hành động" key="1">
                  <ActionPublish
                    handleUpdate={handleSubmit}
                    data={{
                      status: detail?.data?.status,
                      createdAt: detail?.data?.createdAt,
                      author: detail?.data?.author,
                      publishedLanguage: detail?.data?.publishedLanguage,
                    }}
                    loadingUpdate={loadingUpdate}
                    loadingDelete={loadingDel}
                    handleDelete={handleDel}
                    isPublishedLanguage
                  />
                </Panel>
              </Collapse>
              <Collapse
                defaultActiveKey={['1']}
                className="action"
                expandIconPosition="end"
              >
                {/* <Panel header="Status" key="1">
                  <FormStatus data={detail?.data} />
                </Panel> */}
              </Collapse>
              <Collapse
                defaultActiveKey={['1']}
                className="action"
                expandIconPosition="end"
              >
                <Panel header="Ảnh nhỏ" key="1">
                  {/* {/* <Row justify="center">
                    <FormInputUploadImage
                      form={form}
                      name="thumbnail"
                      url={detail?.data?.thumbnail}
                    />
                  </Row> */}

                  <SelectSingleFileFormItem
                    form={form}
                    name="thumbnail"
                    initialValue={detail?.data?.thumbnail}
                  />
                </Panel>
              </Collapse>
              <Collapse
                defaultActiveKey={['1']}
                className="action"
                expandIconPosition="end"
              >
                <Panel header="Thư viện" key="1">
                  {/* <Row justify="center">
                    <FormInputUploadListImage
                      form={form}
                      name="gallery"
                      files={detail?.data?.gallery || []}
                    />
                  </Row> */}

                  <SelectMultipleFileFormItem
                    form={form}
                    name="gallery"
                    initialValue={detail?.data?.gallery || []}
                  />
                </Panel>
              </Collapse>
              <EditSlug data={detail?.data?.slug} name="product" />
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default DetailProduct
