import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { checkAuth } from '@src/libs/localStorage'
import {
  actionUpload,
  beforeUploadImage,
  getBase64,
} from '@src/utils/uploadFile'
import { Form, Upload } from 'antd'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { FormInstance } from 'rc-field-form'
import { useEffect, useState } from 'react'

interface InputUploadImage {
  form: FormInstance<any>
  name?: string
  url?: string
}

function InputUploadImage({ form, name = 'thumbnail', url }: InputUploadImage) {
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()
  const handleChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>,
  ) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return false
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false)
        setImageUrl(url)
        form.setFieldsValue({
          [name]: info.file.response.data,
        })
      })
    }
    setLoading(false)
    return true
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  useEffect(() => {
    if (url) {
      setImageUrl(url)
    }
  }, [url])

  return (
    <Form.Item initialValue={url} name={name}>
      <Upload
        name="file"
        listType="picture-card"
        showUploadList={false}
        action={actionUpload}
        beforeUpload={beforeUploadImage}
        onChange={handleChange}
        headers={{ Authorization: `Bearer ${checkAuth()}` }}
        className="upload-file"
        disabled
        data={{ systemType: 'NIENGIAM' }}
      >
        {imageUrl ? (
          <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </Form.Item>
  )
}

export default InputUploadImage
