import { LockOutlined, MailOutlined } from '@ant-design/icons'
import logoApp from '@assets/images/logo.png'
import HeadHtml from '@src/components/layout/HeadHtml'
import { checkAuth } from '@src/libs/localStorage'
import { SignInPropType } from '@models/auth'
import { useQueryLogin } from '@src/queries/hooks'
import { regexEmail } from '@src/utils'
import { Button, Checkbox, Col, Form, Input, Row } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { queryClient } from '@src/queries'

function Login() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const token: string = checkAuth()
  const { mutate, isLoading } = useQueryLogin()
  const handleLogin = (values: SignInPropType) => {
    mutate(values, {
      onSuccess: () => {
        queryClient.invalidateQueries()
        navigate('/')
      },
    })
  }

  useEffect(() => {
    if (token) navigate('')
  }, [navigate, token])

  return (
    <div className="login-container">
      <HeadHtml title="Login" />
      <Row
        align="middle"
        justify="center"
        className="login-boxer"
        gutter={[24, 60]}
      >
        <Col span={24}>
          <Row justify="center">
            <img src={logoApp} className="logo" alt="logo" />
          </Row>
        </Col>
        <Col span={24}>
          <Form
            name="login"
            initialValues={{ remember: true }}
            onFinish={handleLogin}
            form={form}
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Please input your Email!' },
                { pattern: regexEmail, message: 'Email invalid' },
              ]}
            >
              <Input
                prefix={<MailOutlined />}
                placeholder="Email"
                size="large"
                autoFocus
                onPressEnter={() => form.submit()}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your Password!' },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
                size="large"
                onPressEnter={() => form.submit()}
              />
            </Form.Item>

            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox style={{ color: 'white' }}>Remember me</Checkbox>
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit-login"
                size="large"
                loading={isLoading}
                disabled={isLoading}
              >
                LOGIN
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default Login
