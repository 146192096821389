import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  BASIC_COLLAPSE_HEADER,
  BASIC_CONTENT,
  BASIC_LABEL,
} from '@src/assets/contants'
import HashtagSelect from '@src/components/elements/HashtagSelect'
import LoadingIndicator from '@src/components/elements/Loading'
import RichText from '@src/components/elements/RichText'
import {
  BaseData,
  labelStyle,
  LANGUAGE_DEFAULT,
  OrderByEnum,
  OrderEnum,
  StatusDocumentEnum,
} from '@src/configs/common'
import { getStoredLang } from '@src/libs/localStorage'
import { EnterpriseData } from '@src/models'
import {
  useQueryCityProvince,
  useQueryEconomicSector,
  useQueryIndustrialArea,
} from '@src/queries/hooks'
import { removeAccents } from '@src/utils'
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd'
import moment from 'moment'

import PriceInput from './PriceInput'

const { Panel } = Collapse
const { Option } = Select
const { TextArea } = Input
interface FormInputEnterprise {
  data?: EnterpriseData
  form: any
}
function FormInput({ data, form }: FormInputEnterprise) {
  const params = {
    page: 1,
    limit: 10,
    order: OrderEnum.ASC,
    orderBy: OrderByEnum.CREATE_AT,
    s: '',
    isCms: true,
  }

  const lang = getStoredLang() || LANGUAGE_DEFAULT
  const renderOptionBaseData = (data: BaseData[], showId?: boolean) =>
    data?.length &&
    data?.map((item: BaseData) => (
      <Option value={item.id} key={item.id}>
        {showId ? `${item.id} - ` : ''}
        {item.translations[lang].label}
      </Option>
    ))

  const { data: economicSector, isLoading: isLoadingEconomicZoneID } =
    useQueryEconomicSector()

  const { data: activeIndustrial, isLoading: LoadingActiveIndustrial } =
    useQueryIndustrialArea({
      ...params,
      limit: 0,
      status: StatusDocumentEnum.ACTIVE,
    })

  const {
    data: initIndustrialArea,
    isLoading,
    isFetching,
  } = useQueryIndustrialArea({
    ...params,
    enterprise: data?._id,
  })
  const { data: ListCity, isLoading: isLoadingListCity } = useQueryCityProvince(
    {
      page: 1,
      limit: 0,
      order: OrderEnum.DESC,
      orderBy: OrderByEnum.CREATE_AT,
      s: '',
      isCms: true,
    },
  )

  if (isFetching || isLoading || LoadingActiveIndustrial || isLoadingListCity)
    return <LoadingIndicator />
  const initialIndustrialAreaValue = data
    ? initIndustrialArea?.data?.data?.map((value: any) => value._id)
    : []

  if (isLoadingEconomicZoneID) return <LoadingIndicator />

  return (
    <Space direction="vertical" className="space-event" size={10}>
      <Collapse
        defaultActiveKey={['1']}
        className="action"
        expandIconPosition="end"
      >
        <Panel header={BASIC_COLLAPSE_HEADER.OVERVIEW} key="1">
          <Form.Item
            name="name"
            label={`${BASIC_CONTENT.NAME}:`}
            rules={[
              {
                required: true,
                message: `${BASIC_CONTENT.NAME} không được để trống`,
              },
            ]}
            {...labelStyle}
            initialValue={data?.name}
          >
            <Input
              placeholder={`Nhập ${BASIC_CONTENT.NAME.toLowerCase()} ...`}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            label={BASIC_CONTENT.PHONE}
            rules={[
              {
                required: true,
                message: `${BASIC_CONTENT.PHONE} không được để trống`,
              },
              {
                max: 20,
                message: `${BASIC_CONTENT.PHONE} không được vượt quá 20 ký tự`,
              },
              {
                min: 8,
                message: `${BASIC_CONTENT.PHONE} phải có ít nhất 8 ký tự`,
              },
            ]}
            {...labelStyle}
            initialValue={data?.phone}
          >
            <Input
              placeholder={`Nhập ${BASIC_CONTENT.PHONE.toLowerCase()} ...`}
            />
          </Form.Item>

          <Form.Item
            name="address"
            label={BASIC_CONTENT.ADDRESS}
            {...labelStyle}
            rules={[
              {
                required: true,
                message: `${BASIC_CONTENT.ADDRESS} không được để trống`,
              },
            ]}
            initialValue={data?.address}
          >
            <Input
              placeholder={`Nhập ${BASIC_CONTENT.ADDRESS.toLowerCase()} ...`}
            />
          </Form.Item>
        </Panel>
      </Collapse>

      <Collapse defaultActiveKey={['3']} expandIconPosition="end">
        <Panel header="Thẻ Meta" key="3">
          <Form.Item
            name="metaKeyword"
            label="Meta Keyword"
            required
            initialValue={data?.meta?.metaKeyword}
            {...labelStyle}
          >
            <TextArea placeholder="Nhập thẻ meta ..." />
          </Form.Item>
          <Form.Item
            required
            name="metaDescription"
            label="Meta Description"
            initialValue={data?.meta?.metaDescription}
            {...labelStyle}
          >
            <TextArea rows={6} placeholder="Nhập thẻ meta description ..." />
          </Form.Item>
        </Panel>
      </Collapse>
      <Collapse
        defaultActiveKey={['1']}
        className="action"
        expandIconPosition="end"
      >
        <Panel header={BASIC_COLLAPSE_HEADER.DETAIL} key="1">
          <Form.Item
            name="internationalName"
            label={BASIC_CONTENT.INTERNATIONAL_NAME}
            rules={[
              {
                required: true,
                message: `${BASIC_CONTENT.INTERNATIONAL_NAME} không được để trống`,
              },
            ]}
            {...labelStyle}
            initialValue={data?.internationalName}
          >
            <Input
              placeholder={`Nhập ${BASIC_CONTENT.INTERNATIONAL_NAME.toLowerCase()} ...`}
            />
          </Form.Item>

          <Form.Item
            name="abbreviationName"
            label={BASIC_CONTENT.ABBREVIATION_NAME}
            rules={[
              {
                required: true,
                message: `${BASIC_CONTENT.ABBREVIATION_NAME} không được để trống`,
              },
            ]}
            {...labelStyle}
            initialValue={data?.abbreviationName}
          >
            <Input
              placeholder={`Nhập ${BASIC_CONTENT.ABBREVIATION_NAME.toLowerCase()} ...`}
            />
          </Form.Item>
          <Form.Item
            name="taxCode"
            label={BASIC_CONTENT.TAX_CODE}
            rules={[
              {
                required: true,
                message: `${BASIC_CONTENT.TAX_CODE} không được để trống`,
              },
            ]}
            {...labelStyle}
            initialValue={data?.taxCode}
          >
            <Input
              placeholder={`Nhập ${BASIC_CONTENT.TAX_CODE.toLowerCase()} ...`}
            />
          </Form.Item>
          <Form.Item
            label={BASIC_CONTENT.COMPANY_ESTABLISHMENT_DATE}
            name="establishmentDate"
            {...labelStyle}
            initialValue={
              data?.establishmentDate
                ? moment(
                    new Date(data?.establishmentDate as Date),
                    'YYYY/MM/DD',
                  )
                : moment(new Date(), 'YYYY/MM/DD')
            }
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="legalRepresentative"
            label={BASIC_CONTENT.LEGAL_REPRESENTATIVE}
            rules={[
              {
                required: true,
                message: `${BASIC_CONTENT.LEGAL_REPRESENTATIVE} không được để trống`,
              },
            ]}
            {...labelStyle}
            initialValue={data?.legalRepresentative}
          >
            <Input
              placeholder={`Nhập ${BASIC_CONTENT.LEGAL_REPRESENTATIVE.toLowerCase()} ...`}
            />
          </Form.Item>
          <Form.Item
            name="taxAddress"
            label={BASIC_CONTENT.TAX_ADDRESS}
            rules={[
              {
                required: true,
                message: `${BASIC_CONTENT.TAX_ADDRESS} không được để trống`,
              },
            ]}
            {...labelStyle}
            initialValue={data?.taxAddress}
          >
            <Input
              placeholder={`Nhập ${BASIC_CONTENT.TAX_ADDRESS.toLowerCase()} ...`}
            />
          </Form.Item>
          <Form.Item
            name="nationality"
            label={BASIC_CONTENT.NATIONALITY}
            rules={[
              {
                required: true,
                message: `${BASIC_CONTENT.NATIONALITY} không được để trống`,
              },
            ]}
            {...labelStyle}
            initialValue={data?.internationalName}
          >
            <Input
              placeholder={`Nhập ${BASIC_CONTENT.NATIONALITY.toLowerCase()} ...`}
            />
          </Form.Item>
          <Form.Item
            name="mainMarketing"
            label={BASIC_CONTENT.MAIN_MARKETING}
            rules={[
              {
                required: true,
                message: `${BASIC_CONTENT.MAIN_MARKETING} không được để trống`,
              },
            ]}
            {...labelStyle}
            initialValue={data?.mainMarketing}
          >
            <Input
              placeholder={`Nhập ${BASIC_CONTENT.MAIN_MARKETING.toLowerCase()} ...`}
            />
          </Form.Item>
          <Form.Item
            name="mainJob"
            label={BASIC_CONTENT.MAIN_JOB}
            rules={[
              {
                required: true,
                message: `${BASIC_CONTENT.MAIN_JOB} không được để trống`,
              },
            ]}
            {...labelStyle}
            initialValue={data?.mainJob}
          >
            <Select
              showSearch
              placeholder={`Nhập ${BASIC_CONTENT.MAIN_JOB.toLowerCase()} ...`}
              optionFilterProp="children"
            >
              {renderOptionBaseData(economicSector?.data || [], true)}
            </Select>
          </Form.Item>

          <Form.Item
            name="industrialArea"
            label="Khu công nghiệp"
            initialValue={initialIndustrialAreaValue}
            rules={[
              {
                required: true,
                message: 'Please select industrial zone type',
              },
            ]}
            {...labelStyle}
          >
            <Select
              placeholder="Chọn Khu công nghiệp ở đây"
              showSearch
              mode="multiple"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {activeIndustrial?.data?.data?.map((value: any) => (
                <Option value={value._id} key={value._id}>
                  {value.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* //lĩnh vực kinh doanh */}
          <Form.Item
            name="economicSector"
            label={BASIC_CONTENT.ECONOMIC_SECTORS}
            rules={[
              {
                required: true,
                message: `${BASIC_CONTENT.ECONOMIC_SECTORS} không được để trống`,
              },
            ]}
            {...labelStyle}
            initialValue={data?.economicSector}
          >
            <Select
              showSearch
              placeholder={`Nhập ${BASIC_CONTENT.ECONOMIC_SECTORS.toLowerCase()} ...`}
              optionFilterProp="children"
              mode="multiple"
            >
              {renderOptionBaseData(economicSector?.data || [], true)}
            </Select>
          </Form.Item>

          {/* CITY PROVINCE */}
          <Form.Item
            name="cityProvince"
            label="Tỉnh / Thành Phố"
            {...labelStyle}
            initialValue={data?.cityProvince}
            rules={[
              {
                required: true,
                message: 'Hãy nhập Tỉnh / Thành Phố',
              },
            ]}
          >
            {/* <FormSelectSearch
              fetchOptions={(p) => getListCityProvince(p)}
              placeholder="Nhập tỉnh thành phố"
            /> */}
            <Select
              placeholder="Nhập tỉnh thành phố"
              showSearch
              filterOption={(input, option) =>
                removeAccents(option!.children as unknown as string)
                  .toLowerCase()
                  .includes(removeAccents(input.toLowerCase()))
              }
            >
              {ListCity?.data?.data.map((value: any, index: number) => (
                <Select.Option key={index} value={value._id}>
                  {value.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="shortBio"
            label={BASIC_CONTENT.SHORT_BIO}
            {...labelStyle}
            initialValue={data?.shortBio}
          >
            <RichText
              h={300}
              initialValue={data?.shortBio}
              onEditorChange={(text: any) => {
                form.setFieldsValue({
                  shortBio: text,
                })
              }}
            />
          </Form.Item>
          <Form.Item
            name="factoryAddress"
            label={BASIC_CONTENT.FACTORY_ADDRESS}
            rules={[
              {
                required: true,
                message: `${BASIC_CONTENT.FACTORY_ADDRESS} không được để trống`,
              },
            ]}
            {...labelStyle}
            initialValue={data?.factoryAddress}
          >
            <Input
              placeholder={`Nhập ${BASIC_CONTENT.FACTORY_ADDRESS.toLowerCase()} ...`}
            />
          </Form.Item>
          <Form.Item
            name="stockCode"
            label={BASIC_CONTENT.STOCK_CODE}
            {...labelStyle}
            initialValue={data?.stockCode}
          >
            <Input
              placeholder={`Nhập ${BASIC_CONTENT.STOCK_CODE.toLowerCase()} ...`}
            />
          </Form.Item>
          <Form.Item
            name="authorizedCapital"
            label={BASIC_CONTENT.AUTHORIZED_CAPITAL}
            {...labelStyle}
          >
            <PriceInput
              detailData={{
                value: data?.authorizedCapital?.value,
                unit: data?.authorizedCapital?.unit,
              }}
            />
          </Form.Item>
          <Form.Item
            name="totalInvestment"
            label={BASIC_CONTENT.TOTAL_INVESTMENT}
            {...labelStyle}
          >
            <PriceInput
              detailData={{
                value: data?.totalInvestment?.value,
                unit: data?.totalInvestment?.unit,
              }}
            />
          </Form.Item>
          <HashtagSelect hashtagId={data?.hashtag} />
          {/* <PublishedLanguage data={data} /> */}
        </Panel>
      </Collapse>

      <Collapse
        defaultActiveKey={['1']}
        className="action"
        expandIconPosition="end"
      >
        <Panel header={BASIC_COLLAPSE_HEADER.CONTACT} key="1">
          <Form.List name="contacts" initialValue={data?.contacts || []}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, idx) => (
                  <div key={field.key}>
                    <Divider> Liên hệ {idx + 1}</Divider>
                    <Row gutter={10}>
                      <Col flex="1">
                        <Form.Item
                          {...field}
                          label={BASIC_LABEL.LABEL}
                          name={[field.name, 'label']}
                          fieldKey={['label']}
                          {...labelStyle}
                        >
                          <Input
                            placeholder={`Nhập ${BASIC_LABEL.LABEL.toLowerCase()} ...`}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label={BASIC_LABEL.PEOPLE}
                          name={[field.name, 'people']}
                          fieldKey={['people']}
                          {...labelStyle}
                        >
                          <Input
                            placeholder={`Nhập ${BASIC_LABEL.PEOPLE.toLowerCase()} ...`}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label={BASIC_LABEL.PHONE}
                          name={[field.name, 'phone']}
                          fieldKey={['phone']}
                          {...labelStyle}
                        >
                          <Input
                            placeholder={`Nhập ${BASIC_LABEL.PHONE.toLowerCase()} ...`}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label={BASIC_LABEL.EMAIL}
                          name={[field.name, 'email']}
                          fieldKey={['email']}
                          {...labelStyle}
                        >
                          <Input
                            placeholder={`Nhập ${BASIC_LABEL.EMAIL.toLowerCase()} ...`}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label={BASIC_LABEL.ADDRESS}
                          name={[field.name, 'address']}
                          fieldKey={['address']}
                          {...labelStyle}
                        >
                          <Input
                            placeholder={`Nhập ${BASIC_LABEL.ADDRESS.toLowerCase()} ...`}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex="30px">
                        <Button
                          onClick={() => {
                            remove(field.name)
                          }}
                          block
                        >
                          <MinusCircleOutlined />
                        </Button>
                      </Col>
                      <br />
                    </Row>
                  </div>
                ))}

                <Form.Item>
                  <Button
                    onClick={() => {
                      add()
                    }}
                    block
                  >
                    <PlusOutlined /> Thêm liên hệ
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Panel>
      </Collapse>
    </Space>
  )
}

export default FormInput
