import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  GlobalOutlined,
  MailOutlined,
  PlusOutlined,
  PushpinOutlined,
  SketchOutlined,
} from '@ant-design/icons'
import { cn, en, vi } from '@src/assets/images'
import {
  emailVerify,
  statusDocument,
  statusDocumentBoolean,
  StatusDocumentEnum,
  TYPICAL,
} from '@src/configs/common'
import { ActionPublishData } from '@src/configs/interface.config'
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Popconfirm,
  Row,
  Select,
  Space,
} from 'antd'
import moment from 'moment'

const { Option } = Select
const lans = [
  { value: 'vi', flag: vi },
  { value: 'en', flag: en },
  { value: 'cn', flag: cn },
]

interface ActionPublish {
  data?: ActionPublishData
  handlePublish?: () => void
  handleDelete?: () => void
  handleUpdate?: () => void
  loadingCreate?: boolean
  loadingUpdate?: boolean
  loadingDelete?: boolean
  isStatusBoolean?: boolean
  isPublishedLanguage?: boolean
  isShowSelectStatus?: boolean
}

function ActionPublish({
  data,
  handleDelete,
  handlePublish,
  handleUpdate,
  loadingCreate,
  loadingDelete,
  loadingUpdate,
  isStatusBoolean = false,
  isPublishedLanguage,
  isShowSelectStatus = true,
}: ActionPublish) {
  return (
    <Row>
      {/* <Col span={24}>
        <Row>
          <Col flex="23px">
            <UserOutlined style={{ marginTop: 9 }} />
          </Col>
          <Col flex="1">
            <Form.Item name="author" initialValue="Nguyen Van Ba">
              <Select placeholder="Select Author here" disabled />
            </Form.Item>
          </Col>
        </Row>
      </Col> */}
      {data && data?.emailVerify !== undefined && (
        <Col span={24}>
          <Row>
            <Col flex="23px">
              <MailOutlined style={{ marginTop: 9 }} />
            </Col>
            <Col flex="1">
              <Form.Item
                name="emailVerify"
                initialValue={!!data?.emailVerify || false}
              >
                <Select placeholder="Select Status here">
                  {emailVerify.map((status) => (
                    <Option value={status.value} key={status.key}>
                      <Badge
                        status={status.status}
                        text={status.label.toUpperCase()}
                      />
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      )}
      {data && data?.representative !== undefined && (
        <Col span={24}>
          <Row>
            <Col flex="23px">
              <SketchOutlined style={{ marginTop: 9 }} />
            </Col>
            <Col flex="1">
              <Form.Item
                name="representative"
                initialValue={!!data?.representative || false}
              >
                <Select placeholder="Select Status here">
                  {TYPICAL.map((typical) => (
                    <Option value={typical.value} key={typical.key}>
                      <Badge
                        status={typical.status}
                        text={typical.label.toUpperCase()}
                      />
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      )}
      {isShowSelectStatus && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!isStatusBoolean ? (
            <Col span={24}>
              <Row>
                <Col flex="23px">
                  <PushpinOutlined style={{ marginTop: 9 }} />
                </Col>
                <Col flex="1">
                  <Form.Item
                    name="status"
                    initialValue={data?.status || StatusDocumentEnum.INACTIVE}
                  >
                    <Select placeholder="Select Status here">
                      {statusDocument.map((status) => (
                        <Option value={status.value} key={status.key}>
                          <Badge
                            status={status.status}
                            text={status.value.toUpperCase()}
                          />
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col span={24}>
              <Row>
                <Col flex="23px">
                  <PushpinOutlined style={{ marginTop: 9 }} />
                </Col>
                <Col flex="1">
                  <Form.Item
                    name="status"
                    initialValue={!!data?.status || false}
                  >
                    <Select placeholder="Select Status here">
                      {statusDocumentBoolean.map((status) => (
                        <Option value={status.value} key={status.key}>
                          <Badge
                            status={status.status}
                            text={status.label.toUpperCase()}
                          />
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          )}
        </>
      )}

      {isPublishedLanguage && (
        <Col span={24}>
          <Row>
            <Col flex="23px">
              <GlobalOutlined style={{ marginTop: 9 }} />
            </Col>
            <Col flex="1">
              <Form.Item
                name="publishedLanguage"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Chọn ngôn ngữ',
                //   },
                // ]}
                // {...labelStyle}
                initialValue={data ? data?.publishedLanguage : []}
              >
                <Select
                  style={{ width: '100%' }}
                  // mode={mode || undefined}
                  placeholder="Chọn ngôn ngữ"
                  mode="multiple"
                >
                  {lans.map((v: any) => (
                    <Select.Option key={v.value} value={v.value}>
                      <Space align="center">
                        <Image src={v.flag} width={20} preview={false} />
                        {v.value}
                      </Space>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      )}

      <Col span={24}>
        <Row>
          <Col flex="23px">
            <CalendarOutlined style={{ marginTop: 9 }} />
          </Col>
          <Col flex="1">
            <DatePicker
              showTime
              allowClear={false}
              className="date-picker-input"
              format="DD-MM-YYYY HH:mm:ss"
              defaultValue={moment(data?.createdAt || new Date())}
            />
          </Col>
        </Row>
      </Col>
      <Divider style={{ margin: 0, marginBottom: 18, marginTop: 22 }} />
      {!data && (
        <Col span={24}>
          <Row justify="end">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handlePublish}
              loading={loadingCreate}
            >
              Publish
            </Button>
          </Row>
        </Col>
      )}
      {data && (
        <Col span={24}>
          <Row justify="space-between">
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={handleDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined />} loading={loadingDelete}>
                Delete
              </Button>
            </Popconfirm>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={handleUpdate}
              loading={loadingUpdate}
            >
              Update
            </Button>
          </Row>
        </Col>
      )}
    </Row>
  )
}

export default ActionPublish
