import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import { PRODUCT_ORDER_URL } from '@src/configs/urlRouter'

import { RouteConfigItemType } from './interface'

const List = lazyLoading(() => import('@src/pages/order/ListOrder'))
const Create = lazyLoading(() => import('@pages/order/CreateOrder'))

const Detail = lazyLoading(() => import('@pages/order/DetailOrder'))

const ProductOrderRouter: RouteConfigItemType[] = [
  {
    path: PRODUCT_ORDER_URL.LIST,
    Element: List,
    key: PERMISSIONS.LIST_PRODUCT_ORDER,
  },
  {
    path: PRODUCT_ORDER_URL.CREATE,
    Element: Create,
    key: PERMISSIONS.CREATE_PRODUCT_ORDER,
  },
  {
    path: PRODUCT_ORDER_URL.DETAIL,
    Element: Detail,
    key: PERMISSIONS.VIEW_PRODUCT_ORDER,
  },
]

export { ProductOrderRouter }
