import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import { NEWS_URL } from '@src/configs/urlRouter'

import { RouteConfigItemType } from './interface'

const List = lazyLoading(() => import('@pages/news/ListNews'))
const Create = lazyLoading(() => import('@pages/news/CreateNews'))
const Detail = lazyLoading(() => import('@pages/news/DetailNews'))

const newsRouter: RouteConfigItemType[] = [
  {
    path: NEWS_URL.LIST,
    Element: List,
    key: PERMISSIONS.LIST_NEWS,
  },
  {
    path: NEWS_URL.CREATE,
    Element: Create,
    key: PERMISSIONS.CREATE_NEWS,
  },
  {
    path: NEWS_URL.DETAIL,
    Element: Detail,
    key: PERMISSIONS.VIEW_NEWS,
  },
]

export { newsRouter }
