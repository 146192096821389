import { LangEnum, OrderByEnum, OrderEnum } from '@src/configs/common'
import { TAXONOMY_URL } from '@src/configs/urlRouter'
import { checkAuth } from '@src/libs/localStorage'
import { TaxonomyData, TaxonomyQueryParams } from '@src/models'

import { request } from './config'

class TaxonomyApi {
  url: { LIST: string; CREATE: string; DETAIL: string }

  constructor() {
    this.url = TAXONOMY_URL
  }

  getSearch(s?: string) {
    const accessToken = checkAuth()
    const params = {
      page: 1,
      limit: 10,
      order: OrderEnum.DESC,
      orderBy: OrderByEnum.NAME,
      s: s || '',
    }
    return request(
      { url: TAXONOMY_URL.LIST, method: 'GET', params },
      { token: accessToken, lang: LangEnum.VI },
    )
  }
}

export const taxonomyApi = new TaxonomyApi()
// --------FN----------
export const getListTaxonomy = (
  params: TaxonomyQueryParams,
  token: string,
  lang: LangEnum,
) =>
  request({ url: 'taxonomy/find-all', method: 'GET', params }, { token, lang })

export const createTaxonomy = (data: TaxonomyData, token: string) =>
  request({ url: 'taxonomy/create', method: 'POST', data }, { token })

export const getTaxonomyById = (id: string, token: string) =>
  request({ url: `taxonomy/${id}`, params: { isCms: true } }, { token })

export const updateTaxonomy = (id: string, data: TaxonomyData, token: string) =>
  request({ url: `taxonomy/${id}`, method: 'PUT', data }, { token })

export const delTaxonomyById = (id: string, token: string) =>
  request({ url: `taxonomy/${id}`, method: 'DELETE' }, { token })
