import { OrderByEnum, OrderEnum } from '@src/configs/common'
import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'
import {
  SystemTypeOptions,
  TaxonomyData,
  TaxonomyQueryParams,
} from '@src/models'
import { notification } from 'antd'
import React, { useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import {
  createTaxonomy,
  delTaxonomyById,
  getListTaxonomy,
  getTaxonomyById,
  updateTaxonomy,
} from '../api'
import { TAXONOMY_KEY } from '../keys'

export const useQueryTaxonomy = (initParams?: Partial<TaxonomyQueryParams>) => {
  const token = checkAuth() /// add token

  const [searchParams] = useSearchParams()
  const paramSearchUrl = useMemo(
    () => Object.fromEntries(Array.from(searchParams)),
    [searchParams],
  )
  // const baseParamsDefauls = {
  //   page: 1,
  //   limit: 10,
  //   order: OrderEnum.DESC,
  //   orderBy: OrderByEnum.CREATE_AT,
  //   s: '',
  //   isCms: true,
  // }

  const baseParams = React.useMemo(
    () => ({
      page: 1,
      limit: 10,
      order: OrderEnum.DESC,
      orderBy: OrderByEnum.CREATE_AT,
      s: '',
      isCms: true,
      systemType: SystemTypeOptions.NG,
      ...paramSearchUrl,
      ...initParams,
    }),
    [paramSearchUrl],
  )
  const [params, setParams] = useState<TaxonomyQueryParams>(baseParams)
  return {
    /// return mutil val
    queryTaxonomy: useQuery(
      [TAXONOMY_KEY.LIST, params, getStoredLang()],
      () => getListTaxonomy(params, token, getStoredLang()),
      {
        keepPreviousData: true,
        retry: 2,
      },
    ),
    params,
    setParams,
    paramSearchUrl,
  }
}
export const useQueryTaxonomyAll = (params: TaxonomyQueryParams) => {
  const token = checkAuth()
  return useQuery([TAXONOMY_KEY.LIST, params, getStoredLang()], () =>
    getListTaxonomy(params, token, getStoredLang()),
  )
}

export const useCreateTaxonomy = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: TaxonomyData) => createTaxonomy(body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Create Success!' })
          queryClient.refetchQueries([TAXONOMY_KEY.LIST])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Create failure!' })
      },
    },
  )
}

export const useTaxonomyById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [TAXONOMY_KEY.DETAIL, id, getStoredLang()],
    () => getTaxonomyById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useUpdateTaxonomy = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: TaxonomyData) => updateTaxonomy(id, body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Update Success!' })
          queryClient.refetchQueries([TAXONOMY_KEY.LIST])
          queryClient.refetchQueries([TAXONOMY_KEY.DETAIL, id, getStoredLang()])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Update failure!' })
      },
    },
  )
}

export const useDeleteTaxonomy = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delTaxonomyById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        queryClient.refetchQueries([TAXONOMY_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}
