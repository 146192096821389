import {
  DeleteOutlined,
  EditOutlined,
  FileExclamationOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  LinkOutlined,
  PlaySquareOutlined,
} from '@ant-design/icons'
import placeholderImage from '@assets/placeholder-image.jpg'
import { TFile } from '@src/models'
import {
  regexAudio,
  regexDocument,
  regexImage,
  regexVideo,
} from '@src/utils/regex'
import { Card, Dropdown, Menu, MenuProps, Row, Typography, message } from 'antd'
import { useMemo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const { Meta } = Card
const { Text } = Typography

type TFileItem = {
  file: TFile
  handleEdit?: (file: TFile | undefined) => void
  handleDelete?: (id: string) => void
  handleMove?: (file: TFile | undefined) => void
  handleClick?: (file: TFile | undefined) => void
}

function FileItem({
  file,
  handleDelete,
  handleEdit,
  handleMove,
  handleClick,
}: TFileItem) {
  const fileType: 'docx' | 'image' | 'audio' | 'unknown' = useMemo(() => {
    if (file?.location?.match(regexImage)) {
      return 'image'
    }
    if (file?.originalname?.match(regexDocument)) {
      return 'docx'
    }
    if (
      file?.originalname?.match(regexVideo) ||
      file?.originalname?.match(regexAudio)
    ) {
      return 'audio'
    }
    return 'unknown'
  }, [file])

  const itemsArray: MenuProps['items'] = useMemo(
    () => [
      {
        label: 'Sửa',
        key: '0',
        icon: <EditOutlined />,
        onClick: () => {
          if (handleEdit) handleEdit(file)
        },
      },
      {
        label: 'Xóa',
        key: '1',
        icon: <DeleteOutlined />,
        onClick: () => {
          if (handleDelete) handleDelete(file?._id)
        },
      },
      {
        label: 'Nhận Url',
        key: '3',
        icon: <LinkOutlined />,
        onClick: () => {
          navigator.clipboard.writeText(file.location)
          message.success('Copy')
        },
      },
      {
        label: 'Chuyển tới Folder',
        key: '4',
        icon: <FolderOpenOutlined />,
        onClick: () => {
          if (handleMove) handleMove(file)
        },
      },
    ],
    [file],
  )

  // const items = [
  //   { label: 'item 1', key: 'item-1' }, // remember to pass the key prop
  //   { label: 'item 2', key: 'item-2' },
  // ]

  // const menuProps = {
  //   items,
  //   onClick: handleMenuClick,
  // }

  const menu = (
    <Menu>
      {itemsArray.length > 0 &&
        itemsArray.map((val: any, key) => (
          <Menu.Item key={key} icon={val?.icon} onClickCapture={val.onClick}>
            {val.label}
          </Menu.Item>
        ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['contextMenu']}>
      <Card
        size="small"
        hoverable
        className="card-file-item"
        onClick={() => {
          if (handleClick) {
            handleClick(file)
          }
        }}
        cover={
          <>
            {fileType === 'image' && (
              <LazyLoadImage
                height={100}
                width="100%"
                src={file.location}
                className="card-cover"
                effect="blur"
                placeholderSrc={placeholderImage}
                loading="lazy"
                alt={file.name}
              />
            )}
            {fileType === 'docx' && (
              <Row align="middle" justify="center" className="card-cover-dox">
                <FileTextOutlined />
              </Row>
            )}
            {fileType === 'audio' && (
              <Row align="middle" justify="center" className="card-cover-dox">
                <PlaySquareOutlined />
              </Row>
            )}
            {fileType === 'unknown' && (
              <Row align="middle" justify="center" className="card-cover-dox">
                <FileExclamationOutlined />
              </Row>
            )}
          </>
        }
      >
        <Meta
          description={
            <Text ellipsis style={{ fontSize: 12 }}>
              {file.name}
            </Text>
          }
        />
      </Card>
    </Dropdown>
  )
}

export default FileItem
