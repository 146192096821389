import { ThemeCreateDto, ThemeQueryParams } from '@src/models/theme'

import { request } from './config'

// ---- Fn
export const getTheme = (params: ThemeQueryParams, token: string) =>
  request({ url: 'theme', method: 'GET', params }, { token })

export const createTheme = (data: ThemeCreateDto, token: string) =>
  request({ url: 'theme', method: 'POST', data }, { token })

export const getThemeById = (id: string, token: string) =>
  request({ url: `theme/${id}`, params: { isCms: true } }, { token })

export const updateTheme = (id: string, data: any, token: string) =>
  request({ url: `theme/${id}`, method: 'PUT', data }, { token })

export const delThemeById = (id: string, token: string) =>
  request({ url: `theme/${id}`, method: 'DELETE' }, { token })
