/* eslint-disable react/function-component-definition */
import {
  useMutationPatchFolderById,
  useMutationPostFolder,
} from '@queries/hooks'
import { TResDataListApi } from '@src/configs/interface.config'
import { TResApi } from '@src/configs/resApi.interface'
import { TFolder, TFormValueCEFolder, TQueryFolder } from '@src/models'
import { queryClient } from '@src/queries'
import { LIST_FOLDER } from '@src/queries/keys'
import { Form, Input, Modal } from 'antd'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

type TCreateEditFolderModal = {
  folder?: TFolder
  paramQsFolders?: TQueryFolder
  folderIdCurrent?: string
  handleAfterEditFolder?: (folder: TFolder | undefined) => void
}
export type THandleCreateEditFolderModal = {
  onOpen: (x: boolean) => void
}

const CreateEditFolderModal: React.ForwardRefRenderFunction<
  THandleCreateEditFolderModal,
  TCreateEditFolderModal
> = (
  { folder, paramQsFolders, folderIdCurrent, handleAfterEditFolder },
  ref,
) => {
  // console.log('folderIdCurrent', folderIdCurrent)
  const [form] = Form.useForm<TFormValueCEFolder>()
  const [isOpen, setIsOpen] = useState(false)

  const onClose = () => {
    form.resetFields()
    setIsOpen(false)
    if (handleAfterEditFolder) handleAfterEditFolder(undefined)
  }

  useEffect(() => {
    if (folder) {
      form.setFieldsValue({
        name: folder?.name,
      })
    }
  }, [folder])
  /// createFolder
  const { mutate: mutateFolder, isLoading: isLoadingPostFolder } =
    useMutationPostFolder()
  /// EditFolder
  const { mutate: mutatePathFolderById, isLoading: isLoadingPatchFolderById } =
    useMutationPatchFolderById()
  // finishForm
  const onFinish = ({ name }: TFormValueCEFolder) => {
    if (!folder) {
      mutateFolder(
        { name, parentId: folderIdCurrent },
        {
          onSuccess: (res: TResApi<TFolder>) => {
            const newFolder = res.data

            const old = queryClient.getQueryData<TResDataListApi<TFolder>>([
              LIST_FOLDER,
              JSON.stringify(paramQsFolders),
            ])

            queryClient.setQueryData(
              [LIST_FOLDER, JSON.stringify(paramQsFolders)],
              () => {
                const oldData = old?.data?.data || []

                return {
                  ...old,
                  data: {
                    data: [newFolder, ...oldData],
                  },
                }
              },
            )
            onClose()
          },
        },
      )
    } else {
      mutatePathFolderById(
        // { id: folder?._id, data: { name, parentId: folder?.parentId?._id } },
        { id: folder?._id, data: { name } },
        {
          onSuccess: () => {
            const old = queryClient.getQueryData<TResDataListApi<TFolder>>([
              LIST_FOLDER,
              JSON.stringify(paramQsFolders),
            ])

            queryClient.setQueryData(
              [LIST_FOLDER, JSON.stringify(paramQsFolders)],
              () => {
                const oldData = old?.data?.data || []
                if (oldData?.length <= 0) return { ...old, data: [] }
                const index = oldData.findIndex(
                  (item) => item._id === folder._id,
                )
                if (index >= 0)
                  return {
                    ...old,
                    data: {
                      data: [
                        ...oldData.slice(0, index),
                        { ...oldData[index], name },
                        ...oldData.slice(index + 1),
                      ],
                    },
                  }
                return { ...old }
              },
            )
            if (handleAfterEditFolder) handleAfterEditFolder(undefined)
          },
        },
      )
    }
  }

  useImperativeHandle(ref, () => ({
    onOpen: (x) => setIsOpen(x),
  }))
  return (
    <Modal
      zIndex={10001}
      visible={isOpen}
      title={folder ? 'Tên Folder Mới' : 'Tạo Folder Mới'}
      onOk={() => {
        form.submit()
      }}
      onCancel={onClose}
      okText={folder ? 'Lưu' : 'Tạo mới'}
      cancelText="Hủy"
      forceRender
      confirmLoading={!!isLoadingPostFolder || !!isLoadingPatchFolderById}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Tên folder là bắt buộc',
            },
            {
              max: 30,
              message: 'Tên folder không vượt quá 30 ký tự!',
            },
            {
              min: 3,
              message: 'Tên folder không ít hơn 3!',
            },
          ]}
        >
          <Input placeholder="Tên folder mới" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default forwardRef(CreateEditFolderModal)
