import { useMutation, useQuery, useQueryClient } from 'react-query'
import { EnterpriseData, EnterpriseQueryParams } from '@src/models'
import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { notification } from 'antd'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'

import {
  createEnterprise,
  delEnterpriseById,
  getEnterpriseById,
  getListEnterprise,
  updateEnterprise,
} from '../api'
import { ENTERPRISE_KEY } from '../keys'

export const useQueryEnterprise = (params: EnterpriseQueryParams) =>
  useQuery(
    [ENTERPRISE_KEY.LIST, params, getStoredLang()],
    () => getListEnterprise(params),
    {
      keepPreviousData: true,
      retry: 2,
    },
  )

export const useCreateEnterprise = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: EnterpriseData) => createEnterprise(body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Create Success!' })
          queryClient.refetchQueries([ENTERPRISE_KEY.LIST])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Create failure!' })
      },
    },
  )
}

export const useEnterpriseById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [ENTERPRISE_KEY.DETAIL, id, getStoredLang()],
    () => getEnterpriseById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useUpdateEnterprise = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: EnterpriseData) => updateEnterprise(id, body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Update Success!' })
          queryClient.refetchQueries([ENTERPRISE_KEY.LIST])
          queryClient.refetchQueries([
            ENTERPRISE_KEY.DETAIL,
            id,
            getStoredLang(),
          ])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Update failure!' })
      },
    },
  )
}

export const useDeleteEnterprise = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delEnterpriseById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        queryClient.refetchQueries([ENTERPRISE_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}
