import { useMutation, useQuery, useQueryClient } from 'react-query'
import logger from '@libs/logger'
import {
  getCurrentRoles,
  getProfile,
  refreshToken,
  signOut,
  singIn,
} from '@queries/api'
import { USER_CURRENT_ROLE, USER_PROFILE } from '@queries/keys'
import { ResApi, ResApiErr } from '@configs/resApi.interface'
import {
  checkAuth,
  clearStoredAuth,
  getStoredAuth,
  setStoredAuth,
} from '@libs/localStorage'
import { Auth } from '@src/models/auth'
import { notification } from 'antd'

/**
 * @method queryLogin()
 * @returns
 */
export const useQueryLogin = () => {
  const queryClient = useQueryClient()
  return useMutation(singIn, {
    onSuccess: async (data: ResApi) => {
      if (data.statusCode === 200) {
        setStoredAuth(data.data)
        notification.success({ message: data.message || 'Login Success!' })
      }
    },
    onError: (error: ResApiErr) => {
      clearStoredAuth()
      queryClient.setQueryData(USER_PROFILE, null)
      queryClient.setQueryData(USER_CURRENT_ROLE, null)
      notification.error({ message: error.message || 'Login failure!' })
    },
  })
}

/**
 * @method queryLogOut()
 * @return
 */
export const useQueryLogOut = () => {
  const queryClient = useQueryClient()
  const accessToken = checkAuth()
  return useMutation(() => signOut(accessToken), {
    onSuccess: (data: ResApi) => {
      logger.debug('SignOut data:', data)
      // Todo
    },
    onError: (error: ResApiErr) => {
      logger.debug('SignOut error:', error)
      // Todo
    },
    onSettled: (data, error, variables, context) => {
      logger.debug('onSettled', data, error, variables, context)
      queryClient.setQueryData(USER_PROFILE, null)
      queryClient.setQueryData(USER_CURRENT_ROLE, null)
      clearStoredAuth()
    },
  })
}

/**
 * @method queryProfile
 * @returns
 */
export const useQueryProfile = () => {
  const accessToken = checkAuth()
  return useQuery([USER_PROFILE], () => getProfile(accessToken), {
    enabled: !!accessToken,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })
}

/**
 * @method queryCurrentRole
 * @returns
 */
export const useQueryCurrentRole = () => {
  const accessToken = checkAuth()
  return useQuery([USER_CURRENT_ROLE], () => getCurrentRoles(accessToken), {
    enabled: !!accessToken,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })
}

// Function
/**
 * @method refreshTokenFn
 */
export const refreshTokenFn: () => void = async () => {
  const accessToken = checkAuth()
  const signature: Auth | null = getStoredAuth()
  if (signature) {
    const result: ResApi = await refreshToken(accessToken, {
      refreshToken: signature?.refreshToken || '',
    })
    if (result.statusCode === 200)
      setStoredAuth({
        ...signature,
        accessToken: result.data.accessToken,
        expiredAt: result.data.expiredAt,
      })
    return result
  }
  // TODO ...
  return false
}
