import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import { PROJECT_URL } from '@src/configs/urlRouter'

import { RouteConfigItemType } from './interface'

const InvestProject = lazyLoading(() => import('@src/pages/invest-project'))
const CreateInvestProject = lazyLoading(
  () => import('@pages/invest-project/create'),
)
const DetailInvestProject = lazyLoading(
  () => import('@pages/invest-project/detail'),
)

const projectRouter: RouteConfigItemType[] = [
  {
    path: PROJECT_URL.LIST,
    Element: InvestProject,
    key: PERMISSIONS.LIST_PROJECT,
  },
  {
    path: PROJECT_URL.CREATE,
    Element: CreateInvestProject,
    key: PERMISSIONS.CREATE_PROJECT,
  },
  {
    path: PROJECT_URL.DETAIL,
    Element: DetailInvestProject,
    key: PERMISSIONS.VIEW_PROJECT,
  },
]

export { projectRouter }
